import * as React from 'react';
import {SafeAreaView, Text} from 'react-native';
import * as Style from '../../theme/style';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {AppContext} from '../../AppProvider';
import * as Alert from "../../alerts/alerts";
import * as Updates from "expo-updates";

export function ErrorView(title: string | null, description: string | null) {
	const styles = Style.getStyles()

	if (title === undefined || title === null) {
		title = "Unknown Error!";
	}

	if (description === undefined || description === null) {
		description = "An error occurred! Please try again or restart the application";
	}

	return (
			<AppContext.Consumer>
				{value =>
						<SafeAreaView style={[styles.container, styles.center]}>
							<FontAwesomeIcon icon="exclamation-triangle" size={ 64 } style={[styles.errorIcon]} />
							<Text style={[styles.text, styles.title, styles.errorText, styles.mt10]}>Fehler: {title}</Text>
							<Text style={[styles.text, styles.errorText, styles.mt10, styles.center]}>{description}</Text>
							<Text style={[styles.title, styles.link, styles.mt10]} onPress={() => value.forceReloadApp()}>App neu laden</Text>
							<Text style={[styles.title, styles.link, styles.mt10]} onPress={() => {
								Alert.confirm("App neustarten", "Möchtest du die App jetzt neu starten?", "Neustart", "Abbrechen", () => {
									Updates.reloadAsync().then(r => {})
								}, undefined, "destructive", "cancel");
							}}>App neustarten</Text>
						</SafeAreaView>
				}
			</AppContext.Consumer>
	)
}
