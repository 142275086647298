import 'react-native-gesture-handler';
import * as React from 'react';
import {RefreshControl, ScrollView} from 'react-native';
import * as Backend from '../backend/Backend';
import {LoadingView} from './elements/LoadingView';
import {ErrorView} from './elements/ErrorView';
import * as Style from '../theme/style';
import {Divider, ListItem} from 'react-native-elements';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {getAppContext} from '../AppProvider';
import {ScreenState} from "../types/ScreenState";
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../types/RootStackParamList";
import {UserInfo} from "../types/UserInfo";
import * as Storage from "../storage/storage";
import {StackActions} from "@react-navigation/routers";
import {Button} from "react-native-paper";
import {setSentryImpersonateContext} from '../util/SentryWrapper';

export default function WaiterSelectScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'WaiterSelect'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const [editVisibilityMode, setEditVisibilityMode] = React.useState<boolean>(false)
	const [waiterVisibility, setWaiterVisibility] = React.useState<{[waiterId: string]: boolean}>({})
	const [state, setState] = React.useState<ScreenState>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})
	function loadData() {
		Storage.getItem("waiterVisibility").then(r => {
			if (r !== null) {
				setWaiterVisibility(JSON.parse(r));
			}
		})
		Backend.loadStoreData("waiters", setState, appContext).then(() => {})
	}
	React.useEffect(() => {
		return navigation.addListener('focus', loadData);
	}, [navigation]);

	React.useLayoutEffect(() => {
		navigation.setOptions({
			headerRight: () => {
				if (editVisibilityMode) {
					return (<Button onPress={() => {
						setEditVisibilityMode(false)
						Storage.setItem("waiterVisibility", JSON.stringify(waiterVisibility)).then(r => {})
					}}>
						<FontAwesomeIcon icon="save" size={24} style={[styles.text]}/>
					</Button>)
				} else {
					return (<Button onPress={() => setEditVisibilityMode(true)}>
						<FontAwesomeIcon icon="eye" size={24} style={[styles.text]}/>
					</Button>)
				}
			},
		});
	}, [navigation, waiterVisibility, editVisibilityMode]);

	function selectWaiter(waiter: UserInfo, replace = false) {
		Storage.setItem("impersonate", waiter.id)
		.then(() => {
			setSentryImpersonateContext(waiter)
			console.log('switch to floorplan (replace: ' + replace + ')')
			if (replace) {
				navigation.dispatch(StackActions.replace('Floorplan', {waiter: waiter}))
			} else {
				navigation.navigate('Floorplan', {waiter: waiter});
			}
		});
	}

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen", "Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else {
		const waiters: UserInfo[] = state.response.data
		if (waiters.length === 1) {
			selectWaiter(waiters[0], true)
		}
		return (
				<ScrollView style={[styles.container]} refreshControl={<RefreshControl onRefresh={loadData} refreshing={state.refreshing} />}>
					{
						waiters.filter((waiter: UserInfo) => editVisibilityMode || waiterVisibility[waiter.id]).map((waiter: UserInfo, i: number) => {
									return (
											<ListItem key={"waiter" + waiter.id} topDivider bottomDivider
																containerStyle={[styles.background]}
																onPress={() => {
																	if (editVisibilityMode) {
																		setWaiterVisibility((visibility) => ({...visibility, [waiter.id]: !visibility[waiter.id]}))
																	} else {
																		selectWaiter(waiter)
																	}
																}}>
												<FontAwesomeIcon icon="user-tie" size={32} style={[styles.text, {color: waiter.roles.find((ar) => ar.organization.id == appContext.storeId)?.hexCode}]}/>
												<ListItem.Content style={[styles.center]}>
													<ListItem.Title style={[styles.text, styles.title]}>{waiter.displayName}</ListItem.Title>
												</ListItem.Content>
												{
													editVisibilityMode &&
													<ListItem.CheckBox checked={waiterVisibility[waiter.id]} onPress={() => setWaiterVisibility((visibility) => ({...visibility, [waiter.id]: !visibility[waiter.id]}))}/>
												}
												{
													!editVisibilityMode &&
													<ListItem.Chevron/>
												}
											</ListItem>
									)
								}
						)
					}
					{
						waiters.filter((waiter: UserInfo) => editVisibilityMode || waiterVisibility[waiter.id]).length === 0 &&
						<ListItem key={"noneVisible"} topDivider bottomDivider containerStyle={[styles.background]}>
							<ListItem.Content style={[styles.center]}>
								<ListItem.Title style={[styles.text, styles.title, styles.center]}>Alle ausgeblendet</ListItem.Title>
								<ListItem.Subtitle style={[styles.text, styles.subTitle, styles.center]}>Du kannst über den Button oben rechts die sichtbaren Kellner auswählen</ListItem.Subtitle>
							</ListItem.Content>
						</ListItem>
					}
					<Divider style={[styles.divider]} />
				</ScrollView>
		);
	}
}
