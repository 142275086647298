export type TransactionResponse = {
	SQ?: number, // sequence number
	Result?: Result, // Result
	ESR?: any,
	Fis?: FiscalData, // FiscalData
}

export type Result = {
	RC?: ResultCode,
	ErrorCode?: string,
	UserMessage?: string,
	Warning?: string[],
}

export type ResultCode = 'OK' | 'NO' | 'BAD'

export type FiscalData = {
	D?: any, // LocalDateTime
	StartD?: any, // LocalDateTime
	FN?: string, // Fiscal Number
	DN?: number, // Document number
	ZI?: number, // Z-Report index
	ID?: string, // device serial
	OKP?: string, // Verification code
	Type?: string, // transaction type
	TID?: number, // Transaction id
	Code?: string, // QR-Code Data
	Link?: string, // efstaLink
	Payload?: string, // Signature payload
	Signature?: string, // Signature
	Tag?: FiscalTag[], // Tags
}

export type FiscalTag = {
	Name?: string,
	Value?: string,
	Label?: string,
}

export enum PaymentTypeEnum {
	CASH,
	NON_CASH,
	NONE,
	DEBIT,
	CREDIT,
	PAYMENT_PROVIDER,
	PREPAID_CARD,
	VOUCHER,
	OUTSTANDING,
}
