import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../types/RootStackParamList";
import {Text, TextInput, TouchableOpacity, View} from "react-native";
import * as Alert from "../alerts/alerts";
import Constants from "expo-constants";
import * as Storage from "../storage/storage";
import {getAppContext} from "../AppProvider";
import {ScreenState} from "../types/ScreenState";
import {RegisterDetails} from "../types/RegisterDetails";
import {CommonActions} from "@react-navigation/native";
import {ErrorView} from "./elements/ErrorView";
import {LoadingView} from "./elements/LoadingView";
import {KeyboardAwareScrollView} from "react-native-keyboard-aware-scroll-view";
import {useTheme} from "../theme/ThemeProvider";

export default function RegisterScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'Register'>) {
	const {colors} = useTheme();
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const [state, setState] = React.useState<ScreenState & RegisterDetails & {confirmPassword: string}>({
		isLoading: false,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
		username: "",
		displayName: "",
		email: "",
		password: "",
		confirmPassword: "",
		deviceName: "",
	})

	function register() {
		if (state.username.length < 3 || state.password.length < 3 || state.confirmPassword.length < 3)
			return

		if (state.password !== state.confirmPassword) {
			Alert.alert("Passwörter stimmen nicht überein!", "Bitte überprüfe deine Eingaben! Die beiden Passwort-Eingabefelder müssen übereinstimmen")
			return
		}

		setState((s) => ({
			...s,
			isLoading: true,
		}))

		let deviceId = Constants.deviceId
		if (deviceId == null) {
			deviceId = Constants.installationId
		}
		if (deviceId == null) {
			deviceId = Constants.deviceName
		}
		if (deviceId == null) {
			return
		}

		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json; charset=UTF-8");

		const payload: RegisterDetails = {
			'username': state.username,
			'displayName': state.displayName,
			'email': state.email,
			'password': state.password,
			'deviceName': deviceId,
		}
		const raw = JSON.stringify(payload);

		const requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		} as RequestInit;

		fetch(appContext.environment.baseURL + "register", requestOptions)
		.then(response => response.json())
		.then(response => {
			if (response["status"] === "SUCCESS") {
				Storage.setItem("authorization", response.data.auth.token)
				.then(() => {
					navigation.dispatch(
							CommonActions.reset({
								index: 0,
								routes: [
									{ name: 'StoreSelect' },
								],
							})
					);
				})
			} else {
				Alert.alert("Registrierung fehlgeschlagen!", "Dieser Benutzername existiert vermutlich bereits")
				setState((s) => ({
					...s,
					isLoading: false,
					response: undefined,
				}))
			}
		})
		.catch(() => {
			Alert.alert("Registrierung fehlgeschlagen!", "Dieser Benutzername existiert vermutlich bereits")
			setState((s) => ({
				...s,
				isLoading: false,
				response: undefined,
			}))
		});
	}

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else {
		let displayNameInput: TextInput | null
		let emailInput: TextInput | null
		let passwordInput: TextInput | null
		let confirmPasswordInput: TextInput | null
		return (
				<KeyboardAwareScrollView style={[styles.container]} contentContainerStyle={[styles.center]} enableResetScrollToCoords={false}>
					<View style={[styles.w80p, styles.h80p]}>
						<Text style={[styles.navigationHeader]}>Registrierung</Text>
						<Text style={[styles.navigationSubheader]}>für {appContext.environment.displayName}</Text>
						<View style={[styles.fullWidth, styles.loginInputView]}>
							<TextInput
									style={[styles.text, {
										height: 50,
									}]}
									placeholderTextColor={colors.textSecondary}
									placeholder="Benutzername"
									returnKeyType={"next"}
									autoCapitalize={"none"}
									onChangeText={text => setState((s) => ({...s, username: text}))}
									onSubmitEditing={() => {
										displayNameInput?.focus();
									}}
									blurOnSubmit={false}
							/>
						</View>
						<View style={[styles.fullWidth, styles.loginInputView]}>
							<TextInput
									style={[styles.text, {
										height: 50,
									}]}
									placeholderTextColor={colors.textSecondary}
									placeholder="Voller Name"
									returnKeyType={"next"}
									onChangeText={text => setState((s) => ({...s, displayName: text}))}
									onSubmitEditing={() => {
										emailInput?.focus();
									}}
									ref={(input) => {
										displayNameInput = input;
									}}
									blurOnSubmit={false}
							/>
						</View>
						<View style={[styles.fullWidth, styles.loginInputView]}>
							<TextInput
									style={[styles.text, {
										height: 50,
									}]}
									placeholderTextColor={colors.textSecondary}
									placeholder="Email"
									keyboardType={'email-address'}
									autoCapitalize={"none"}
									autoCompleteType={"email"}
									returnKeyType={"next"}
									onChangeText={text => setState((s) => ({...s, email: text}))}
									onSubmitEditing={() => {
										passwordInput?.focus();
									}}
									ref={(input) => {
										emailInput = input;
									}}
									blurOnSubmit={false}
							/>
						</View>
						<View style={[styles.fullWidth, styles.loginInputView]}>
							<TextInput
									secureTextEntry
									style={[styles.text, {
										height: 50,
									}]}
									placeholderTextColor={colors.textSecondary}
									placeholder="Passwort"
									autoCapitalize={"none"}
									returnKeyType={"next"}
									onChangeText={text => setState((s) => ({...s, password: text}))}
									onSubmitEditing={() => {
										confirmPasswordInput?.focus()
									}}
									ref={(input) => {
										passwordInput = input;
									}}
							/>
						</View>
						<View style={[styles.fullWidth, styles.loginInputView]}>
							<TextInput
									secureTextEntry
									style={[styles.text, {
										height: 50,
									}]}
									placeholderTextColor={colors.textSecondary}
									placeholder="Passwort wiederholen"
									autoCapitalize={"none"}
									returnKeyType={"send"}
									onChangeText={text => setState((s) => ({...s, confirmPassword: text}))}
									onSubmitEditing={register}
									ref={(input) => {
										confirmPasswordInput = input;
									}}
							/>
						</View>
						<TouchableOpacity style={[styles.fullWidth, styles.loginBtnStyle]} onPress={register}>
							<Text style={[styles.text, styles.center]}>REGISTRIEREN</Text>
						</TouchableOpacity>
					</View>
				</KeyboardAwareScrollView>
		)
	}
}
