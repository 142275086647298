import { CommonActions } from '@react-navigation/native';
import { StackScreenProps } from "@react-navigation/stack";
import Constants from "expo-constants";
import * as React from 'react';
import {exchangeCodeAsync, makeRedirectUri, useAuthRequest, useAutoDiscovery} from 'expo-auth-session';
import { Text, TextInput, TouchableOpacity, View } from "react-native";
import { ButtonGroup, Divider } from "react-native-elements";
import 'react-native-gesture-handler';
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { getAppContext } from "../AppProvider";
import { KassensturzEnvironment, getAvailableEnvironments } from "../KassensturzEnvironment";
import * as Alert from "../alerts/alerts";
import * as Backend from "../backend/Backend";
import * as Storage from "../storage/storage";
import * as WebBrowser from 'expo-web-browser';
import { useTheme } from "../theme/ThemeProvider";
import * as Style from '../theme/style';
import { LoginCredentials } from "../types/LoginCredentials";
import { OrganizationType } from "../types/Organization";
import { AssignedRole } from "../types/Role";
import { RootStackParamList } from "../types/RootStackParamList";
import { ScreenState } from "../types/ScreenState";
import { UserInfo } from "../types/UserInfo";
import { setSentryStoreContext, setSentryUser } from '../util/SentryWrapper';
import { ErrorView } from "./elements/ErrorView";
import { LoadingView } from "./elements/LoadingView";
import {useEffect} from "react";

WebBrowser.maybeCompleteAuthSession();

/**
 Azure AD B2C Tenant Details:
 - Display name: Kassensturz Webapp
 - Application (client) ID: 321e6a0e-183f-46db-91dd-9fe9c571aecf
 - Directory (tenant) ID: 58307dcb-39f0-4c85-95f6-eac8574295ff
 Expo Docs: https://docs.expo.dev/guides/authentication/#azure
 */
export default function LoginScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'Login'>) {
	const stage = Constants.expoConfig?.extra?.stage ?? 'Unknown';
	const availableEnvironments = getAvailableEnvironments(stage);

	const oAuthLoginEnabled = false;

	useEffect(() => {
		WebBrowser.warmUpAsync();

		return () => {
			WebBrowser.coolDownAsync();
		};
	}, []);

	const discovery = useAutoDiscovery('https://login.microsoftonline.com/58307dcb-39f0-4c85-95f6-eac8574295ff/v2.0');
	const [request, response, promptAsync] = useAuthRequest(
		{
			clientId: '321e6a0e-183f-46db-91dd-9fe9c571aecf',
			scopes: ['openid', 'profile', 'email', 'offline_access'],
			redirectUri: makeRedirectUri({
				path: 'oauth2callback'
			}),
		},
		discovery
	);

	const {colors} = useTheme();
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const [state, setState] = React.useState<ScreenState & { username: string, password: string }>({
		isLoading: false,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
		username: "",
		password: "",
	})

	function loadData() {
		Backend.request("user", setState, appContext).then(() => {})
	}
	React.useEffect(loadData, [])
	React.useEffect(() => {
		return navigation.addListener('focus', loadData);
	}, [navigation]);

	function legacyLogin() {
		if (state.username.length < 3 || state.password.length < 3)
			return

		setState((state) => ({
			...state,
			isLoading: true,
		}))

		let deviceId = Constants.deviceId
		if (deviceId == null) {
			deviceId = Constants.installationId
		}
		if (deviceId == null) {
			deviceId = Constants.deviceName
		}

		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json; charset=UTF-8");

		const raw = JSON.stringify({'username': state.username, 'password': state.password, 'deviceName': deviceId} as LoginCredentials);

		const requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		} as RequestInit;

		console.log('Logging in at ' + appContext.environment.baseURL + "login")

		fetch(appContext.environment.baseURL + "login", requestOptions)
			.then(response => response.json())
			.then(response => {
				if (response["status"] === "SUCCESS") {
					Storage.setItem("authorization", response.data.auth.token)
						.then(() => {
							const user: UserInfo = response.data.user
							setSentryUser(user)
							const stores = user.roles.filter((role: AssignedRole) => role.organization.type === OrganizationType.STORE)
							const invitations = user.invitations
							if (stores.length === 1 && invitations.length === 0) {
								Storage.setItem("storeId", stores[0].organization.id)
									.then(() => {
										setSentryStoreContext(stores[0].organization.id)
										appContext.storeId = stores[0].organization.id
										navigation.dispatch(
											CommonActions.reset({
												index: 0,
												routes: [
													{ name: 'Home', params: {user: response.data.user, store: stores[0].organization}},
												],
											})
										);
									})
							} else {
								navigation.dispatch(
									CommonActions.reset({
										index: 0,
										routes: [
											{ name: 'StoreSelect' },
										],
									})
								);
							}
						})
				} else {
					Alert.alert("Login fehlerhaft!", "Dieser Benutzername existiert nicht, oder das Passwort ist falsch. Nutze die \"Passwort vergessen?\"-Funktion, falls du deine Zugangsdaten vergessen hast.")
					setState((state) => ({
						...state,
						isLoading: false,
						response: undefined,
					}))
				}
			})
			.catch(() => {
				Alert.alert("Login fehlerhaft!", "Dieser Benutzername existiert nicht, oder das Passwort ist falsch. Nutze die \"Passwort vergessen?\"-Funktion, falls du deine Zugangsdaten vergessen hast.")
				setState((state) => ({
					...state,
					isLoading: false,
					response: undefined,
				}))
			});
	}

	function handleAuthSessionResponse() {

	}

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else {
		let passwordInput: TextInput | null
		return (
			<KeyboardAwareScrollView style={[styles.container]} contentContainerStyle={[styles.center]} enableResetScrollToCoords={false}>
				<View style={[styles.w80p, styles.h80p]}>
					<Text style={[styles.navigationHeader]}>Kassensturz</Text>
					<ButtonGroup
						onPress={index => {
							let env: KassensturzEnvironment = availableEnvironments[index]
							Storage.setItem("environment", env.id).then(() => {
								Storage.deleteItem("authorization").then(() => {
									setSentryUser(null)
									Storage.deleteItem("storeId").then(() => {
										setSentryStoreContext(null)
										appContext.forceReloadApp()
									})
								})
							})
						}}
						selectedIndex={availableEnvironments.findIndex(env => env.id === appContext.environment.id)}
						buttons={availableEnvironments.map(e => e.displayName)}
						containerStyle={[styles.center]}
						buttonStyle={[styles.background]}
						selectedButtonStyle={[styles.backgroundPrimary]}
						textStyle={[styles.text]}
						selectedTextStyle={[styles.text]}
					/>
					<Divider style={[styles.divider]} />
					<View style={[styles.fullWidth, styles.loginInputView]}>
						<TextInput
							style={[styles.text, {
								height: 50,
							}]}
							placeholderTextColor={colors.textSecondary}
							autoCapitalize={"none"}
							autoCorrect={false}
							autoComplete={"username"}
							placeholder="Benutzername / Email"
							returnKeyType={"next"}
							onChangeText={text => setState((state) => ({...state, username: text}))}
							onSubmitEditing={() => {
								passwordInput?.focus();
							}}
							blurOnSubmit={false}
						/>
					</View>
					<View style={[styles.fullWidth, styles.loginInputView]}>
						<TextInput
							secureTextEntry
							style={[styles.text, {
								height: 50,
							}]}
							placeholderTextColor={colors.textSecondary}
							autoCapitalize={"none"}
							autoCorrect={false}
							autoComplete={"password"}
							placeholder="Passwort"
							onChangeText={text => setState((state) => ({...state, password: text}))}
							onSubmitEditing={() => legacyLogin()}
							ref={(input) => {
								passwordInput = input;
							}}
						/>
					</View>
					<TouchableOpacity style={[styles.fullWidth, styles.loginBtnStyle]} onPress={legacyLogin}>
						<Text style={[styles.text, styles.center]}>LOGIN</Text>
					</TouchableOpacity>
					{
						oAuthLoginEnabled &&
						<TouchableOpacity style={[styles.fullWidth, styles.loginBtnStyle]}
										  disabled={true} onPress={() => {
							promptAsync().then(handleAuthSessionResponse)
						}}>
							<Text style={[styles.text, styles.center]}>LOGIN via oAuth 2.0</Text>
						</TouchableOpacity>
					}
					<View style={[styles.fullWidth, {
						display: 'flex',
						flexDirection: "row",
					}]}>
						<View style={[{
							flex: 1,
							marginRight: '5%',
						}]}>
							<TouchableOpacity style={[styles.fullWidth, styles.loginBtnStyle, styles.backgroundSecondary]} onPress={() => navigation.navigate('Register')}>
								<Text style={[styles.text, styles.center]}>Registrieren</Text>
							</TouchableOpacity>
						</View>
						<View style={[{
							flex: 1,
							marginLeft: '5%',
						}]}>
							<TouchableOpacity style={[styles.fullWidth, styles.loginBtnStyle, styles.backgroundSecondary]} onPress={() => Alert.alert("Passwort vergessen?", "Aktuell kannst du dein Password nicht selbst zurücksetzen. Bitte kontaktiere einen Adminstrator um dein Password zurückzusetzen.")}>
								<Text style={[styles.text, styles.center]}>Passwort vergessen?</Text>
							</TouchableOpacity>
						</View>
					</View>
					<Text style={[styles.textDisabled, styles.center, styles.mt10]}>Version {Constants.expoConfig?.version} - Stage: {stage}</Text>
				</View>
			</KeyboardAwareScrollView>
		)
	}
}
