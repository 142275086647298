export type TaxGroup = {
	TaxG: TaxGroupTypeEnum,
	Prc: number, // percent (0-100)
	Net: number, // net
	TAmt: number, // tax
	Amt: number, // gross
}

export enum TaxGroupTypeEnum {
	A,
	B,
	C,
	D,
	E,
	F,
	G,
	H,
	I,
}

export function taxGroupPercentage(taxGroup: TaxGroupTypeEnum | string): number {
	if (taxGroup == TaxGroupTypeEnum.A || taxGroup == 'A') return 19.0;
	if (taxGroup == TaxGroupTypeEnum.B || taxGroup == 'B') return 7.0;
	if (taxGroup == TaxGroupTypeEnum.C || taxGroup == 'C') return 10.7;
	if (taxGroup == TaxGroupTypeEnum.D || taxGroup == 'D') return 5.5;
	if (taxGroup == TaxGroupTypeEnum.E || taxGroup == 'E') return 0.0;
	if (taxGroup == TaxGroupTypeEnum.F || taxGroup == 'F') return 0.0;
	if (taxGroup == TaxGroupTypeEnum.G || taxGroup == 'G') return 0.0;
	if (taxGroup == TaxGroupTypeEnum.H || taxGroup == 'H') return 16.0;
	if (taxGroup == TaxGroupTypeEnum.I || taxGroup == 'I') return 5.0;
	return NaN;
}
