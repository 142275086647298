import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {getAppContext} from "../../AppProvider";
import {ScreenState} from "../../types/ScreenState";
import * as Backend from "../../backend/Backend";
import {ErrorView} from "../elements/ErrorView";
import {LoadingView} from "../elements/LoadingView";
import {RefreshControl, ScrollView, ViewStyle} from "react-native";
import {Divider, ListItem} from "react-native-elements";
import {StoreHubInfo} from "../../types/StoreHubInfo";
import {InfoView} from "../elements/InfoView";
import moment, {min} from "moment/moment";
import {zdtFormat} from "../../backend/Backend";

export default function PairStoreHubScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'PairStoreHub'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const [state, setState] = React.useState<ScreenState>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})
	function loadData() {
		Backend.loadStoreData("hub/find", setState, appContext).then(() => {})
	}
	React.useEffect(() => {
		return navigation.addListener('focus', loadData);
	}, [navigation]);

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response) {
		return ErrorView("Keine Daten empfangen", "Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else {
		const storeHubInfos: StoreHubInfo[] = state.response.data
		console.log(storeHubInfos)
		return (
			<ScrollView style={[styles.container]} refreshControl={<RefreshControl onRefresh={loadData} refreshing={state.refreshing} />}>
				{
					(storeHubInfos === undefined || storeHubInfos === null || storeHubInfos.length === 0) &&
					InfoView("Nicht gefunden", "Bitte stelle sicher, dass du im selben Netzwerk, wie der StoreHub bist und dieser eingeschalten ist")
				}
				{
					storeHubInfos.map((storeHubInfo: StoreHubInfo, i: number) => {
							let minutesSinceLastPing = Math.abs(moment(storeHubInfo.lastKeepAlivePing, zdtFormat).diff(moment.now(), 'minutes'))
							let connectionTextStyle: ViewStyle[] = []
							if (minutesSinceLastPing >= 10)  {
								connectionTextStyle = [styles.errorText]
							}
							return (
								<ListItem key={"storeHubInfo" + storeHubInfo.runtimeId} topDivider bottomDivider
										  containerStyle={[styles.background]} onPress={() => {
									navigation.navigate('StoreHubDetails', {storeHubInfo: storeHubInfo})
								}}>
									<ListItem.Content style={[]}>
										<ListItem.Title
											style={[styles.text, styles.title]}>{storeHubInfo.displayName || storeHubInfo.hardwareAddress}</ListItem.Title>
										<ListItem.Subtitle
											style={[styles.text, styles.subTitle]}>Version: {storeHubInfo.storeHubVersion}</ListItem.Subtitle>
										<ListItem.Subtitle
											style={[styles.text, styles.subTitle, ...connectionTextStyle]}>
											Letzter Kontakt: {moment(storeHubInfo.lastKeepAlivePing, zdtFormat).fromNow()}</ListItem.Subtitle>
									</ListItem.Content>
									<ListItem.Content style={[]} right={true}>
										<ListItem.Subtitle
											style={[styles.text, styles.subTitle, styles.textRight]}>Status:</ListItem.Subtitle>
										{
											storeHubInfo && !storeHubInfo.paired &&
											<ListItem.Title
												style={[styles.text, styles.title, styles.textRight]}>Offen</ListItem.Title>
										}
										{
											storeHubInfo && storeHubInfo.paired &&
											<ListItem.Title
												style={[styles.text, styles.title, styles.textRight]}>Verbunden</ListItem.Title>
										}
										{
											storeHubInfo && storeHubInfo.paired && minutesSinceLastPing >= 10 &&
											<ListItem.Title
												style={[styles.text, styles.title, styles.textRight, ...connectionTextStyle]}></ListItem.Title>
										}
									</ListItem.Content>
									<ListItem.Chevron/>
								</ListItem>
							)
						}
					)
				}
				<Divider style={[styles.divider]} />
			</ScrollView>
		);
	}
}
