import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {getAppContext} from "../../AppProvider";
import {ButtonGroup, Divider, ListItem} from "react-native-elements";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'
import * as Backend from "../../backend/Backend";
import {ScreenState} from "../../types/ScreenState";
import {ErrorView} from "../elements/ErrorView";
import {LoadingView} from "../elements/LoadingView";
import * as Alert from "../../alerts/alerts";
import {ArticleCreateRequest} from "../../types/ArticleCreateRequest";
import {TaxGroupTypeEnum} from "../../types/TaxGroup";
import {Article, ArticleGroup, ArticleType} from "../../types/Article";
import RNPickerSelect from 'react-native-picker-select';
import {Platform} from "react-native";

export default function ArticleCreateScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'CreateArticle'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()

	let article: Article | undefined
	if ('article' in route.params && route.params.article !== undefined) { // Editing existing article
		article = route.params.article
	}
	const [articleGroups, setArticleGroups] = React.useState<ArticleGroup[]>(route.params.groups)
	const [articleType, setArticleType] = React.useState<number>((article?.type === ArticleType.DEPOSIT) ? 1 : 0)
	const [orderReceiptTypeIndex, setOrderReceiptTypeIndex] = React.useState<number>((article?.orderReceiptType === "KITCHEN_ORDER") ? 1 : 0)
	const [selectedTaxHere, setSelectedTaxHere] = React.useState<number>((article?.taxGroupEatHere === TaxGroupTypeEnum.F) ? 2 : ((article?.taxGroupEatHere === TaxGroupTypeEnum.B) ? 1 : 0))
	const [selectedTaxTakeAway, setSelectedTaxTakeAway] = React.useState<number>((article?.taxGroupEatHere === TaxGroupTypeEnum.F) ? 2 : ((article?.taxGroupEatHere === TaxGroupTypeEnum.A) ? 0 : 1))
	const [state, setState] = React.useState<ScreenState & ArticleCreateRequest>({
		isLoading: false,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,

		name: article?.name || "",
		netFlag: article?.netFlag || false,
		stockKeepingUnit: article?.stockKeepingUnit || "",
		itemNumber: article?.itemNumber,
		orderReceiptType: article?.orderReceiptType,
		quantityUnit: article?.quantityUnit,
		sort: article?.sort || 0,
		stock: article?.stock,
		groupId: article?.group,
		type: article?.type,
		taxGroupEatHere: article?.taxGroupEatHere || TaxGroupTypeEnum.A,
		taxGroupTakeAway: article?.taxGroupTakeAway || TaxGroupTypeEnum.A,
		unitPrice: article?.unitPrice || -1,
	})

	function replaceSpace(str: string | undefined) {
		return str?.replace(/\u0020/g, '\u00a0')
	}

	function escapeSKU(str: string) {
		return str.toUpperCase().replace(/\s/g, '-').replace(/[^A-Z0-9-_]/g, '')
	}

	function escapeItemNumber(str: string) {
		return str.replace(/[^0-9]/g, '')
	}

	function escapeNumber(str: string): number {
		return parseInt(str.replace(/[^0-9]/g, ''))
	}

	function createArticleGroup() {
		appContext.openDialog({
			title: "Gruppe hinzufügen",
			message: "Bitte gib einen Namen für die neue Artikel-Gruppe ein:",
			input_visible: true,
			input_keyboardType: 'default',
			positiveButton_label: "Erstellen",
			positiveButton_onPress(inputValue?: string) {
				if (inputValue) {
					Backend.sendStoreData("category/" + route.params.category.id + "/group", setState, appContext, "PUT", {name: inputValue}, false).then(response => {
						setArticleGroups(response.data.groups.filter((group: ArticleGroup) => group.category === route.params.category.id))
					})
				}
			},
			negativeButton_visible: true,
			negativeButton_label: "Abbrechen",
		})
	}

	function deleteArticle() {
		if ('article' in route.params && route.params.article !== undefined) {
			Alert.confirm("Artikel entfernen", "Möchtest du diesen Artikel löschen?", "Entfernen", "Abbrechen", () => {
				Backend.sendStoreData("article/" + route.params.article?.id, setState, appContext, "DELETE").then(() => {
					navigation.goBack()
				})
			}, undefined, 'destructive', 'cancel')
		}
	}

	function submit() {
		if (state.name.length < 3 || state.stockKeepingUnit.length < 1 || state.unitPrice == -1) {
			Alert.alert("Bitte fülle alle Felder vollständig aus!")
			return;
		}

		const request: ArticleCreateRequest = {
			name: state.name,
			netFlag: false,
			stockKeepingUnit: state.stockKeepingUnit,
			taxGroupEatHere: TaxGroupTypeEnum.A,
			taxGroupTakeAway: TaxGroupTypeEnum.A,
			unitPrice: state.unitPrice,
			quantityUnit: state.quantityUnit,
			itemNumber: state.itemNumber,
			orderReceiptType: state.orderReceiptType,
			sort: state.sort,
		}

		if (state.groupId !== 'new')
			request.groupId = state.groupId

		if (articleType === 0) {
			request.type = ArticleType.ARTICLE
		} else if (articleType === 1) {
			request.type = ArticleType.DEPOSIT
		}

		if (orderReceiptTypeIndex == 0) {
			request.orderReceiptType = "DRINKS_ORDER"
		} else if (orderReceiptTypeIndex == 1) {
			request.orderReceiptType = "KITCHEN_ORDER"
		}

		if (selectedTaxHere === 0) {
			request.taxGroupEatHere = TaxGroupTypeEnum.A
		} else if (selectedTaxHere === 1) {
			request.taxGroupEatHere = TaxGroupTypeEnum.B
		} else if (selectedTaxHere === 2) {
			request.taxGroupEatHere = TaxGroupTypeEnum.F
		}

		if (selectedTaxTakeAway === 0) {
			request.taxGroupTakeAway = TaxGroupTypeEnum.A
		} else if (selectedTaxTakeAway === 1) {
			request.taxGroupTakeAway = TaxGroupTypeEnum.B
		} else if (selectedTaxTakeAway === 2) {
			request.taxGroupTakeAway = TaxGroupTypeEnum.F
		}

		if ('article' in route.params && route.params.article !== undefined) { // Editing existing article
			Backend.sendStoreData("article/" + route.params.article.id, setState, appContext, "PATCH", request).then(() => {
				navigation.goBack()
			})
		} else { // Creating a new article
			Backend.sendStoreData("category/" + route.params.category.id + "/article", setState, appContext, "PUT", request).then(() => {
				navigation.goBack()
			})
		}
	}

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else {
		let groups = articleGroups.map(group => {
			return {label: group.name, value: group.id}
		})
		groups.push({label: '- Neue Gruppe anlegen -', value: 'new'})
		return (
				<KeyboardAwareScrollView style={[styles.container]} enableResetScrollToCoords={false}>
					<ListItem key={"articleType"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title]}>Typ der Position</ListItem.Title>
							<ButtonGroup
									onPress={setArticleType}
									selectedIndex={articleType}
									buttons={['Artikel', 'Pfand']}
									containerStyle={[styles.mt10, styles.fullWidth]}
									buttonStyle={[styles.background]}
									selectedButtonStyle={[styles.backgroundPrimary]}
									textStyle={[styles.text]}
									selectedTextStyle={[styles.text]}
							/>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"orderReceiptType"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title]}>Auftragsziel</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>Wähle aus, wo der Bon gedruckt werden soll</ListItem.Subtitle>
							<ButtonGroup
									onPress={setOrderReceiptTypeIndex}
									selectedIndex={orderReceiptTypeIndex}
									buttons={['Theke', 'Küche']}
									containerStyle={[styles.mt10, styles.fullWidth]}
									buttonStyle={[styles.background]}
									selectedButtonStyle={[styles.backgroundPrimary]}
									textStyle={[styles.text]}
									selectedTextStyle={[styles.text]}
							/>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"categoryName"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Artikel-Kategorie</ListItem.Title>
						</ListItem.Content>
						<ListItem.Content style={[]} right={true}>
							<ListItem.Title style={[styles.text]}>{route.params.category.name}</ListItem.Title>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"articleGroup"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Artikel-Gruppe</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>kann zur Gruppierung ähnlicher Artikel genutzt werden (z.B. verschiedene Größen des selben Artikels)</ListItem.Subtitle>
						</ListItem.Content>
						<ListItem.Content style={[]} right={true}>
							<RNPickerSelect
									style={{
										placeholder: styles.text,
										inputIOS: styles.text,
										inputAndroid: styles.text,
									}}
									onClose={() => {
										if (state.groupId === 'new' && Platform.OS === "ios") {
											setState((s) => ({...s, groupId: undefined}))
											createArticleGroup()
										}
									}}
									onValueChange={(groupId) => {
										if (groupId === 'new' && Platform.OS !== "ios") {
											createArticleGroup()
										} else {
											setState((s) => ({...s, groupId: groupId}))
										}
									}}
									value={state.groupId}
									items={groups}>
								<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]}
																value={groups.find(g => g.value === state.groupId)?.label}/>
							</RNPickerSelect>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"articleName"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Artikel-Name</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"Name des Artikels"}
															value={replaceSpace(state.name)}
															onChangeText={text => setState((s) => ({...s, name: text}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"sort"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Sortierung</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>Niedrigere Zahlen stehen weiter oben, es wird also aufsteigend sortiert</ListItem.Subtitle>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"1"} keyboardType={"number-pad"}
															value={isNaN(state.sort || NaN) ? '' : state.sort + ''}
															onChangeText={text => setState((s) => ({...s, sort: escapeNumber(text)}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"stockKeepingUnit"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Artikelnummer / SKU</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>einzigartiger interner Identifikator</ListItem.Subtitle>
							<ListItem.Subtitle style={[styles.subTitle]}>Erlaubte Zeichen: [A-Z], [0-9], [-_]</ListItem.Subtitle>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"WEIN-12"}
															autoCapitalize={"characters"}
															value={state.stockKeepingUnit}
															onChangeText={text => setState((s) => ({...s, stockKeepingUnit: escapeSKU(text)}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"itemNumber"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Artikelnummer (optional)</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>Globale Id-Nummer (z.B. GTIN, EAN, UPC,...)</ListItem.Subtitle>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"4000001012345"} keyboardType={"number-pad"}
															value={state.itemNumber}
															onChangeText={text => setState((s) => ({...s, itemNumber: escapeItemNumber(text)}))}/>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"unitPrice"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Preis pro Einheit</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"4,99"} keyboardType={"decimal-pad"}
															defaultValue={((state.unitPrice !== -1 && state.unitPrice !== 0) ? (state.unitPrice + '').replace(".", ",") : undefined)}
															onChangeText={text => setState((s) => ({...s, unitPrice: Number(text.replace(",", "."))}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"quantityUnit"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Einheit (optional)</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>Bitte Abkürzungen nutzen (Stk. / Fl. / 250ml / ...)</ListItem.Subtitle>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"Stk."}
															value={replaceSpace(state.quantityUnit)}
															onChangeText={text => setState((s) => ({...s, quantityUnit: text}))}/>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"taxGroupHeader"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Steuersatz</ListItem.Title>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"taxGroupHere"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title]}>Vor-Ort Verzehr</ListItem.Title>
							<ButtonGroup
									onPress={setSelectedTaxHere}
									selectedIndex={selectedTaxHere}
									buttons={['19%', '7%', '0%']}
									containerStyle={[styles.mt10, styles.fullWidth]}
									buttonStyle={[styles.background]}
									selectedButtonStyle={[styles.backgroundPrimary]}
									textStyle={[styles.text]}
									selectedTextStyle={[styles.text]}
							/>
							<ListItem.Title style={[styles.text, styles.center, styles.mt10]}>
								{selectedTaxHere == 0 && "Normal"}
								{selectedTaxHere == 1 && "Ermäßigt"}
								{selectedTaxHere == 2 && "Umsatzsteuerfrei"}
							</ListItem.Title>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"taxGroupTakeAway"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title]}>Mitnahme</ListItem.Title>
							<ButtonGroup
									onPress={setSelectedTaxTakeAway}
									selectedIndex={selectedTaxTakeAway}
									buttons={['19%', '7%', '0%']}
									containerStyle={[styles.mt10, styles.fullWidth]}
									buttonStyle={[styles.background]}
									selectedButtonStyle={[styles.backgroundPrimary]}
									textStyle={[styles.text]}
									selectedTextStyle={[styles.text]}
							/>
							<ListItem.Title style={[styles.text, styles.center, styles.mt10]}>
								{selectedTaxTakeAway == 0 && "Normal"}
								{selectedTaxTakeAway == 1 && "Ermäßigt"}
								{selectedTaxTakeAway == 2 && "Umsatzsteuerfrei"}
							</ListItem.Title>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"submit"} topDivider bottomDivider containerStyle={[styles.background]} onPress={submit}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title, styles.center]}>Absenden</ListItem.Title>
						</ListItem.Content>
						<ListItem.Chevron/>
					</ListItem>
					<Divider style={[styles.divider]} />
					{
						article !== undefined && <>
							<ListItem key={"delete"} topDivider bottomDivider containerStyle={[styles.background]} onPress={deleteArticle}>
								<ListItem.Content style={[styles.center]}>
									<ListItem.Title style={[styles.title, styles.center, styles.destructive]}>Löschen</ListItem.Title>
								</ListItem.Content>
								<ListItem.Chevron/>
							</ListItem>
							<Divider style={[styles.divider]} />
						</>
					}
				</KeyboardAwareScrollView>
		)
	}
}
