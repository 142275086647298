import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../../theme/style';
import {ListItem} from 'react-native-elements';
import moment from 'moment';
import {DatePickerListItemData} from '../../../types/DatePickerListItemData';

export default function DatePickerListItem(data : DatePickerListItemData) {
	const styles = Style.getStyles()
	const [openStartDate, setOpenStartDate] = React.useState(false)

	function setStartDate(newValue: Date): void {
		const newStartTime = newValue.setSeconds(0, 0)
		data.callback(new Date(newStartTime))
	}

	function open(): void {
		const input = prompt('Enter new Date-Time as HH:mm DD-MM-YYYY');
	}

	return (
			<>
				<ListItem topDivider bottomDivider containerStyle={[styles.background]} onPress={() => open()}>
					<ListItem.Content style={[]}>
						<ListItem.Title style={[styles.title]}>Startzeit</ListItem.Title>
						<ListItem.Subtitle style={[styles.subTitle]}>{moment(data.value).format('DD. MMM YYYY - LT')}</ListItem.Subtitle>
					</ListItem.Content>
					<ListItem.Chevron/>
				</ListItem>
			</>
	)
}
