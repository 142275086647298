export const en = {
	paymentType_0: 'Cash',
	paymentType_CASH: 'Cash',
	paymentType_3: 'Debit',
	paymentType_DEBIT: 'Debit',
	paymentType_4: 'Credit',
	paymentType_CREDIT: 'Credit',
	taxGroup_A: 'Normal',
	taxGroup_B: 'Ermäßigt',
	taxGroup_C: 'Durchschnittsatz (§24(1)Nr.3 UStG)',
	taxGroup_D: 'Durchschnittsatz (§24(1)Nr.1 UStG)',
	taxGroup_E: 'Nicht Steuerbar',
	taxGroup_F: 'Umsatzsteuerfrei',
	taxGroup_G: 'Umsatzsteuer nicht ermittelbar',
	taxGroup_H: 'Corona Normal',
	taxGroup_I: 'Corona Ermäßigt',
	voidReason_OUT_OF_STOCK: 'Out of Stock',
	voidReason_PARTY_VOID: 'Party Total-Void',
	voidReason_COMPLAINT: 'Complaint',
	voidReason_OTHER: 'Other',
	reportType_Z: 'Z-Report',
	reportType_X: 'X-Report',
	reportType_MONTHLY: 'Monthly Report',
	reportType_YEARLY: 'Yearly Report',
	reportType_Z_list: 'Z-Reports',
	reportType_X_list: 'X-Reports',
	reportType_MONTHLY_list: 'Monthly Reports',
	reportType_YEARLY_list: 'Yearly Reports',
	bar: 'Bar {{someValue}}',
};
