import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { StackScreenProps } from "@react-navigation/stack";
import * as React from 'react';
import { ScrollView } from "react-native";
import { Divider, ListItem } from "react-native-elements";
import 'react-native-gesture-handler';
import * as Style from '../theme/style';
import { RootStackParamList } from "../types/RootStackParamList";

export default function BusinessScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'Business'>) {
	const styles = Style.getStyles()

	return (
			<ScrollView style={[styles.container]}>
				<ListItem key={"setupHeader"} topDivider bottomDivider containerStyle={[styles.background]}>
					<ListItem.Content style={[styles.center]}>
						<ListItem.Title style={[styles.text, styles.title]}>Einstellungen</ListItem.Title>
					</ListItem.Content>
				</ListItem>
				<ListItem key={"floorplan"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('EditFloorplan')}>
					<FontAwesomeIcon icon="chair" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>Tischplan</ListItem.Title>
					</ListItem.Content>
					<ListItem.Chevron />
				</ListItem>
				<ListItem key={"articles"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('EditArticles')}>
					<FontAwesomeIcon icon="pallet" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>Artikel & Gutscheine</ListItem.Title>
					</ListItem.Content>
					<ListItem.Chevron />
				</ListItem>
				<ListItem key={"employees"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('Employee')}>
					<FontAwesomeIcon icon="user-tie" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>Mitarbeiter</ListItem.Title>
					</ListItem.Content>
					<ListItem.Chevron />
				</ListItem>
				<Divider style={[styles.divider]} />
				<ListItem key={"logo"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('EditLogo')}>
					<FontAwesomeIcon icon="image" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>Logo</ListItem.Title>
					</ListItem.Content>
					<ListItem.Chevron />
				</ListItem>
				<Divider style={[styles.divider]} />
				<ListItem key={"storeHub"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('PairStoreHub')}>
					<FontAwesomeIcon icon="server" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>StoreHub</ListItem.Title>
					</ListItem.Content>
					<ListItem.Chevron />
				</ListItem>
				<ListItem key={"printers"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('Printers')}>
					<FontAwesomeIcon icon="print" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>Drucker</ListItem.Title>
					</ListItem.Content>
					<ListItem.Chevron />
				</ListItem>
				<Divider style={[styles.divider]} />
				<ListItem key={"specialOrderHeader"} topDivider bottomDivider containerStyle={[styles.background]}>
					<ListItem.Content style={[styles.center]}>
						<ListItem.Title style={[styles.text, styles.title]}>Spezial-Buchungen</ListItem.Title>
					</ListItem.Content>
				</ListItem>
				<ListItem key={"wage"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('WagePayment')}>
					<FontAwesomeIcon icon="hand-holding-usd" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>Lohnzahlung</ListItem.Title>
						<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Lohnzahlung aus der Kasse (z.B. Lohnvorschuss)</ListItem.Subtitle>
					</ListItem.Content>
					<ListItem.Chevron />
				</ListItem>
				<ListItem key={"change"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('PaymentTypeExchange')}>
					<FontAwesomeIcon icon="exchange-alt" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>Zahlungsmitteltausch</ListItem.Title>
						<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Umbuchung zwischen zwei Zahlungsmitteln (z.B. EC-Karten "Auszahlung" oder Korrektur einer falschen Buchung)</ListItem.Subtitle>
					</ListItem.Content>
					<ListItem.Chevron />
				</ListItem>
				<ListItem key={"transfer"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('MoneyTransfer')}>
					<FontAwesomeIcon icon="cash-register" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>Geldtransit</ListItem.Title>
						<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Wechselgeldeinzahlung / Abschöpfung</ListItem.Subtitle>
					</ListItem.Content>
					<ListItem.Chevron />
				</ListItem>
				<ListItem key={"capital"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('Capital')}>
					<FontAwesomeIcon icon="donate" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>Privateinlage/-entnahme</ListItem.Title>
					</ListItem.Content>
					<ListItem.Chevron />
				</ListItem>
				<ListItem key={"diff"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('Diff')}>
					<FontAwesomeIcon icon="not-equal" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>Soll/Ist-Differenz</ListItem.Title>
						<ListItem.Subtitle style={[styles.text, styles.subTitle]}>
							Im Zuge des Kassenabschlusses erfolgt eine Geldzählung und die Ermittlung der Soll/Ist-Differenz.
							Eine etwaige Differenz ist zu buchen.
						</ListItem.Subtitle>
					</ListItem.Content>
					<ListItem.Chevron />
				</ListItem>
				<ListItem key={"pay"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('OtherPayment')}>
					<FontAwesomeIcon icon="money-bill-wave" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>Sonstige Ein-/Auszahlungen</ListItem.Title>
						<ListItem.Subtitle style={[styles.text, styles.subTitle]}>
							Ein-/Auszahlungen, welche nicht durch andere Fälle abgedeckt werden.
							Diese müssen anschließend im Kassenbuch weiter differenziert werden!
						</ListItem.Subtitle>
					</ListItem.Content>
					<ListItem.Chevron />
				</ListItem>
				<Divider style={[styles.divider]} />
			</ScrollView>
	);
}
