import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {
	Keyboard,
	Platform,
	RefreshControl,
	ScrollView,
	TextInput as ReactTextInput,
	TouchableWithoutFeedback,
	View
} from 'react-native';
import {Divider, ListItem} from 'react-native-elements';
import {Button, Dialog, Portal, TextInput} from 'react-native-paper';
import * as Alert from '../../alerts/alerts';
import {getAppContext} from '../../AppProvider';
import * as Backend from '../../backend/Backend';
import {ErrorView} from '../elements/ErrorView';
import {LoadingView} from '../elements/LoadingView';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import * as Storage from '../../storage/storage';
import {ScreenState} from "../../types/ScreenState";
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {UserInfo} from "../../types/UserInfo";
import {CommonActions} from "@react-navigation/native";
import {setSentryUser} from '../../util/SentryWrapper';

export default function AccountScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'Account'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()

	const [state, setState] = React.useState<ScreenState>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})

	const [passwordChangeDialogVisible, setPasswordChangeDialogVisible] = React.useState<boolean>(false)
	const [passwordChangeDialogOldPassword, setPasswordChangeDialogOldPassword] = React.useState<string|undefined>(undefined)
	const [passwordChangeDialogNewPassword, setPasswordChangeDialogNewPassword] = React.useState<string|undefined>(undefined)
	const [passwordChangeDialogNewPasswordConfirm, setPasswordChangeDialogNewPasswordConfirm] = React.useState<string|undefined>(undefined)

	function loadData() {
		Backend.request("user", setState, appContext).then(() => {})
	}

	React.useEffect(loadData, [])

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen", "Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else {
		const userData = state.response?.data as UserInfo
		let newPasswordInput: ReactTextInput | null
		let newPasswordConfirmInput: ReactTextInput | null
		return (
				<ScrollView style={[styles.container]} refreshControl={<RefreshControl refreshing={state.refreshing} onRefresh={loadData}/>}>
					<ListItem key={"user"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {}}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title]}>{userData.displayName}</ListItem.Title>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"username"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {}}>
						<FontAwesomeIcon icon="user" size={24} style={[styles.text]}/>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Username</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>{userData.username}</ListItem.Subtitle>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"email"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {}}>
						<FontAwesomeIcon icon="envelope" size={24} style={[styles.text]}/>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>E-Mail</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>{userData.email}</ListItem.Subtitle>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"change_display_name"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => appContext.openDialog({
						title: "Anzeigename ändern",
						message: "Dein Name ist u.a. auf den Kassenbons sichtbar, bitte achte also auf eine korrekte Schreibweise!",
						input_visible: true,
						input_defaultValue: userData.displayName,
						input_keyboardType: 'default',
						positiveButton_label: "Speichern",
						positiveButton_onPress(inputValue?: string) {
							if (inputValue) {
								Backend.request("user/display", setState, appContext, "PATCH", {
									newDisplayName: inputValue
								}).then(() => {})
							}
						},
						negativeButton_visible: true,
						negativeButton_label: "Abbrechen",
					})}>
						<FontAwesomeIcon icon="user-edit" size={24} style={[styles.text]}/>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Name ändern</ListItem.Title>
						</ListItem.Content>
						<ListItem.Chevron/>
					</ListItem>
					<ListItem key={"password"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => setPasswordChangeDialogVisible(true)}>
						<FontAwesomeIcon icon="key" size={24} style={[styles.text]}/>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Passwort ändern</ListItem.Title>
						</ListItem.Content>
						<ListItem.Chevron/>
					</ListItem>
					<Portal>
						<Dialog visible={passwordChangeDialogVisible} onDismiss={() => setPasswordChangeDialogVisible(false)}>
							<TouchableWithoutFeedback onPress={() => {if (Platform.OS === "ios") {Keyboard.dismiss()}}}>
								<View>
									<Dialog.Title>Passwort ändern</Dialog.Title>
									<Dialog.Content>
										<TextInput
												label="Altes Passwort"
												value={passwordChangeDialogOldPassword}
												onChangeText={text => setPasswordChangeDialogOldPassword(text)}
												secureTextEntry
												onSubmitEditing={() => { newPasswordInput?.focus(); }}
												style={[styles.my10]}
										/>
										<TextInput
												label="Neues Passwort"
												value={passwordChangeDialogNewPassword}
												onChangeText={text => setPasswordChangeDialogNewPassword(text)}
												secureTextEntry
												ref={(input) => { newPasswordInput = input; }}
												onSubmitEditing={() => { newPasswordConfirmInput?.focus(); }}
												style={[styles.my10]}
										/>
										<TextInput
												label="Neues Passwort wiederholen"
												value={passwordChangeDialogNewPasswordConfirm}
												onChangeText={text => setPasswordChangeDialogNewPasswordConfirm(text)}
												secureTextEntry
												ref={(input) => { newPasswordConfirmInput = input; }}
												style={[styles.my10]}
										/>
									</Dialog.Content>
									<Dialog.Actions>
										<Button onPress={() => {
											setPasswordChangeDialogVisible(false)
										}} mode={"contained"} style={[styles.mr10]}>Abbrechen</Button>
										<Button onPress={() => {
											setPasswordChangeDialogVisible(false)
											if (passwordChangeDialogNewPassword !== passwordChangeDialogNewPasswordConfirm) {
												Alert.alert("Passwörter stimmen nicht überein!", "Bitte überprüfe deine Eingaben!")
											} else {
												Backend.request("user/password", setState, appContext, "PATCH", {
													oldPassword: passwordChangeDialogOldPassword,
													newPassword: passwordChangeDialogNewPassword
												}, false).then(() => {
													Storage.deleteItem("authorization").then(() => {
														setSentryUser(null)
														navigation.dispatch(
																CommonActions.reset({
																	index: 0,
																	routes: [
																		{ name: 'Login' },
																	],
																})
														);
													})
												})
											}
										}} mode={"contained"} style={[styles.ml10]}>Speichern</Button>
									</Dialog.Actions>
								</View>
							</TouchableWithoutFeedback>
						</Dialog>
					</Portal>
					<Divider style={[styles.divider]} />
				</ScrollView>
		)
	}
}
