import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {getAppContext} from "../../AppProvider";
import {Divider, ListItem} from "react-native-elements";
import {StoreRequest} from "../../types/OrganizationRequest";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'
import * as Backend from "../../backend/Backend";
import {ScreenState} from "../../types/ScreenState";
import {ErrorView} from "../elements/ErrorView";
import {LoadingView} from "../elements/LoadingView";
import * as Alert from "../../alerts/alerts";
import * as Storage from "../../storage/storage";
import {CommonActions} from "@react-navigation/native";
import {setSentryStoreContext} from '../../util/SentryWrapper';

export default function StoreCreateScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'StoreCreate'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()

	const [state, setState] = React.useState<ScreenState>({
		isLoading: false,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})

	const [store, setStore] = React.useState<StoreRequest>( {
		address: {
			city: "",
			country: "Deutschland",
			postalCode: "",
			state: "",
			street: "",
			streetNr: ""
		},
		organizationName: "",
		taxId: "",
		telephone: "",
		registrationCode: route.params.registrationCode,
	})

	function submit() {
		if (store.organizationName.length < 3 || store.taxId.length < 3 || store.telephone.length < 3 ||
				store.address.street.length < 3 || store.address.streetNr.length < 1 || store.address.postalCode.length !== 5 ||
				store.address.city.length < 2) {
			Alert.alert("Bitte fülle alle Felder vollständig aus!")
			return;
		}

		Backend.request("business/" + route.params.businessId + "/store", setState, appContext, "PUT", store).then((response) => {
			Storage.setItem("storeId", response.data.id).then(() => {
				setSentryStoreContext(response.data.id)
				appContext.storeId = response.data.id
				navigation.dispatch(
						CommonActions.reset({
							index: 0,
							routes: [
								{name: 'Home', params: {user: state.response?.data, store: response.data}},
							],
						})
				);
			});
		})
	}

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else {
		function replaceSpace(str: string | undefined) {
			return str?.replace(/\u0020/g, '\u00a0')
		}

		function escapeTaxId(str: string) {
			return str.toUpperCase().replace(/\s/g, '').replace(/[^A-Z0-9/]/g, '')
		}

		function onlyNumbers(str: string) {
			return str.replace(/[^0-9]/g, '')
		}

		return (
				<KeyboardAwareScrollView style={[styles.container]} enableResetScrollToCoords={false}>
					<ListItem key={"code"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title, styles.center]}>Registierungs-Code</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle, styles.center]}>{route.params.registrationCode}</ListItem.Subtitle>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"organizationNameTitle"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Filialname</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"Name der Filiale"}
															value={replaceSpace(store.organizationName)}
															onChangeText={text => setStore((s) => ({...s, organizationName: text}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"taxId"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Umsatzsteuer-ID</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>alternativ Steuernummer</ListItem.Subtitle>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"DE123456789"}
															value={store.taxId}
															onChangeText={text => setStore((s) => ({...s, taxId: escapeTaxId(text)}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"telephone"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Telefonnnummer</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"089123456789"} keyboardType={"phone-pad"}
															onChangeText={text => setStore((s) => ({...s, telephone: text}))}/>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"addressHeader"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title, styles.center]}>Adresse</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle, styles.center]}>Anschrift der Filiale</ListItem.Subtitle>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"addressStreet"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Straße</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"Musterstraße"} keyboardType={"default"}
															value={replaceSpace(store.address.street)}
															onChangeText={text => setStore((s) => ({...s, address: {...s.address, street: text}}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"addressStreetNr"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Hausnummer</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"1b"} keyboardType={"default"}
															value={replaceSpace(store.address.streetNr)}
															onChangeText={text => setStore((s) => ({...s, address: {...s.address, streetNr: text}}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"addressPostalCode"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Postleitzahl</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"12345"} keyboardType={"number-pad"}
															value={replaceSpace(store.address.postalCode)}
															onChangeText={text => setStore((s) => ({...s, address: {...s.address, postalCode: onlyNumbers(text)}}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"addressCity"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Stadt</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"Musterstadt"} keyboardType={"default"}
															value={replaceSpace(store.address.city)}
															onChangeText={text => setStore((s) => ({...s, address: {...s.address, city: text}}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"addressState"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Bundesland</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"Musterstaat"} keyboardType={"default"}
															value={replaceSpace(store.address.state)}
															onChangeText={text => setStore((s) => ({...s, address: {...s.address, state: text}}))}/>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"submit"} topDivider bottomDivider containerStyle={[styles.background]} onPress={submit}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title, styles.center]}>Absenden</ListItem.Title>
						</ListItem.Content>
						<ListItem.Chevron/>
					</ListItem>
					<Divider style={[styles.divider]} />
				</KeyboardAwareScrollView>
		)
	}
}
