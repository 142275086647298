import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {getAppContext} from "../../AppProvider";
import {ScreenState} from "../../types/ScreenState";
import * as Alert from "../../alerts/alerts";
import * as Backend from "../../backend/Backend";
import {ErrorView} from "../elements/ErrorView";
import {LoadingView} from "../elements/LoadingView";
import {KeyboardAwareScrollView} from "react-native-keyboard-aware-scroll-view";
import {Divider, ListItem} from "react-native-elements";
import {WagePaymentRequest} from "../../types/WagePaymentRequest";

export default function WagePaymentScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'WagePayment'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()

	const [state, setState] = React.useState<ScreenState & WagePaymentRequest>({
		isLoading: false,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,

		targetUsername: "",
		amount: -1,
	})

	function submit() {
		if (state.targetUsername.length < 3 || state.amount == -1) {
			Alert.alert("Bitte fülle alle Felder vollständig aus!")
			return;
		}

		const request: WagePaymentRequest = {
			targetUsername: state.targetUsername,
			amount: state.amount,
		}

		Backend.sendStoreData("special/transaction/wage", setState, appContext, "PUT", request).then(() => {
			navigation.goBack()
		})
	}

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else {
		return (
				<KeyboardAwareScrollView style={[styles.container]} enableResetScrollToCoords={false}>
					<ListItem key={"targetUser"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Mitarbeiter</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"Username"}
															onChangeText={text => setState((s) => ({...s, targetUsername: text}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"amount"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Wert in Euro</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"630,00"} keyboardType={"decimal-pad"}
															onChangeText={text => setState((s) => ({...s, amount: Number(text.replace(",", "."))}))}/>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"submit"} topDivider bottomDivider containerStyle={[styles.background]} onPress={submit}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title, styles.center]}>Absenden</ListItem.Title>
						</ListItem.Content>
						<ListItem.Chevron/>
					</ListItem>
					<Divider style={[styles.divider]} />
				</KeyboardAwareScrollView>
		)
	}
}
