import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {
	Keyboard,
	Platform,
	RefreshControl,
	SafeAreaView,
	ScrollView,
	Text,
	TouchableOpacity,
	TouchableWithoutFeedback,
	View
} from 'react-native';
import {ListItem} from 'react-native-elements';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {InfoView} from '../elements/InfoView';
import {Col, Grid, Row} from 'react-native-easy-grid';
import {Button, Dialog, Portal, TextInput} from 'react-native-paper';
import * as Backend from '../../backend/Backend';
import {ErrorView} from '../elements/ErrorView';
import {LoadingView} from '../elements/LoadingView';
import {getAppContext} from '../../AppProvider';
import * as Alert from '../../alerts/alerts';
import {ScreenState} from "../../types/ScreenState";
import {StackNavigationProp} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {MaterialTopTabNavigationProp} from "@react-navigation/material-top-tabs";
import {OpenTablePartyTabsParamList} from "./OpenTableScreen";
import {CompositeNavigationProp, RouteProp} from '@react-navigation/native';
import {LocalizationContext} from "../../LocalizationContext";
import {Modifier} from "../../types/PaymentRequest";
import {OpenTable} from "../../types/OpenTable";
import {OrderItem} from "../../types/OrderItem";
import {Floorplan} from "../../types/Floorplan";
import {ArticleType} from "../../types/Article";

export default function OpenTablePartyScreen({ route, navigation }: {
	route: RouteProp<OpenTablePartyTabsParamList, 'OpenTableParty'>;
	navigation: CompositeNavigationProp<MaterialTopTabNavigationProp<OpenTablePartyTabsParamList, 'OpenTableParty'>, StackNavigationProp<RootStackParamList>>;
}) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const { locale } = React.useContext(LocalizationContext);

	const [tableDialogVisible, setTableDialogVisible] = React.useState<boolean>(false);

	const [orderItemDialogVisible, setOrderItemDialogVisible] = React.useState<boolean>(false);
	const [orderItemDialogTarget, setOrderItemDialogTarget] = React.useState<OrderItem | undefined>(undefined);

	const [orderItemDialogCourseVisible, setOrderItemDialogCourseVisible] = React.useState<boolean>(false)
	const [orderItemDialogCourse, setOrderItemDialogCourse] = React.useState<any>(null)

	const [orderItemDialogNotesVisible, setOrderItemDialogNotesVisible] = React.useState<boolean>(false)
	const [orderItemDialogNotes, setOrderItemDialogNotes] = React.useState<any>(null)

	const [orderItemDialogVoidVisible, setOrderItemDialogVoidVisible] = React.useState<boolean>(false)
	const [orderItemDialogVoidQuantity, setOrderItemDialogVoidQuantity] = React.useState<any>(null)

	const [state, setState] = React.useState<ScreenState>({
		isLoading: false,
		customError: undefined,
		error: undefined,
		response: {
			data: route.params?.tableData
		},
		refreshing: false,
	})

	function loadData() {
		Backend.loadStoreData("table/" + route?.params?.tableData.tableId + "/" + route?.params?.tableData.partyNumber, setState, appContext).then(() => {})
	}
	React.useEffect(() => {
		return navigation.addListener('focus', loadData);
	}, [navigation]);

	function orderCourse(courseId: number) {
		Backend.sendTableData("course/" + courseId + "/call", route?.params?.tableData.tableId, route?.params?.tableData.partyNumber, setState, appContext, "PATCH").then(() => {})
	}

	function switchCourses(courseId1: number, courseId2: number) {
		Backend.sendTableData("course/" + courseId1 + "/switch/" + courseId2, route?.params?.tableData.tableId, route?.params?.tableData.partyNumber, setState, appContext, "PATCH").then(() => {})
	}

	function setOrderItemCourse(orderItemId: string, courseId: number) {
		Backend.sendTableData("order/" + orderItemId + "/edit/course/" + courseId, route?.params?.tableData.tableId, route?.params?.tableData.partyNumber, setState, appContext, "PATCH").then(() => {})
	}

	function setOrderItemNote(orderItemId: string, note: string) {
		Backend.sendTableData("order/" + orderItemId + "/edit/note", route?.params?.tableData.tableId, route?.params?.tableData.partyNumber, setState, appContext, "PATCH", note).then(() => {})
	}

	function addItemModifier(orderItemId: string, modifier: Modifier) {
		Backend.sendTableData("order/" + orderItemId + "/modifier", route?.params?.tableData.tableId, route?.params?.tableData.partyNumber, setState, appContext, "PUT", modifier).then(() => {})
	}

	function removeItemModifier(orderItemId: string, modifierId: string) {
		Backend.sendTableData("order/" + orderItemId + "/modifier/" + modifierId, route?.params?.tableData.tableId, route?.params?.tableData.partyNumber, setState, appContext, "DELETE").then(() => {})
	}

	function voidOrderItem(orderItemId: string, voidQuantity: number) {
		if (isNaN(voidQuantity))
			voidQuantity = 1
		Backend.sendTableData("order/" + orderItemId + "/void/" + voidQuantity, route?.params?.tableData.tableId, route?.params?.tableData.partyNumber, setState, appContext, "DELETE", undefined, true).then((response) => {
			if (response?.data?.active === false) { // Voided the last item
				navigation.navigate('Floorplan')
			}
		})
	}

	function voidParty() {
		Backend.sendTableData("void", route?.params?.tableData.tableId, route?.params?.tableData.partyNumber, setState, appContext, "DELETE", undefined, false).then((response) => {
			navigation.navigate('Floorplan')
		})
	}

	function relocateParty(newTableId: string) {
		if (!newTableId) {
			Alert.alert("Ungültige Tischnummer")
			return
		}
		Backend.sendTableData("relocate/" + newTableId, route?.params?.tableData.tableId, route?.params?.tableData.partyNumber, setState, appContext, "PATCH", undefined, false).then((response) => {
			navigation.navigate('Floorplan')
		})
	}

	function combineParty(secondTableId: string, secondPartyId: number) {
		if (!secondTableId || !secondTableId) {
			Alert.alert("Ungültige Zielidentifier")
			return
		}
		Backend.sendTableData("combine/" + secondTableId + "/" + secondPartyId, route?.params?.tableData.tableId, route?.params?.tableData.partyNumber, setState, appContext, "PATCH", undefined, false).then((response) => {
			navigation.navigate('Floorplan')
		})
	}

	function closeParty() {
		Backend.sendTableData("close", route?.params?.tableData.tableId, route?.params?.tableData.partyNumber, setState, appContext, "DELETE", undefined, false).then((response) => {
			navigation.navigate('Floorplan')
		})
	}

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen",
				"Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else {
		const tableData: OpenTable = state.response?.data
		let courseIds = tableData == null ? [] : tableData.courseIds || []
		courseIds = courseIds.sort((a: number, b: number) => a - b)
		return (
				<SafeAreaView style={[styles.container]}>
					<Grid>
						<Row size={10}>
							<ScrollView style={[styles.container]} refreshControl={<RefreshControl refreshing={state.refreshing} onRefresh={loadData} />}>
								{
									(tableData == null)?
											InfoView("Tisch noch nicht geöffnet",
													"Für diesen Tisch wurde noch keine Bestellung aufgenommen. Nutze den Button \"Bestellung\" um eine neue Bestellung aufzugeben und den Tisch damit zu öffnen.",
													"question-circle")
											: (
													<>
														<ListItem key={"duration"} topDivider bottomDivider containerStyle={[styles.background]}>
															<ListItem.Content style={[styles.center]}>
																<ListItem.Title style={[styles.text, styles.subTitle]}>{tableData.duration}</ListItem.Title>
															</ListItem.Content>
														</ListItem>
														{
															courseIds.map((courseId: number, i: number) => {
																return (
																		<View key={"courseWrapper" + courseId}>
																			<ListItem key={"course" + courseId} topDivider bottomDivider containerStyle={[styles.backgroundHighlight]}>
																				{
																					!tableData.orderedCourses?.includes(courseId) && !tableData.orderedCourses?.includes(courseIds[i+1]) && i < (tableData.courseIds.length - 1) && courseId !== 0 &&
																					<TouchableOpacity onPress={() => switchCourses(courseId, courseIds[i+1])}>
																						<FontAwesomeIcon icon="arrow-circle-down" size={24} style={[styles.text, styles.link]}/>
																					</TouchableOpacity>
																				}
																				{
																					!tableData.orderedCourses?.includes(courseId) && !tableData.orderedCourses?.includes(courseIds[i-1]) && i > 0 && courseIds[i-1] !== 0 &&
																					<TouchableOpacity onPress={() => switchCourses(courseId, courseIds[i-1])}>
																						<FontAwesomeIcon icon="arrow-circle-up" size={24} style={[styles.text, styles.link]}/>
																					</TouchableOpacity>
																				}
																				<ListItem.Content>
																					<ListItem.Title style={[styles.text, styles.title]}>
																						{courseId === 0 ? "Bestellung" : "Gang " + courseId}
																					</ListItem.Title>
																				</ListItem.Content>
																				{
																					courseId !== 0 && !tableData["orderedCourses"]?.includes(courseId) &&
																					<ListItem.Content right={true}>
																						<ListItem.Title style={[styles.title, styles.link]} onPress={() => orderCourse(courseId)}>Abrufen</ListItem.Title>
																					</ListItem.Content>
																				}
																			</ListItem>
																			{
																				tableData.orderItems.filter((item) => item.course === courseId).map((item, j: number) => {
																					return <View key={"item" + courseId + "-" + j}>
																						<ListItem topDivider bottomDivider={item.modifiers === null || item.modifiers === undefined || item.modifiers.length === 0} containerStyle={[styles.background]} onPress={() => {
																							setOrderItemDialogTarget(item)
																							setOrderItemDialogVisible(true)
																						}}>
																							{
																								item.articleUnit.length > 0 && item.articleType !== ArticleType.VOUCHER_MULTIPURPOSE &&
																								<View>
																									<ListItem.Title style={[styles.text, styles.title, styles.center]}>
																										{item["quantity"].toLocaleString(locale, { style: 'decimal' })}
																									</ListItem.Title>
																									<ListItem.Subtitle style={[styles.text, styles.subTitle]}>{item.articleUnit}</ListItem.Subtitle>
																								</View>
																							}
																							<ListItem.Content>
																								<ListItem.Title style={[styles.text, styles.title]}>{item.articleName}</ListItem.Title>
																								{
																									item.specialWishes !== null && item.specialWishes !== undefined &&
																									<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Notiz: {item.specialWishes}</ListItem.Subtitle>
																								}
																							</ListItem.Content>
																							<ListItem.Content right={true}>
																								<ListItem.Title style={[styles.text, styles.title]}>{(item.quantity * item.unitPrice).toLocaleString(locale, { style: 'currency', currency: 'EUR' })}</ListItem.Title>
																								{
																									item.articleType !== ArticleType.VOUCHER_MULTIPURPOSE &&
																									<ListItem.Subtitle style={[styles.text, styles.subTitle]}>{item.quantity.toLocaleString(locale, { style: 'decimal' })} x {item.unitPrice.toLocaleString(locale, { style: 'currency', currency: 'EUR' })}</ListItem.Subtitle>
																								}
																							</ListItem.Content>
																						</ListItem>
																						{
																							item.modifiers !== null && item.modifiers !== undefined && item.modifiers.filter((m: Modifier) => !m.isRedeemed).sort(function(x, y) {
																								// absolute modifiers first
																								return (x.isAbsolute === y.isAbsolute) ? 0 : (x.isAbsolute ? -1 : 1);
																							}).map((modifier, l, array) => {
																								return (<ListItem key={item.id + "-modifier-" + l} bottomDivider={array.length === l+1} containerStyle={[styles.background]} onPress={() => {
																											Alert.confirm("Rabatt/Zuschlag löschen?", "Diese Aktion kann nicht zurückgenommen werden!", "Löschen", "Abbrechen", () => {
																												if (modifier.id != null) {
																													removeItemModifier(item.id, modifier.id)
																												}
																											}, undefined, 'destructive', 'cancel')
																										}
																										}>
																											<ListItem.Content>
																												<ListItem.Title style={[styles.text, styles.title]}>{modifier.description}</ListItem.Title>
																											</ListItem.Content>
																											<ListItem.Content right={true}>
																												{
																													modifier.isAbsolute &&
																													<ListItem.Title style={[styles.text, styles.title]}>{modifier.amount.toLocaleString(locale, { style: 'currency', currency: 'EUR' })}</ListItem.Title>
																												}
																												{
																													!modifier.isAbsolute &&
																													<ListItem.Title style={[styles.text, styles.title]}>{modifier.amount.toLocaleString(locale, { style: 'decimal' })} %</ListItem.Title>
																												}
																											</ListItem.Content>
																										</ListItem>
																								)
																							})
																						}
																					</View>
																				})
																			}
																		</View>
																)
															})
														}
														<Portal>
															<Dialog visible={orderItemDialogVisible} onDismiss={() => setOrderItemDialogVisible(false)}>
																<Dialog.Title>Bestellung bearbeiten</Dialog.Title>
																<Dialog.Content>
																	{
																		orderItemDialogTarget && tableData["orderedCourses"]?.includes(orderItemDialogTarget.course) &&
																		<Text style={[styles.text, styles.mb10]}>Hinweis: Einige Aktionen sind ausgeblendet, da die Bestellung bereits bearbeitet wurde</Text>
																	}
																	{
																		orderItemDialogTarget && !tableData["orderedCourses"]?.includes(orderItemDialogTarget.course) &&
																		<Button onPress={() => {
																			setOrderItemDialogVisible(false);
																			setOrderItemDialogCourse(orderItemDialogTarget.course.toString())
																			setOrderItemDialogCourseVisible(true)
																		}} mode={'outlined'} style={[styles.my10]}>In anderen Gang verschieben</Button>
																	}
																	{
																		orderItemDialogTarget && !tableData["orderedCourses"]?.includes(orderItemDialogTarget.course) &&
																		<Button onPress={() => {
																			setOrderItemDialogVisible(false)
																			setOrderItemDialogNotes(orderItemDialogTarget?.specialWishes)
																			setOrderItemDialogNotesVisible(true)
																		}} mode={"outlined"} style={[styles.my10]}>Sonderwunsch</Button>
																	}
																	<Button onPress={() => {
																		setOrderItemDialogVisible(false)
																		setOrderItemDialogVoidQuantity("1")
																		setOrderItemDialogVoidVisible(true)
																	}} mode={"outlined"} style={[styles.my10]}>Stornieren</Button>
																	<Button onPress={() => {
																		setOrderItemDialogVisible(false)
																		appContext.openDialog({
																			title: 'Rabatt',
																			message: 'Rabattsumme in EUR oder Prozent',
																			input_visible: true,
																			input_keyboardType: "decimal-pad",
																			middleButton_visible: true,
																			middleButton_label: '%',
																			middleButton_bold: true,
																			positiveButton_label: '€',
																			negativeButton_visible: true,
																			negativeButton_label: 'Kein Rabatt',
																			positiveButton_onPress(inputValue) {
																				let v = Number(inputValue?.replace(",", "."))
																				if (isNaN(v) || v < 0) {
																					v = 0
																				}
																				if (orderItemDialogTarget) {
																					addItemModifier(orderItemDialogTarget?.id, {
																						amount: -v,
																						isAbsolute: true,
																						description: 'Manueller Rabatt',
																						isRedeemed: false,
																					})
																				}
																			},
																			middleButton_onPress(inputValue) {
																				let v = Number(inputValue?.replace(",", "."))
																				if (isNaN(v) || v < 0) {
																					v = 0
																				}
																				if (orderItemDialogTarget) {
																					addItemModifier(orderItemDialogTarget?.id, {
																						amount: -v,
																						isAbsolute: false,
																						description: 'Manueller Rabatt',
																						isRedeemed: false,
																					})
																				}
																			},
																		});
																	}} mode={"outlined"} style={[styles.my10]}>Rabatt</Button>
																	<Button onPress={() => {
																		setOrderItemDialogVisible(false)
																		appContext.openDialog({
																			title: 'Zuschlag',
																			message: 'Summe in EUR oder Prozent',
																			input_visible: true,
																			input_keyboardType: "decimal-pad",
																			middleButton_visible: true,
																			middleButton_label: '%',
																			middleButton_bold: true,
																			positiveButton_label: '€',
																			negativeButton_visible: true,
																			negativeButton_label: 'Kein Zuschlag',
																			positiveButton_onPress(inputValue) {
																				let v = Number(inputValue?.replace(",", "."))
																				if (isNaN(v) || v < 0) {
																					v = 0
																				}
																				if (orderItemDialogTarget) {
																					addItemModifier(orderItemDialogTarget?.id, {
																						amount: v,
																						isAbsolute: true,
																						description: 'Manueller Zuschlag',
																						isRedeemed: false,
																					})
																				}
																			},
																			middleButton_onPress(inputValue) {
																				let v = Number(inputValue?.replace(",", "."))
																				if (isNaN(v) || v < 0) {
																					v = 0
																				}
																				if (orderItemDialogTarget) {
																					addItemModifier(orderItemDialogTarget?.id, {
																						amount: v,
																						isAbsolute: false,
																						description: 'Manueller Zuschlag',
																						isRedeemed: false,
																					})
																				}
																			},
																		});
																	}} mode={"outlined"} style={[styles.my10]}>Zuschlag</Button>
																</Dialog.Content>
																<Dialog.Actions>
																	<Button onPress={() => setOrderItemDialogVisible(false)} mode={"contained"} style={[styles.mx10]}>Abbrechen</Button>
																</Dialog.Actions>
															</Dialog>
														</Portal>
														<Portal>
															<Dialog visible={orderItemDialogCourseVisible} onDismiss={() => setOrderItemDialogCourseVisible(false)}>
																<TouchableWithoutFeedback onPress={() => {if (Platform.OS === "ios") {Keyboard.dismiss()}}}>
																	<View>
																		<Dialog.Title>Gang ändern</Dialog.Title>
																		<Dialog.Content>
																			<Text style={[styles.text, styles.mb10]}>Hinweis: Gang 0 wird immer sofort abgerufen</Text>
																			<Text style={[styles.text, styles.mb10]}>Hinweis: Du kannst diese Bestellung nicht in einen bereits abgerufenen Gang verschieben</Text>
																			<TextInput
																					label="Gang"
																					keyboardType={'number-pad'}
																					value={orderItemDialogCourse}
																					onChangeText={text => setOrderItemDialogCourse(text)}
																			/>
																		</Dialog.Content>
																		<Dialog.Actions>
																			<Button onPress={() => {
																				setOrderItemDialogCourseVisible(false)
																				setOrderItemDialogCourse(null)
																			}} mode={"contained"} style={[styles.mr10]}>Abbrechen</Button>
																			<Button onPress={() => {
																				setOrderItemDialogCourseVisible(false)
																				if (orderItemDialogTarget) {
																					setOrderItemCourse(orderItemDialogTarget?.id, parseInt(orderItemDialogCourse))
																				}
																			}} mode={"contained"} style={[styles.ml10]}>Speichern</Button>
																		</Dialog.Actions>
																	</View>
																</TouchableWithoutFeedback>
															</Dialog>
														</Portal>
														<Portal>
															<Dialog visible={orderItemDialogNotesVisible} onDismiss={() => setOrderItemDialogNotesVisible(false)}>
																<TouchableWithoutFeedback onPress={() => {if (Platform.OS === "ios") {Keyboard.dismiss()}}}>
																	<View>
																		<Dialog.Title>Sonderwunsch</Dialog.Title>
																		<Dialog.Content>
																			<TextInput
																					label="Sonderwunsch / Notiz"
																					value={orderItemDialogNotes}
																					onChangeText={text => setOrderItemDialogNotes(text)}
																			/>
																		</Dialog.Content>
																		<Dialog.Actions>
																			<Button onPress={() => {
																				setOrderItemDialogNotesVisible(false)
																				setOrderItemDialogNotes(null)
																			}} mode={"contained"} style={[styles.mr10]}>Abbrechen</Button>
																			<Button onPress={() => {
																				setOrderItemDialogNotesVisible(false)
																				if (orderItemDialogTarget) {
																					setOrderItemNote(orderItemDialogTarget?.id, orderItemDialogNotes)
																				}
																			}} mode={"contained"} style={[styles.ml10]}>Speichern</Button>
																		</Dialog.Actions>
																	</View>
																</TouchableWithoutFeedback>
															</Dialog>
														</Portal>
														<Portal>
															<Dialog visible={orderItemDialogVoidVisible} onDismiss={() => setOrderItemDialogVoidVisible(false)}>
																<TouchableWithoutFeedback onPress={() => {if (Platform.OS === "ios") {Keyboard.dismiss()}}}>
																	<View>
																		<Dialog.Title>Stornieren</Dialog.Title>
																		<Dialog.Content>
																			<TextInput
																					label="Stornierungsmenge"
																					keyboardType={'number-pad'}
																					value={orderItemDialogVoidQuantity}
																					onChangeText={text => setOrderItemDialogVoidQuantity(text)}
																			/>
																		</Dialog.Content>
																		<Dialog.Actions>
																			<Button onPress={() => {
																				setOrderItemDialogVoidVisible(false)
																			}} mode={"contained"} style={[styles.mr10]}>Abbrechen</Button>
																			<Button onPress={() => {
																				setOrderItemDialogVoidVisible(false)
																				if (orderItemDialogTarget) {
																					voidOrderItem(orderItemDialogTarget?.id, parseInt(orderItemDialogVoidQuantity))
																				}
																			}} mode={"contained"} style={[styles.ml10]}>Speichern</Button>
																		</Dialog.Actions>
																	</View>
																</TouchableWithoutFeedback>
															</Dialog>
														</Portal>
													</>
											)
								}
							</ScrollView>
						</Row>
						<Row size={1}>
							<Col size={3} style={[styles.center, styles.backgroundPrimary]} onPress={() => navigation.navigate('OrderCategories', {tableData: tableData, articleHistory: tableData.orderItems.map(i => i.article).filter(i => i !== undefined) as string[]})}>
								<Text style={[styles.title, { fontSize: 20}]}><FontAwesomeIcon icon="plus" size={16} style={[styles.text]}/> Bestellung</Text>
							</Col>
							<Col size={2} style={[styles.center, styles.backgroundSecondary]} onPress={() => navigation.navigate('Payment', {tableData: tableData})}>
								<Text style={[styles.title, { fontSize: 20}]}>{tableData.totalPrice.toLocaleString(locale, { style: 'currency', currency: 'EUR' })}</Text>
							</Col>
							<Col size={1} style={[styles.center, styles.backgroundHighlight]} onPress={() => setTableDialogVisible(true)}>
								<FontAwesomeIcon icon="ellipsis-h" size={24} style={[styles.text]}/>
							</Col>
							<Portal>
								<Dialog visible={tableDialogVisible} onDismiss={() => setTableDialogVisible(false)}>
									<Dialog.Title>Partei bearbeiten</Dialog.Title>
									<Dialog.Content>
										<Button onPress={() => {
											setTableDialogVisible(false)
											navigation.navigate('OrderCategories', {tableData: {tableId: tableData.tableId, tableName: tableData.tableName, partyNumber: -1}})
										}} mode={"outlined"} style={[styles.my10]}>Neue Partei hinzufügen</Button>
										<Button onPress={() => {
											setTableDialogVisible(false)
											Backend.loadStoreData("tables", setState, appContext, false).then((r) => {
												const floorplan: Floorplan = r.data.floorplan
												appContext.openDialog({
													title: "Partei umziehen",
													message: "Bitte wähle den neuen Tisch für diese Partei",
													picker_visible: true,
													picker_items: floorplan.floors.flatMap(value => value.tables).map(table => ({label: table.name, value: table.id})),
													positiveButton_label: "Umziehen",
													positiveButton_onPress(inputValue?: string, pickerValue?: string) {
														if (pickerValue) {
															relocateParty(pickerValue)
														}
													},
													negativeButton_visible: true,
													negativeButton_label: "Abbrechen",
												})
											})
										}} mode={"outlined"} style={[styles.my10]}>Partei umziehen</Button>
										<Button onPress={() => {
											setTableDialogVisible(false)
											Backend.loadStoreData("tables", setState, appContext, false).then((r) => {
												const openTables: OpenTable[] = r.data.tables
												appContext.openDialog({
													title: "Partei zusammenlegen",
													message: "Bitte wähle die Partei, zu der der aktuelle Tisch hinzugefügt werden soll",
													picker_visible: true,
													picker_items: openTables.filter(table => (route?.params?.tableData?.tableId !== table.tableId || route?.params?.tableData?.partyNumber !== table.partyNumber)).map(table => ({label: table.tableName + ", Partei " + table.partyNumber, value: JSON.stringify({tableId: table.tableId, partyNumber: table.partyNumber})})),
													positiveButton_label: "Zusammenlegen",
													positiveButton_onPress(inputValue?: string, pickerValue?: string) {
														if (pickerValue) {
															var jsonValue = JSON.parse(pickerValue)
															if (jsonValue.tableId && jsonValue.partyNumber) {
																combineParty(jsonValue.tableId, jsonValue.partyNumber)
															}
														}
													},
													negativeButton_visible: true,
													negativeButton_label: "Abbrechen",
												})
											})
										}} mode={"outlined"} style={[styles.my10]}>Partei zusammenlegen</Button>
										<Button onPress={() => {
											setTableDialogVisible(false)
											Alert.confirm("Partei wirklich stornieren?", "Diese Aktion kann nicht zurückgenommen werden!", "Löschen", "Abbrechen", voidParty, undefined, 'destructive', 'cancel')
										}} mode={"outlined"} style={[styles.my10]}>Partei stornieren</Button>
									</Dialog.Content>
									<Dialog.Actions>
										<Button onPress={() => setTableDialogVisible(false)} mode={"contained"} style={[styles.mr10]}>Abbrechen</Button>
									</Dialog.Actions>
								</Dialog>
							</Portal>
						</Row>
					</Grid>
				</SafeAreaView>
		)
	}
}
