import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {ButtonGroup, Divider, ListItem} from "react-native-elements";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'
import {ScreenState} from "../../types/ScreenState";
import {ErrorView} from "../elements/ErrorView";
import {LoadingView} from "../elements/LoadingView";
import * as Alert from "../../alerts/alerts";
import {TaxGroupTypeEnum} from "../../types/TaxGroup";
import {ArticleType} from "../../types/Article";
import {CustomOrderRequestItem} from "../../types/OrderRequest";

export default function CustomOrderItemScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'CustomOrderItem'>) {
	const styles = Style.getStyles()

	const [orderReceiptTypeIndex, setOrderReceiptTypeIndex] = React.useState<number>(0)
	const [selectedTax, setSelectedTax] = React.useState<number>(0)
	const [state, setState] = React.useState<ScreenState & CustomOrderRequestItem>({
		isLoading: false,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,

		name: "",
		unit: "",
		unitPrice: -1,
		orderReceiptPrinterType: "KITCHEN_ORDER",
		taxGroup: TaxGroupTypeEnum.A,
		articleType: ArticleType.ARTICLE,
		course: 0,
		quantity: 1,
		specialWishes: undefined,
	})

	function replaceSpace(str: string | undefined) {
		return str?.replace(/\u0020/g, '\u00a0')
	}

	function submit() {
		if (state.name.length < 3 || state.unitPrice == -1) {
			Alert.alert("Bitte fülle alle Felder vollständig aus!")
			return;
		}

		const request: (CustomOrderRequestItem & {differentiator: string}) = {
			name: state.name,
			unit: state.unit,
			unitPrice: state.unitPrice,
			orderReceiptPrinterType: state.orderReceiptPrinterType,
			taxGroup: TaxGroupTypeEnum.A,
			articleType: ArticleType.ARTICLE,
			course: 0,
			quantity: state.quantity,
			specialWishes: state.specialWishes,
			differentiator: Math.random() + ''
		}

		if (orderReceiptTypeIndex == 0) {
			request.orderReceiptPrinterType = "DRINKS_ORDER"
		} else if (orderReceiptTypeIndex == 1) {
			request.orderReceiptPrinterType = "KITCHEN_ORDER"
		}

		if (selectedTax === 0) {
			request.taxGroup = TaxGroupTypeEnum.A
		} else if (selectedTax === 1) {
			request.taxGroup = TaxGroupTypeEnum.B
		} else if (selectedTax === 2) {
			request.taxGroup = TaxGroupTypeEnum.F
		}

		console.log(request)
		console.log('sent')
		navigation.navigate('OrderCategories', {tableData: route?.params?.tableData, customOrderItem: request, articleHistory: route?.params?.articleHistory});
	}

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else {
		return (
				<KeyboardAwareScrollView style={[styles.container]} enableResetScrollToCoords={false}>
					<ListItem key={"orderReceiptType"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title]}>Auftragsziel</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>Wähle aus, wo der Bon gedruckt werden soll</ListItem.Subtitle>
							<ButtonGroup
									onPress={setOrderReceiptTypeIndex}
									selectedIndex={orderReceiptTypeIndex}
									buttons={['Theke', 'Küche']}
									containerStyle={[styles.mt10, styles.fullWidth]}
									buttonStyle={[styles.background]}
									selectedButtonStyle={[styles.backgroundPrimary]}
									textStyle={[styles.text]}
									selectedTextStyle={[styles.text]}
							/>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"articleName"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Artikel-Name</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"Name des Artikels"}
															value={replaceSpace(state.name)}
															onChangeText={text => setState((s) => ({...s, name: text}))}/>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"unitPrice"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Preis pro Einheit</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"4,99"} keyboardType={"decimal-pad"}
															defaultValue={((state.unitPrice !== -1 && state.unitPrice !== 0) ? (state.unitPrice + '').replace(".", ",") : undefined)}
															onChangeText={text => setState((s) => ({...s, unitPrice: Number(text.replace(",", "."))}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"quantityUnit"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Einheit (optional)</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>Bitte Abkürzungen nutzen (Stk. / Fl. / 250ml / ...)</ListItem.Subtitle>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"Stk."}
															value={replaceSpace(state.unit)}
															onChangeText={text => setState((s) => ({...s, unit: text}))}/>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"taxGroup"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title]}>Steuersatz</ListItem.Title>
							<ButtonGroup
									onPress={setSelectedTax}
									selectedIndex={selectedTax}
									buttons={['19%', '7%', '0%']}
									containerStyle={[styles.mt10, styles.fullWidth]}
									buttonStyle={[styles.background]}
									selectedButtonStyle={[styles.backgroundPrimary]}
									textStyle={[styles.text]}
									selectedTextStyle={[styles.text]}
							/>
							<ListItem.Title style={[styles.text, styles.center, styles.mt10]}>
								{selectedTax == 0 && "Normal"}
								{selectedTax == 1 && "Ermäßigt"}
								{selectedTax == 2 && "Umsatzsteuerfrei"}
							</ListItem.Title>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"submit"} topDivider bottomDivider containerStyle={[styles.background]} onPress={submit}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title, styles.center]}>Absenden</ListItem.Title>
						</ListItem.Content>
						<ListItem.Chevron/>
					</ListItem>
					<Divider style={[styles.divider]} />
				</KeyboardAwareScrollView>
		)
	}
}
