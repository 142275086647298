import {TaxGroupTypeEnum} from "./TaxGroup";
import {PrinterType} from "./PrinterTypeEnum";
import {IconName} from "@fortawesome/fontawesome-common-types";

export type Article = {
	id: string,
	category: string, // Id
	group?: string, // Id
	name: string,
	stockKeepingUnit: string, // Article number (company internal unique identifier)
	itemNumber?: string, // Public article number (GTIN, EAN, UPC)
	taxGroupEatHere: TaxGroupTypeEnum, // Tax Group for consumption in the restaurant
	taxGroupTakeAway: TaxGroupTypeEnum, // Tax Group for Take Away
	quantityUnit: string, // Quantity Unit, default: "pc" ("piece")
	unitPrice: number, // Price per Unit
	netFlag: boolean, // Flag set if unitPrice is net instead of gross
	sort: number,
	stock: number,
	type: ArticleType,
	orderReceiptType: PrinterType,
	icon: IconName,
}

export type ArticleCategoryRequest = {
	name: string,
	sort: number,
	faIcon: IconName,
	hexCode: string,
}

export type ArticleCategory = ArticleCategoryRequest & {
	id: string,
}

export type ArticleGroup = {
	id: string,
	category: string, // Id
	name: string,
	sort: number,
}

export enum ArticleType {
	ARTICLE = 'ARTICLE',
	DEPOSIT = 'DEPOSIT',
	VOUCHER_SINGLEPURPOSE = 'VOUCHER_SINGLEPURPOSE',
	VOUCHER_MULTIPURPOSE = 'VOUCHER_MULTIPURPOSE',
}
