import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {SafeAreaView, Text} from 'react-native';
import {InfoView} from '../elements/InfoView';
import * as Backend from '../../backend/Backend';
import {ErrorView} from '../elements/ErrorView';
import {LoadingView} from '../elements/LoadingView';
import {getAppContext} from '../../AppProvider';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import OpenTablePartyScreen from './OpenTablePartyScreen';
import {Col, Grid, Row} from 'react-native-easy-grid';
import {ScreenState} from "../../types/ScreenState";
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {OpenTableInfo} from "../../types/OpenTable";

const Tab = createMaterialTopTabNavigator();

export type OpenTablePartyTabsParamList = {
	OpenTableParty: { tableData: OpenTableInfo };
}

export default function OpenTableScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'OpenTable'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()

	const [state, setState] = React.useState<ScreenState>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})

	function loadData() {
		Backend.loadStoreData("table/" + route?.params?.tableData.tableId, setState, appContext).then(() => {})
	}
	React.useEffect(loadData, [])
	React.useEffect(() => {
		return navigation.addListener('focus', loadData);
	}, [navigation]);

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen",
				"Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else {
		const tablesData = state.response?.data
		let tabs = tablesData?.map((table: any) => <Tab.Screen name={"Partei " + table["partyNumber"]} key={"party" + table["partyNumber"]} component={OpenTablePartyScreen} initialParams={{tableData: table}}/>)
		if (tabs == null) {
			tabs = <Tab.Screen name="Info">
				{
					() =>
							<Grid>
								<Row size={10}>
									<Col>
										{
											InfoView(route?.params?.tableData.tableName,
													"Dieser Tisch ist aktuell frei. Nutze den Button unten um den Tisch zu öffnen und Bestellungen aufzunehmen.",
													"question-circle")
										}
									</Col>
								</Row>
								<Row size={1}>
									<Col size={3} style={[styles.center, styles.backgroundPrimary]} onPress={() => navigation.navigate('OrderCategories', {tableData: route?.params?.tableData})}>
										<Text style={[styles.title, { fontSize: 20}]}>Bestellung</Text>
									</Col>
								</Row>
							</Grid>
				}
			</Tab.Screen>
		}
		return (
				<SafeAreaView style={[styles.container]}>
					<Tab.Navigator style={[styles.background]} screenOptions={{
						tabBarLabelStyle: [styles.text],
						tabBarStyle: [styles.background],
						tabBarIndicatorStyle: [styles.backgroundPrimary],
					}}>
						{tabs}
					</Tab.Navigator>
				</SafeAreaView>
		)
	}
}
