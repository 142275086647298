import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {getAppContext} from "../../AppProvider";
import {ScreenState} from "../../types/ScreenState";
import * as Backend from "../../backend/Backend";
import {ErrorView} from "../elements/ErrorView";
import {LoadingView} from "../elements/LoadingView";
import {ScrollView} from "react-native";
import {Divider, ListItem} from "react-native-elements";
import {UserInfo} from "../../types/UserInfo";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
// @ts-ignore
import ColorPalette from 'react-native-color-palette'
import {Role} from "../../types/Role";
import * as Alert from "../../alerts/alerts";

export default function EditEmployeeScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'EditEmployee'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const [state, setState] = React.useState<ScreenState>({
		isLoading: false,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})

	const [employee, setEmployee] = React.useState<UserInfo>(route.params.target)

	const [colorPalette] = React.useState([
		'#d73964',
		'#d23440',
		'#db643a',
		'#e88334',
		'#e2a71e',
		'#e25241',
		'#d0da59',
		'#4053ae',
		'#70b949',
		'#73564a',
		'#67ab5a',
		'#8f36aa',
		'#f6c244',
		'#52b9d0',
		'#4595ec',
		'#009688',
		'#5abeA7',
		'#59bccd',
		'#4a97e4',
		'#2d68cd',
		'#9946c7',
		'#d9639e',
		'#939287',
		'#868ea3',
		'#ffffff',
	]);

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else {
		return (
				<ScrollView style={[styles.container]}>
					<ListItem key={"nameHeader"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title, styles.center]}>Persönliche Informationen</ListItem.Title>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"name"} topDivider bottomDivider containerStyle={[styles.background]}>
						<FontAwesomeIcon icon="user-tag" size={32} style={[styles.text]}/>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.text, styles.title]}>{employee.displayName}</ListItem.Title>
						</ListItem.Content>
						<ListItem.Content style={[]} right={true}>
							<ListItem.Subtitle style={[styles.text, styles.subTitle, styles.textRight]}>{employee.email}</ListItem.Subtitle>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					{
						employee.id !== appContext.user?.id && <>
							<ListItem key={"rankHeader"} topDivider bottomDivider containerStyle={[styles.background]}>
								<ListItem.Content style={[styles.center]}>
									<ListItem.Title style={[styles.title, styles.center]}>Rolle</ListItem.Title>
								</ListItem.Content>
							</ListItem>
							<ListItem key={"role"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => appContext.openDialog({
								title: "Rolle ändern",
								message: "Bitte wähle die neue Rolle für diese/n Mitarbeiter/in",
								picker_visible: true,
								picker_items: [{label: 'Mitarbeiter/in', value: 'EMPLOYEE'}, {label: 'Schichtleiter/in', value: 'SHIFT_LEADER'}, {label: 'Filial-Inhaber/in', value: 'STORE_OWNER'}],
								positiveButton_label: "Speichern",
								positiveButton_onPress(inputValue?: string, pickerValue?: string) {
									if (pickerValue) {
										Backend.sendStoreData("employees/" + employee.id + "/role", setState, appContext, "PUT", {role: pickerValue as Role}).then(() => {
											navigation.goBack()
										})
									}
								},
								negativeButton_visible: true,
								negativeButton_label: "Abbrechen",
							})}>
								<FontAwesomeIcon icon="user-tie" size={32} style={[styles.text]}/>
								<ListItem.Content style={[]}>
									<ListItem.Title style={[styles.text, styles.title]}>{employee.roles.find((ar) => ar.organization.id == appContext.storeId)?.roleDisplay}</ListItem.Title>
									<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Aktuelle Rolle</ListItem.Subtitle>
								</ListItem.Content>
							</ListItem>
							<ListItem key={"removeEmployee"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {
								Alert.confirm("Mitarbeiter/in entfernen", "Möchtest du diese/n Mitarbeiter/in entfernen?", "Entfernen", "Abbrechen", () => {
									Backend.sendStoreData("employees/" + employee.id, setState, appContext, "DELETE").then(() => {
										navigation.goBack()
									})
								}, undefined, 'destructive', 'cancel')
							}}>
								<FontAwesomeIcon icon="user-minus" size={24} style={[styles.text]}/>
								<ListItem.Content style={[]}>
									<ListItem.Title style={[styles.text, styles.title]}>Entlassen</ListItem.Title>
								</ListItem.Content>
							</ListItem>
							<Divider style={[styles.divider]} />
						</>
					}

					<ListItem key={"color"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Farbe</ListItem.Title>
							<ColorPalette
									onChange={(color: string) => {
										Backend.sendStoreData("employees/" + employee.id + "/color", setState, appContext, "PUT", {hexCode: color}).then((data) => {
											setEmployee(data.data)
										})
									}}
									value={employee.roles.find((ar) => ar.organization.id == appContext.storeId)?.hexCode}
									colors={colorPalette}
									title={""}
									icon={<FontAwesomeIcon icon={"check-circle"} size={24} color={'black'} />}
							/>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
				</ScrollView>
		);
	}
}
