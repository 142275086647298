import 'react-native-gesture-handler';
import * as React from 'react';
import {ActivityIndicator, ScrollView} from 'react-native';
import {Divider, ListItem} from 'react-native-elements';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import * as Style from '../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../types/RootStackParamList";
import * as Backend from "../backend/Backend";
import {getAppContext} from "../AppProvider";
import * as Alert from "../alerts/alerts";

export default function ReportsScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'Reports'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()

	const [xReportLoading, setXReportLoading] = React.useState<boolean>(false)

	return (
			<ScrollView style={[styles.container]}>
				<ListItem key={"zReport"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('ReportsList', {type: 'Z'})}>
					<FontAwesomeIcon icon="calendar-day" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>Z-Berichte</ListItem.Title>
						<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Tagesabschlussberichte</ListItem.Subtitle>
					</ListItem.Content>
					<ListItem.Chevron />
				</ListItem>
				<ListItem key={"monthlyReport"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('ReportsList', {type: 'MONTHLY'})}>
					<FontAwesomeIcon icon="calendar-week" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>Monatsberichte</ListItem.Title>
					</ListItem.Content>
					<ListItem.Chevron />
				</ListItem>
				<ListItem key={"yearlyReport"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('ReportsList', {type: 'YEARLY'})}>
					<FontAwesomeIcon icon="calendar" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>Jahresberichte</ListItem.Title>
					</ListItem.Content>
					<ListItem.Chevron />
				</ListItem>
				<Divider style={[styles.divider]} />
				<ListItem key={"newXReport"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {
					if (!xReportLoading) {
						setXReportLoading(true)
						Backend.sendStoreData("report/x", () => {}, appContext, "POST", undefined, false).then(() => {
							Alert.alert("X-Bericht", "Ein X-Bericht wurde erstellt und gedruckt!")
							setXReportLoading(false)
						}).catch(() => {
							setXReportLoading(false)
						})
					}
				}}>
					<FontAwesomeIcon icon="receipt" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>Neuer X-Bericht</ListItem.Title>
						<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Tageszwischenbericht erstellen</ListItem.Subtitle>
					</ListItem.Content>
					{
						xReportLoading &&
						<ActivityIndicator size="small" />
					}
				</ListItem>
				<Divider style={[styles.divider]} />
			</ScrollView>
	);
}
