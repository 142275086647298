import Constants from "expo-constants";

export type KassensturzEnvironment = {
	id: string;
	displayName: string,
	baseURL: string,
}

export const LocalEnvironment: KassensturzEnvironment = {
	id: 'local',
	displayName: "Lokal",
	baseURL: "http://192.168.1.18:9081/",
}

export const DevEnvironment: KassensturzEnvironment = {
	id: 'dev',
	displayName: "Test-Umgebung",
	baseURL: "https://backend.dev.kassensturz.code-crafting.eu/",
}

export const ProdEnvironment: KassensturzEnvironment = {
	id: 'prod',
	displayName: "Normalbetrieb",
	baseURL: "https://backend.prod.kassensturz.code-crafting.eu/",
}

export const Environments = [
	LocalEnvironment,
	DevEnvironment,
	ProdEnvironment,
];

export type Stage = 'Unknown' | 'Local' | 'Development' | 'Preview' | 'Production'

export function getAvailableEnvironments(stage?: Stage): KassensturzEnvironment[] {
	if (stage === undefined) {
		stage = Constants.expoConfig?.extra?.stage ?? 'Unknown';
	}
	switch (stage) {
		case "Unknown":
			return [
				//LocalEnvironment,
				DevEnvironment,
				ProdEnvironment,
			]
		case "Local":
			return [
				LocalEnvironment,
				DevEnvironment,
				ProdEnvironment,
			]
		case "Development":
			return [
				LocalEnvironment,
				DevEnvironment,
			]
		case "Preview":
			return [
				DevEnvironment,
				ProdEnvironment,
			]
		case "Production":
			return [ProdEnvironment]
		default:
			return [ProdEnvironment]
	}
}
