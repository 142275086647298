import 'react-native-gesture-handler';
import * as React from 'react';
import {useState} from 'react';
import {Platform, Text, TouchableOpacity, View} from 'react-native';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {Col, Grid, Row} from 'react-native-easy-grid';
import * as Style from '../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../types/RootStackParamList";
import * as Storage from "../storage/storage";
import {setSentryImpersonateContext} from '../util/SentryWrapper';
import {getAppContext} from '../AppProvider';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import * as Backend from '../backend/Backend';
import * as Alert from '../alerts/alerts';

export default function HomeScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'Home'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const iconSize = 48

	React.useEffect(() => {
		return navigation.addListener('focus', () => {
			Storage.deleteItem("impersonate").then(() => {
				setSentryImpersonateContext(null)
			})
		});
	}, [navigation]);

	const [expoPushToken, setExpoPushToken] = useState('');
	async function registerForPushNotificationsAsync() : Promise<void> {
		if (expoPushToken !== '') {
			console.log('token already set')
			return // token already set
		}
		if (!Device.isDevice || !(Platform.OS === 'ios' || Platform.OS === 'android')) {
			console.log('Current device not suitable for notifications')
			return
		}
		console.log('checking notifications permissions...')
		const { status: existingStatus } = await Notifications.getPermissionsAsync()
		let finalStatus = existingStatus
		if (existingStatus !== 'granted') {
			const { status } = await Notifications.requestPermissionsAsync()
			finalStatus = status
		}
		if (finalStatus !== 'granted') {
			Alert.alert(
					"Benachrichtigungen nicht erlaubt",
					"Bitte ändere dies in den Systemeinstellungen deines Geräts"
			)
			return
		}
		const token = (await Notifications.getExpoPushTokenAsync({
			experienceId: '@cceu_code-crafting_ug_haftungsbeschraenkt/kassensturz-app'
		})).data
		console.log('got expo token!')
		setExpoPushToken(token)
		const storeId = await Storage.getItem("storeId");
		Backend.request("user/notifications/token", () => {}, appContext, "PATCH", {
			notificationToken: token,
			storeId: storeId,
		}, false).then(() => {
			console.log('Sent notification token to backend!')
		})

		if (Platform.OS === 'android') {
			await Notifications.setNotificationChannelAsync('default', {
				name: 'default',
				importance: Notifications.AndroidImportance.MAX,
				vibrationPattern: [0, 250, 250, 250],
				lightColor: '#FF231F7C',
			})
		}
	}

	React.useEffect(() => {
		registerForPushNotificationsAsync().then(r => {})
		return
	}, []);

	return (
			<Grid style={[styles.backgroundLight]}>
				<Row>
					<Col style={[styles.p10]} size={2}>
						<TouchableOpacity onPress={() => navigation.navigate('WaiterSelect', {})} style={[styles.folderContainer]}>
							<View style={[styles.center]}>
								<FontAwesomeIcon icon="cash-register" size={iconSize} style={[styles.text, styles.mb10]}/>
								<Text style={[styles.text, styles.folderButtonText]}>Tischplan</Text>
							</View>
						</TouchableOpacity>
					</Col>
					{false &&
							<Col style={[styles.p10]} size={1}>
								<TouchableOpacity onPress={() => navigation.navigate('Delivery')}
																	style={[styles.folderContainer]}>
									<View style={[styles.center]}>
										<FontAwesomeIcon icon="shipping-fast" size={iconSize}
																		 style={[styles.text, styles.mb10, styles.textDisabled]}/>
										<Text
												style={[styles.text, styles.folderButtonText, styles.textDisabled]}>Lieferung</Text>
									</View>
								</TouchableOpacity>
							</Col>
					}
				</Row>

				<Row>
					<Col style={[styles.p10]}>
						<TouchableOpacity onPress={() => navigation.navigate('WalkIn')} style={[styles.folderContainer]}>
							<View style={[styles.center]}>
								<FontAwesomeIcon icon="person-walking-dashed-line-arrow-right" size={iconSize} style={[styles.text, styles.mb10, styles.textDisabled]}/>
								<Text style={[styles.text, styles.folderButtonText, styles.textDisabled]}>Walk-In</Text>
							</View>
						</TouchableOpacity>
					</Col>
					<Col style={[styles.p10]}>
						<TouchableOpacity onPress={() => navigation.navigate('Reservations')} style={[styles.folderContainer]}>
							<View style={[styles.center]}>
								<FontAwesomeIcon icon="clock" size={iconSize} style={[styles.text, styles.mb10, styles.textDisabled]}/>
								<Text style={[styles.text, styles.folderButtonText, styles.textDisabled]}>Reservierungen</Text>
							</View>
						</TouchableOpacity>
					</Col>
				</Row>

				<Row>
					<Col style={[styles.p10]}>
						<TouchableOpacity onPress={() => navigation.navigate('History')} style={[styles.folderContainer]}>
							<View style={[styles.center]}>
								<FontAwesomeIcon icon="history" size={iconSize} style={[styles.text, styles.mb10]}/>
								<Text style={[styles.text, styles.folderButtonText]}>Historie</Text>
							</View>
						</TouchableOpacity>
					</Col>
					<Col style={[styles.p10]}>
						<TouchableOpacity onPress={() => navigation.navigate('Reports')} style={[styles.folderContainer]}>
							<View style={[styles.center]}>
								<FontAwesomeIcon icon="receipt" size={iconSize} style={[styles.text, styles.mb10]}/>
								<Text style={[styles.text, styles.folderButtonText]}>Berichte</Text>
							</View>
						</TouchableOpacity>
					</Col>
					<Col style={[styles.p10]}>
						<TouchableOpacity onPress={() => navigation.navigate('Business')} style={[styles.folderContainer]}>
							<View style={[styles.center]}>
								<FontAwesomeIcon icon="building" size={iconSize} style={[styles.text, styles.mb10]}/>
								<Text style={[styles.text, styles.folderButtonText]}>Verwaltung</Text>
							</View>
						</TouchableOpacity>
					</Col>
				</Row>

				<Row>
					<Col style={[styles.p10]}>
						<TouchableOpacity onPress={() => navigation.navigate('Insights')} style={[styles.folderContainer]}>
							<View style={[styles.center]}>
								<FontAwesomeIcon icon="chart-line" size={iconSize} style={[styles.text, styles.mb10, styles.textDisabled]}/>
								<Text style={[styles.text, styles.folderButtonText, styles.textDisabled]}>Statistiken</Text>
							</View>
						</TouchableOpacity>
					</Col>
					<Col style={[styles.p10]}>
						<TouchableOpacity onPress={() => navigation.navigate('Export')} style={[styles.folderContainer]}>
							<View style={[styles.center]}>
								<FontAwesomeIcon icon="file-export" size={iconSize} style={[styles.text, styles.mb10]}/>
								<Text style={[styles.text, styles.folderButtonText]}>Export</Text>
							</View>
						</TouchableOpacity>
					</Col>
					<Col style={[styles.p10]}>
						<TouchableOpacity onPress={() => navigation.navigate('Settings')} style={[styles.folderContainer]}>
							<View style={[styles.center]}>
								<FontAwesomeIcon icon="cog" size={iconSize} style={[styles.text, styles.mb10]}/>
								<Text style={[styles.text, styles.folderButtonText]}>Einstellungen</Text>
							</View>
						</TouchableOpacity>
					</Col>
				</Row>
			</Grid>
	);
}
