import {Dimensions, StyleSheet, TextStyle, ViewStyle} from 'react-native';
import {useTheme} from './ThemeProvider';

export function getStyles() {
	const {colors} = useTheme();
	const DeviceDimensions = Dimensions.get('window')
	const MinDimension = Math.min(DeviceDimensions.width, DeviceDimensions.height)

	return StyleSheet.create({
		// General styles
		container: {
			flex: 1,
			backgroundColor: colors.backgroundLight,
		} as ViewStyle,
		positionAbsoluteFill: {
			position: 'absolute',
			borderWidth: 0,
			top: 0,
			bottom: 0,
			right: 0,
			left: 0,
		} as ViewStyle,
		background: {
			backgroundColor: colors.background,
		} as ViewStyle,
		backgroundLight: {
			backgroundColor: colors.backgroundLight,
		} as ViewStyle,
		backgroundBar: {
			backgroundColor: colors.backgroundBar,
		} as ViewStyle,
		backgroundHighlight: {
			backgroundColor: colors.backgroundHighlight,
		} as ViewStyle,
		backgroundPrimary: {
			backgroundColor: colors.primary,
		} as ViewStyle,
		backgroundSecondary: {
			backgroundColor: colors.secondary,
		} as ViewStyle,
		navigationHeader: {
			fontWeight: "bold",
			fontSize: 40,
			color: colors.primary,
			marginBottom: 40
		} as ViewStyle,
		navigationSubheader: {
			fontSize: 20,
			color: colors.text,
			marginTop: -30,
			marginBottom: 30
		} as ViewStyle,
		divider: {
			backgroundColor: colors.backgroundLight,
			height: 32,
			borderBottomWidth: 0,
		} as ViewStyle,
		center: {
			alignItems: 'center',
			justifyContent: 'center',
			textAlign: 'center',
		} as ViewStyle,
		textRight: {
			textAlign: 'right',
		} as ViewStyle,
		fullWidth: {
			width: '100%',
		} as ViewStyle,
		w80p: {
			width: '80%',
		} as ViewStyle,
		h80p: {
			height: '80%',
		} as ViewStyle,
		h100p: {
			height: '100%',
		} as ViewStyle,
		title: {
			color: colors.text,
			fontWeight: 'bold',
		} as ViewStyle,
		subTitle: {
			color: colors.textSecondary,
		} as ViewStyle,
		text: {
			color: colors.text,
		} as TextStyle,
		textSecondary: {
			color: colors.textSecondary,
		} as ViewStyle,
		textDisabled: {
			color: colors.textDisabled,
		} as ViewStyle,
		link: {
			color: colors.linkColor,
		} as ViewStyle,
		errorIcon: {
			color: colors.errorIcon,
		} as ViewStyle,
		errorText: {
			color: colors.errorText,
		} as ViewStyle,
		infoColor: {
			color: colors.infoColor,
		} as ViewStyle,
		warningColor: {
			color: colors.warningColor,
		} as ViewStyle,
		destructive: {
			color: colors.destructiveColor,
		} as ViewStyle,
		p10: {
			padding: 10,
		} as ViewStyle,
		m10: {
			margin: 10,
		} as ViewStyle,
		mt10: {
			marginTop: 10,
		} as ViewStyle,
		mb10: {
			marginBottom: 10,
		} as ViewStyle,
		mr10: {
			marginRight: 10,
		} as ViewStyle,
		ml10: {
			marginLeft: 10,
		} as ViewStyle,
		ml32: {
			marginLeft: 32,
		} as ViewStyle,
		mx10: {
			marginLeft: 10,
			marginRight: 10,
		} as ViewStyle,
		my10: {
			marginTop: 10,
			marginBottom: 10,
		} as ViewStyle,

		// Main Menu specific styles
		folderContainer: {
			margin: 1,
			marginTop: 'auto',
			marginBottom: 'auto',
			padding: MinDimension/20,
			borderRadius: 10,
			backgroundColor: colors.backgroundHighlight,
		} as ViewStyle,
		folderButtonText: {
			fontWeight: 'bold',
			fontSize: 10,
			textAlign: 'center',
		} as ViewStyle,

		// Login View specific styles
		loginBtnStyle: {
			width: "100%",
			backgroundColor: colors.primary,
			borderRadius:25,
			height:50,
			alignItems:"center",
			justifyContent:"center",
			marginTop:10,
			marginBottom:10
		} as ViewStyle,
		loginInputView: {
			backgroundColor: colors.backgroundHighlight,
			borderRadius:25,
			height:50,
			marginBottom:20,
			justifyContent:"center",
			padding:20
		} as ViewStyle,
	})
}
