import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {Col, Grid, Row} from 'react-native-easy-grid';
import {
	RefreshControl,
	Route,
	SafeAreaView,
	ScrollView,
	Text,
	TouchableOpacity,
	View,
	ViewStyle
} from 'react-native';
import {Divider, ListItem} from 'react-native-elements';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import * as Alert from "../../alerts/alerts";
import * as Backend from '../../backend/Backend';
import {ErrorView} from '../elements/ErrorView';
import {LoadingView} from '../elements/LoadingView';
import {Button, Dialog, Portal} from 'react-native-paper';
import {getAppContext} from '../../AppProvider';
import * as Storage from '../../storage/storage';
import {ScreenState} from "../../types/ScreenState";
import {
	createStackNavigator,
	StackNavigationOptions,
	StackScreenProps
} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {LocalizationContext} from "../../LocalizationContext";
import {Article, ArticleCategory, ArticleGroup, ArticleType} from "../../types/Article";
import {
	ArticleOrderRequestItem,
	CustomOrderRequestItem,
	OrderRequest
} from "../../types/OrderRequest";
import {useTheme} from "../../theme/ThemeProvider";

const Stack = createStackNavigator();

export default function OrderCategoriesScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'OrderCategories'>) {
	const styles = Style.getStyles()
	const {colors} = useTheme();
	const appContext = getAppContext()
	const { locale } = React.useContext(LocalizationContext);

	const [state, setState] = React.useState<ScreenState>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})

	const [articleHistory, setArticleHistory] = React.useState<string[]>([])
	const [favoriteArticles, setFavoriteArticles] = React.useState<string[]>([])
	function toggleFavourite(articleId: string) {
		let newFavorites: string[]
		if (favoriteArticles.includes(articleId)) {
			newFavorites = favoriteArticles.filter((id) => id !== articleId)
		} else {
			newFavorites = [...favoriteArticles, articleId]
		}
		Storage.setItem("favoriteArticles", JSON.stringify(newFavorites)).then(r => {
			setFavoriteArticles(newFavorites)
		})
	}

	const iconSize = 48

	type ExtendedOrderRequestItem = ArticleOrderRequestItem & {name: string}
	const [orderItems, setOrderItems] = React.useState<(ExtendedOrderRequestItem | CustomOrderRequestItem)[]>([])

	const [orderItemDialogVisible, setOrderItemDialogVisible] = React.useState<boolean>(false)
	const [orderItemDialogTarget, setOrderItemDialogTarget] = React.useState<number>(0)

	const [differentiator, setDifferentiator] = React.useState<string>('')

	const [expandedGroup, setExpandedGroup] = React.useState<string|undefined>(undefined)

	function addOrder(newItem: (ExtendedOrderRequestItem | CustomOrderRequestItem)) {
		let found = false
		let newOrderItems = orderItems.map(item => {
			if (!found && 'articleId' in item && item.articleId === (newItem as ExtendedOrderRequestItem).articleId && item.course === newItem.course && item.specialWishes === newItem.specialWishes) {
				item.quantity += newItem.quantity
				found = true
			}
			return item
		})
		if (!found) {
			newOrderItems.push(newItem)
		}
		setOrderItems(newOrderItems)
	}

	function submitOrder() {
		const orderRequest: OrderRequest = {
			articles: orderItems.filter(item => 'articleId' in item).map((item) => ({
				course: item.course,
				articleId: (item as ExtendedOrderRequestItem).articleId,
				quantity: item.quantity,
				specialWishes: item.specialWishes,
				articleType: item.articleType,
				unitPrice: item.unitPrice,
			})),
			custom: orderItems.filter(item => 'taxGroup' in item).map(item => item as CustomOrderRequestItem),
		}
		Backend.sendTableData("order", route?.params?.tableData.tableId, route?.params?.tableData.partyNumber, setState, appContext, "PATCH", orderRequest, false).then((response) => {
			setOrderItems([])
			navigation.navigate('Floorplan')
			/*
			if (route?.params?.initialOrder === true) {
				navigation.dispatch(
						StackActions.replace('OpenTable', {tableData: route?.params?.tableData})
				)
			} else {
				navigation.navigate('OpenTable', {tableData: route?.params?.tableData})
			}
			*/
		})
	}

	function loadData() {
		Backend.loadArticles(setState, appContext).then(() => {})
		Storage.getItem("favoriteArticles").then(r => {
			if (r !== null) {
				setFavoriteArticles(JSON.parse(r));
			}
		})
		if (route?.params?.articleHistory) {
			setArticleHistory(route?.params?.articleHistory)
		} else {
			setArticleHistory([])
		}
	}
	React.useEffect(() => {
		return navigation.addListener('focus', loadData);
	}, [navigation]);

	React.useEffect(() =>
			navigation.addListener('beforeRemove', (e) => {
				if (orderItems?.length === 0) { // If we don't have unsaved changes, then we don't need to do anything
					return;
				}
				e.preventDefault(); // Prevent default behavior of leaving the screen

				Alert.confirm(
						'Bestellung beenden',
						'Du hast die Bestellung noch nicht abgesendet. Möchtest du wirklich beenden?',
						'Beenden',
						'Fortsetzen',
						() => navigation.dispatch(e.data.action),
						() => {},
						'destructive',
						'cancel'
				);
			}), [navigation, orderItems]);

	React.useEffect(() => {
		if (route?.params?.customOrderItem !== undefined && route.params.customOrderItem.differentiator !== differentiator) {
			const item = route.params.customOrderItem;
			setDifferentiator(item.differentiator)
			const customOrderItem: (CustomOrderRequestItem & {differentiator?: string}) = item
			delete customOrderItem.differentiator
			console.log('found new custom order item');
			console.log(customOrderItem);
			addOrder(customOrderItem);
		}
	}, [route.params]);

	React.useLayoutEffect(() => {
		navigation.setOptions({
			headerRight: () => (
					<Button onPress={() => navigation.navigate('CustomOrderItem', {tableData: route?.params?.tableData})}>
						<FontAwesomeIcon icon="plus" size={24} style={[styles.text]}/>
					</Button>
			),
		});
	}, [navigation]);

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen", "Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else {
		let categories: ArticleCategory[] = state.response?.data?.categories
		let articles: Article[] = state.response?.data?.articles
		let groups: ArticleGroup[] = state.response?.data?.groups

		function CategoriesView({ route, navigation }: StackScreenProps<RootStackParamList, 'OrderCategoriesInner'>): JSX.Element {
			let rows: any[][] = [[]]
			if (favoriteArticles.length > 0) {
				rows[0].push((
						<Col style={[styles.p10]} size={2} key="favorites">
							<TouchableOpacity onPress={() => navigation.navigate('OrderArticlesInner', {category: undefined})} style={[styles.folderContainer]}>
								<View style={[styles.center]}>
									<FontAwesomeIcon icon="heart" size={iconSize} style={[styles.text, styles.mb10, {color: '#f00'} as ViewStyle]}/>
									<Text style={[styles.text, styles.folderButtonText]}>Favoriten</Text>
								</View>
							</TouchableOpacity>
						</Col>
				))
			}
			if (articleHistory.length > 0) {
				rows[0].push((
						<Col style={[styles.p10]} size={2} key="articleHistory">
							<TouchableOpacity onPress={() => navigation.navigate('OrderArticlesInner', {category: null})} style={[styles.folderContainer]}>
								<View style={[styles.center]}>
									<FontAwesomeIcon icon="redo" size={iconSize} style={[styles.text, styles.mb10, {color: '#38ad23'} as ViewStyle]}/>
									<Text style={[styles.text, styles.folderButtonText]}>Historie</Text>
								</View>
							</TouchableOpacity>
						</Col>
				))
			}
			categories.forEach((category, i) => {
				let rowIndex = 0
				while (rows[rowIndex] !== undefined && rows[rowIndex].length >= 3) rowIndex++
				if (rows[rowIndex] === undefined) rows[rowIndex] = []
				rows[rowIndex].push(
						<Col style={[styles.p10]} size={2} key={"category" + i}>
							<TouchableOpacity onPress={() => {
								navigation.navigate('OrderArticlesInner', {category: category});
							}} style={[styles.folderContainer]}>
								<View style={[styles.center]}>
									<FontAwesomeIcon icon={category.faIcon} size={iconSize} style={[styles.text, styles.mb10, {color: category.hexCode} as ViewStyle]}/>
									<Text style={[styles.text, styles.folderButtonText]}>{category.name}</Text>
								</View>
							</TouchableOpacity>
						</Col>
				);
			})
			return (
					<ScrollView style={[styles.container]} refreshControl={<RefreshControl onRefresh={loadData} refreshing={state.refreshing} />}>
						{rows.map((row, i) => (<Row key={'row' + i}>{row}</Row>))}
					</ScrollView>
			)
		}

		function ArticlesView({ route, navigation }: StackScreenProps<RootStackParamList, 'OrderArticlesInner'>): JSX.Element {
			const category = route?.params?.category
			return (
					<ScrollView style={[styles.container]} refreshControl={<RefreshControl onRefresh={loadData} refreshing={state.refreshing} />}>
						{
							category === undefined && listArticles('favorites',
									article => favoriteArticles.includes(article.id) && (article.group === undefined || article.group === null || !favoriteArticles.includes(article.group)),
									articleGroup => favoriteArticles.includes(articleGroup.id))
						}
						{
							category === null && listArticles('history',
									article => articleHistory.includes(article.id),
									articleGroup => false)
						}
						{
							category !== undefined && category !== null && listArticles(category?.id || '',
									article => article.category === category?.id && (article.group === undefined || article.group === null),
									articleGroup => articleGroup.category === category?.id)
						}
					</ScrollView>
			)
		}

		function listArticles(id: string, articleFilter: (article: Article) => boolean, groupFilter: (articleGroup: ArticleGroup) => boolean, indent: boolean = false) {
			return <View key={"articleList" + id} style={[{paddingLeft: 0}]}>
				{
					groups.filter(groupFilter).filter(group => articles.filter(article => article.group === group.id).length > 0).map((articleGroup: ArticleGroup) => {
						return (
								<View key={"group" + articleGroup.id}>
									<ListItem key={"articleGroupTrigger" + articleGroup.id} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {
										if (expandedGroup === articleGroup.id) {
											setExpandedGroup(undefined)
										} else {
											setExpandedGroup(articleGroup.id)
										}
									}}>
										<TouchableOpacity onPress={() => toggleFavourite(articleGroup.id)}>
											<FontAwesomeIcon icon="heart" style={[{color: favoriteArticles.includes(articleGroup.id) ? 'red' : 'gray'} as ViewStyle]} />
										</TouchableOpacity>
										<ListItem.Content>
											<ListItem.Title style={[styles.text, styles.title]}>{articleGroup.name}</ListItem.Title>
										</ListItem.Content>
										<ListItem.Content right={true}>
											{
												expandedGroup === articleGroup.id && <FontAwesomeIcon icon="minus" style={[styles.text]}/>
											}
											{
												expandedGroup !== articleGroup.id && <FontAwesomeIcon icon="plus" style={[styles.text]}/>
											}
										</ListItem.Content>
									</ListItem>
									{
										expandedGroup === articleGroup.id && listArticles(articleGroup.id, article => article.group === articleGroup.id, () => false, true)
									}
									<Divider style={[styles.divider]} />
								</View>
						)
					})
				}
				{
					articles.filter(articleFilter).map((article: Article) => {
						return (
								<View key={"articleView" + article.id}>
									<TouchableOpacity onPress={() => {
										if (article.type === ArticleType.VOUCHER_MULTIPURPOSE) {
											appContext.openDialog({
												title: "Menge angeben",
												message: "Bitte gib den Wert des Gutscheins in Euro ein:",
												input_visible: true,
												input_keyboardType: 'decimal-pad',
												input_placeholder: "10,5",
												positiveButton_label: "Hinzufügen",
												positiveButton_onPress(inputValue?: string) {
													if (inputValue) {
														addOrder({
															articleId: article.id,
															name: article.name,
															quantity: Number(inputValue.replace(",", ".")) / article.unitPrice,
															course: 0,
															articleType: article.type,
															unitPrice: article.unitPrice,
														})
													}
												},
												negativeButton_visible: true,
												negativeButton_label: "Abbrechen",
											})
										} else {
											addOrder({
												articleId: article.id,
												name: article.name,
												quantity: 1,
												course: 0,
												articleType: article.type,
												unitPrice: article.unitPrice,
											})
										}
									}} onLongPress={() => {
										// TODO
									}}
									>
										<ListItem key={"article" + article.id} topDivider bottomDivider containerStyle={[styles.background]}>
											{
												indent && <View style={[{width: 10}]}/>
											}
											{
												indent && <TouchableOpacity onPress={() => toggleFavourite(article.id)}>
													<FontAwesomeIcon icon="heart" style={[{color: favoriteArticles.includes(article.id) ? 'red' : 'gray'} as ViewStyle]} />
												</TouchableOpacity>
											}
											{
												!indent && <TouchableOpacity onPress={() => toggleFavourite(article.id)}>
													<FontAwesomeIcon icon="heart" style={[{color: favoriteArticles.includes(article.id) ? 'red' : 'gray'} as ViewStyle]} />
												</TouchableOpacity>
											}
											<ListItem.Content>
												<ListItem.Title style={[styles.text, styles.title]}>{article.name}</ListItem.Title>
											</ListItem.Content>
											<ListItem.Content right={true}>
												<ListItem.Subtitle style={[styles.text, styles.subTitle, styles.textRight]}>
													{article["unitPrice"].toLocaleString(locale, { style: 'currency', currency: 'EUR' })}
													{isFinite(article.stock) && ", Verfügbar: " + article["stock"].toLocaleString(locale, { style: 'decimal' })}
												</ListItem.Subtitle>
											</ListItem.Content>
										</ListItem>
									</TouchableOpacity>
									{
										article.type == ArticleType.DEPOSIT &&
										<TouchableOpacity onPress={() => {
											addOrder({
												articleId: article.id,
												name: article.name,
												quantity: -1,
												course: 0,
												specialWishes: "(Rückgabe)",
												articleType: article.type,
												unitPrice: article.unitPrice,
											})
										}}>
											<ListItem key={"article" + article.id} topDivider containerStyle={[styles.background]}>
												<TouchableOpacity onPress={() => toggleFavourite(article.id)}>
													<FontAwesomeIcon icon="heart" style={[{color: favoriteArticles.includes(article.id) ? 'red' : 'gray'} as ViewStyle]} />
												</TouchableOpacity>
												<ListItem.Content>
													<ListItem.Title style={[styles.text, styles.title]}>Rückgabe: {article.name}</ListItem.Title>
												</ListItem.Content>
												<ListItem.Content right={true}>
													<ListItem.Subtitle style={[styles.text, styles.subTitle, styles.textRight]}>
														{(-article["unitPrice"]).toLocaleString(locale, { style: 'currency', currency: 'EUR' })}
														{isFinite(article.stock) && ", Verfügbar: " + article["stock"].toLocaleString(locale, { style: 'decimal' })}
													</ListItem.Subtitle>
												</ListItem.Content>
											</ListItem>
										</TouchableOpacity>
									}
									{
										(article.type == ArticleType.VOUCHER_SINGLEPURPOSE || article.type == ArticleType.VOUCHER_MULTIPURPOSE) &&
										<TouchableOpacity onPress={() => {
											if (article.type === ArticleType.VOUCHER_MULTIPURPOSE) {
												appContext.openDialog({
													title: "Menge angeben",
													message: "Bitte gib den Wert des Gutscheins in Euro ein:",
													input_visible: true,
													input_keyboardType: 'decimal-pad',
													input_placeholder: "10,5",
													positiveButton_label: "Hinzufügen",
													positiveButton_onPress(inputValue?: string) {
														if (inputValue) {
															addOrder({
																articleId: article.id,
																name: article.name,
																quantity: -Number(inputValue.replace(",", ".")),
																course: 0,
																specialWishes: "(Einlösen)",
																articleType: article.type,
																unitPrice: article.unitPrice,
															})
														}
													},
													negativeButton_visible: true,
													negativeButton_label: "Abbrechen",
												})
											} else {
												addOrder({
													articleId: article.id,
													name: article.name,
													quantity: -1,
													course: 0,
													specialWishes: "(Einlösen)",
													articleType: article.type,
													unitPrice: article.unitPrice,
												})
											}
										}}>
											<ListItem key={"article" + article.id} topDivider containerStyle={[styles.background]}>
												<TouchableOpacity onPress={() => toggleFavourite(article.id)}>
													<FontAwesomeIcon icon="heart" style={[{color: favoriteArticles.includes(article.id) ? 'red' : 'gray'} as ViewStyle]} />
												</TouchableOpacity>
												<ListItem.Content>
													<ListItem.Title style={[styles.text, styles.title]}>Einlösen: {article.name}</ListItem.Title>
												</ListItem.Content>
												<ListItem.Content right={true}>
													<ListItem.Subtitle style={[styles.text, styles.subTitle, styles.textRight]}>
														{(-article["unitPrice"]).toLocaleString(locale, { style: 'currency', currency: 'EUR' })}
														{isFinite(article.stock) && ", Verfügbar: " + article["stock"].toLocaleString(locale, { style: 'decimal' })}
													</ListItem.Subtitle>
												</ListItem.Content>
											</ListItem>
										</TouchableOpacity>
									}
								</View>
						)
					})
				}
			</View>
		}

		return (
				<Grid style={[styles.container]}>
					<Row size={6}>
						<Stack.Navigator initialRouteName={'OrderCategoriesInner'} screenOptions={{
							headerStyle: styles.backgroundBar,
							headerTintColor: colors.text.toString(),
							headerTitleStyle: [styles.text, {
								fontWeight: 'bold',
							}],
						}}>
							<Stack.Screen name="OrderCategoriesInner" component={CategoriesView} options={{title: "Kategorien", headerShown: false}}/>
							<Stack.Screen name="OrderArticlesInner" component={ArticlesView} options={((props: { route: Route; navigation: any; }) =>
									({ title: props.route.params?.category?.name || "Artikel", headerShown: true} as StackNavigationOptions))}/>
						</Stack.Navigator>
					</Row>
					{
						orderItems.length > 0 &&
						<Row size={4}>
							<Col>
								<ListItem key={"orderHeader"} topDivider bottomDivider containerStyle={[styles.backgroundBar]}>
									<ListItem.Content style={[styles.center]}>
										<ListItem.Title style={[styles.text, styles.subTitle]}>Bestellung</ListItem.Title>
									</ListItem.Content>
								</ListItem>
								<ScrollView style={[styles.container]}>
									{
										orderItems.map((item, i) =>
												<ListItem key={"orderArticle" + i} topDivider containerStyle={[styles.background]}>
													<TouchableOpacity onPress={() => {
														addOrder({...item, quantity: 1})
													}}>
														<FontAwesomeIcon icon="plus-circle" style={[styles.link]} size={24} />
													</TouchableOpacity>
													<ListItem.Content style={[]}>
														{
															(item.articleType === ArticleType.ARTICLE || item.articleType === ArticleType.DEPOSIT) &&
															<ListItem.Title style={[styles.text, styles.title]}>{item.quantity} x {item.name}</ListItem.Title>
														}
														{
															(item.articleType === ArticleType.VOUCHER_SINGLEPURPOSE || item.articleType === ArticleType.VOUCHER_MULTIPURPOSE) &&
															<ListItem.Title style={[styles.text, styles.title]}>{(item.quantity*item.unitPrice).toLocaleString(locale, {style: 'currency', currency: 'EUR'})} {item.name}</ListItem.Title>
														}
														{
															item.specialWishes !== undefined &&
															<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Notiz: {item.specialWishes}</ListItem.Subtitle>
														}
													</ListItem.Content>
													{
														item.course !== 0 &&
														<ListItem.Content style={[]} right={true}>
															<ListItem.Title style={[styles.text, styles.title]}>Gang {item.course}</ListItem.Title>
														</ListItem.Content>
													}
													<TouchableOpacity onPress={() => {
														setOrderItemDialogTarget(i)
														setOrderItemDialogVisible(true)
													}}>
														<FontAwesomeIcon icon="edit" style={[styles.link]} size={24} />
													</TouchableOpacity>
												</ListItem>
										)
									}
								</ScrollView>
								<Portal>
									<Dialog visible={orderItemDialogVisible} onDismiss={() => setOrderItemDialogVisible(false)}>
										<Dialog.Title>Bestellung bearbeiten</Dialog.Title>
										<Dialog.Content>
											<Button onPress={() => {
												appContext.openDialog({
													title: "Menge ändern",
													input_visible: true,
													input_keyboardType: 'decimal-pad',
													positiveButton_label: "Speichern",
													positiveButton_onPress(inputValue?: string) {
														setOrderItems((items) => {
															items[orderItemDialogTarget].quantity = inputValue === "" || inputValue === undefined ? 1 : Number(inputValue.replace(",", "."))
															return items
														})
													},
													negativeButton_visible: true,
													negativeButton_label: "Abbrechen",
												});
												setOrderItemDialogVisible(false)
											}} mode={"outlined"} style={[styles.my10]}>Menge ändern</Button>
											<Button onPress={() => {
												appContext.openDialog({
													title: "Gang ändern",
													message: "Hinweis: Gang 0 wird immer sofort abgerufen",
													input_visible: true,
													input_keyboardType: 'number-pad',
													positiveButton_label: "Speichern",
													positiveButton_onPress(inputValue?: string) {
														setOrderItems((items) => {
															items[orderItemDialogTarget].course = inputValue === "" || inputValue === undefined ? 1 : parseInt(inputValue)
															return items
														})
													},
													negativeButton_visible: true,
													negativeButton_label: "Abbrechen",
												});
												setOrderItemDialogVisible(false)
											}} mode={"outlined"} style={[styles.my10]}>Gang ändern</Button>
											<Button onPress={() => {
												appContext.openDialog({
													title: "Sonderwunsch",
													input_visible: true,
													input_keyboardType: 'default',
													positiveButton_label: "Speichern",
													positiveButton_onPress(inputValue?: string) {
														setOrderItems((items) => {
															items[orderItemDialogTarget].specialWishes = inputValue === undefined || inputValue.length === 0 ? undefined : inputValue
															return items
														})
													},
													negativeButton_visible: true,
													negativeButton_label: "Abbrechen",
												});
												setOrderItemDialogVisible(false)
											}} mode={"outlined"} style={[styles.my10]}>Sonderwunsch</Button>
											<Button onPress={() => {
												setOrderItemDialogVisible(false)
												setOrderItems((items) => {
													items.splice(orderItemDialogTarget, 1)
													return items
												})
											}} mode={"outlined"} style={[styles.my10]}>Löschen</Button>
										</Dialog.Content>
										<Dialog.Actions>
											<Button onPress={() => setOrderItemDialogVisible(false)} mode={"contained"} style={[styles.mr10]}>Abbrechen</Button>
										</Dialog.Actions>
									</Dialog>
								</Portal>
							</Col>
						</Row>
					}
					{
						orderItems.length > 0 &&
						<Row size={1}>
							<Col size={2} style={[styles.center, styles.backgroundPrimary]} onPress={submitOrder}>
								<SafeAreaView>
									<Text style={[styles.title, { fontSize: 20}]}>Abschicken</Text>
								</SafeAreaView>
							</Col>
						</Row>
					}
				</Grid>
		)
	}
}
