import * as React from 'react';
import {SafeAreaView, Text} from 'react-native';
import * as Style from '../../theme/style';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {IconProp} from "@fortawesome/fontawesome-svg-core";

export function InfoView(title = "Information!", description = "", icon: IconProp = "info-circle") {
	const styles = Style.getStyles()

	return (
			<SafeAreaView style={[styles.container, styles.center]}>
				<FontAwesomeIcon icon={icon} size={ 64 } style={[styles.infoColor, {marginTop: 16}]} />
				<Text style={[styles.text, styles.title, {
					marginTop: 20
				}]}>{title}</Text>
				<Text style={[styles.text, {
					marginTop: 10,
					marginBottom: 10,
					textAlign: 'center',
				}]}>{description}</Text>
			</SafeAreaView>
	)
}
