import {createStackNavigator, StackNavigationOptions} from "@react-navigation/stack";
import * as React from "react";
import {NavigationContainer, NavigationContainerRef} from "@react-navigation/native";
import {RootStackParamList} from "../types/RootStackParamList";
import * as Style from "../theme/style";
import {useTheme} from "../theme/ThemeProvider";
import {Dimensions, Route} from "react-native";
import {LocalizationContext} from "../LocalizationContext";
import {ScreenState} from "../types/ScreenState";
import {getAvailableEnvironments, KassensturzEnvironment, ProdEnvironment} from "../KassensturzEnvironment";
import {NotificationData} from "../types/NotificationData";
import * as Notifications from "expo-notifications";
import {DialogState} from "../types/DialogState";
import * as Storage from "../storage/storage";
import * as Backend from "../backend/Backend";
import {ErrorView} from "./elements/ErrorView";
import {LoadingView} from "./elements/LoadingView";
import {AssignedRole} from "../types/Role";
import {Store} from "../types/Organization";
import {StackActions} from "@react-navigation/routers";
import {AppContext} from "../AppProvider";
import DialogWrapper from "./components/dialog/DialogWrapper";
import LoginScreen from "./LoginScreen";
import RegisterScreen from "./RegisterScreen";
import BusinessCreateScreen from "./setup/BusinessCreateScreen";
import StoreCreateScreen from "./setup/StoreCreateScreen";
import HomeScreen from "./HomeScreen";
import FloorplanScreen from "./FloorplanScreen";
import OpenTableScreen from "./table/OpenTableScreen";
import {OpenTableInfo} from "../types/OpenTable";
import OrderCategoriesScreen from "./table/OrderCategoriesScreen";
import CustomOrderItemScreen from "./table/CustomOrderItemScreen";
import WaiterSelectScreen from "./WaiterSelectScreen";
import PaymentScreen from "./table/payment/PaymentScreen";
import PaymentSelectPartScreen from "./table/payment/PaymentSelectPartScreen";
import SelectCustomerScreen from "./customer/SelectCustomerScreen";
import DeliveryScreen from "./DeliveryScreen";
import WalkInScreen from "./WalkInScreen";
import ReservationsScreen from "./ReservationsScreen";
import AddReservationScreen from "./reservation/AddReservationScreen";
import ReservationDetailsScreen from "./reservation/ReservationDetailsScreen";
import HistoryScreen from "./HistoryScreen";
import HistoryTableScreen from "./history/HistoryTableScreen";
import ReportsScreen from "./ReportsScreen";
import ReportsListScreen from "./reports/ReportsListScreen";
import ReportDetailScreen from "./reports/ReportDetailScreen";
import BusinessScreen from "./BusinessScreen";
import EmployeeScreen from "./business/EmployeeScreen";
import EditEmployeeScreen from "./business/EditEmployeeScreen";
import EditFloorplanScreen from "./business/EditFloorplanScreen";
import EditArticlesScreen from "./business/EditArticlesScreen";
import EditArticleCategoryScreen from "./business/EditArticleCategoryScreen";
import ArticleCreateScreen from "./business/ArticleCreateScreen";
import CreateVoucherScreen from "./business/CreateVoucherScreen";
import EditLogoScreen from "./business/EditLogoScreen";
import PrintersScreen from "./business/PrintersScreen";
import EditPrinterScreen from "./business/EditPrinterScreen";
import PairStoreHubScreen from "./business/PairStoreHubScreen";
import StoreHubDetailsScreen from "./business/StoreHubDetailsScreen";
import WagePaymentScreen from "./business/WagePaymentScreen";
import PaymentTypeExchangeScreen from "./business/PaymentTypeExchangeScreen";
import MoneyTransferScreen from "./business/MoneyTransferScreen";
import CapitalScreen from "./business/CapitalScreen";
import DiffScreen from "./business/DiffScreen";
import OtherPaymentScreen from "./business/OtherPaymentScreen";
import InsightsScreen from "./InsightsScreen";
import ExportScreen from "./ExportScreen";
import SettingsScreen from "./SettingsScreen";
import StoreSelectScreen from "./settings/StoreSelectScreen";
import AccountScreen from "./settings/AccountScreen";

const Stack = createStackNavigator();
const navigationRef = React.createRef<NavigationContainerRef<RootStackParamList>>();

export function MainNavigation() {
    const styles = Style.getStyles()
    const {colors} = useTheme();
    const DeviceDimensions = Dimensions.get('window')
    const MinDimension = Math.min(DeviceDimensions.width, DeviceDimensions.height)
    const {t} = React.useContext(LocalizationContext);

    const [c, setC] = React.useState(0);
    const [state, setState] = React.useState<ScreenState & {
        storeId: string | null,
        environment: KassensturzEnvironment
    }>({
        isLoading: true,
        customError: undefined,
        error: undefined,
        response: undefined,
        refreshing: false,
        storeId: null,
        environment: ProdEnvironment,
    })

    const [lastNotificationData, setLastNotificationData] = React.useState<NotificationData | undefined>(undefined)
    React.useEffect(() => {
        const subscription = Notifications.addNotificationResponseReceivedListener(notificationResponse => {
            console.log('notification');
            console.log(notificationResponse);
            if (
                notificationResponse &&
                notificationResponse.notification.request.content.data &&
                notificationResponse.actionIdentifier === Notifications.DEFAULT_ACTION_IDENTIFIER
            ) {
                const data: NotificationData = notificationResponse.notification.request.content.data as NotificationData
                setLastNotificationData(data)
                console.log('notification data: ' + JSON.stringify(data))
            }
        })
        return () => subscription.remove();
    }, []);

    const defaultDialogState: DialogState = {
        isVisible: false,
        title: 'Title',
        message: undefined,
        onBackdropPress: () => {
        },
        input_visible: false,
        input_defaultValue: '',
        input_placeholder: '',
        input_keyboardType: 'default',
        picker_visible: false,
        picker_items: [],
        positiveButton_label: 'Ok',
        positiveButton_bold: true,
        positiveButton_disabled: false,
        positiveButton_onPress: () => {
        },
        middleButton_visible: false,
        middleButton_label: 'Ok',
        middleButton_bold: false,
        middleButton_disabled: false,
        middleButton_onPress: () => {
        },
        negativeButton_visible: true,
        negativeButton_label: 'Cancel',
        negativeButton_bold: false,
        negativeButton_disabled: false,
        negativeButton_onPress: () => {
        },
    }
    const [dialog, setDialog] = React.useState<DialogState>(defaultDialogState)

    function closeDialog(): void {
        setDialog((state) => ({...state, isVisible: false}));
    }

    function openDialog(dialog: Partial<DialogState>) {
        setDialog(() => ({...defaultDialogState, ...dialog, isVisible: true}));
    }

    function forceReload() {
        forceAsyncReload().then(() => {
        })
    }

    async function forceAsyncReload() {
        setState((s) => ({...s, storeId: null}));
        Storage.getItem("storeId").then(storeId => {
            setState((s) => ({...s, storeId: storeId}));
        })
        const environment = await Storage.getItem("environment");
        let env: KassensturzEnvironment = getAvailableEnvironments().find(e => e.id === environment) ?? state.environment
        setState((s) => ({...s, environment: env}));
        Backend.timeout(2000, Backend.request("user", setState, {
            deviceDimensions: DeviceDimensions,
            minDimension: MinDimension,
            openDialog,
            forceReloadApp: forceReload,
            storeId: null,
            environment: env
        })).then((r) => {
        }).catch(() => {
            setState((s) => ({...s, isLoading: false, error: undefined, customError: undefined}));
        })
        setC(c + 1)
    }

    React.useEffect(forceReload, [])

    if (state.error) {
        return ErrorView(null, state.error.message)
    } else if (state.customError !== undefined && state.customError !== null) {
        console.log(JSON.stringify(state.customError))
        return ErrorView(state.customError?.title, state.customError?.description)
    } else if (state.isLoading || state.storeId === undefined) {
        return LoadingView()
    } else {
        let initialRoute;
        let store;
        if (!state.response) {
            initialRoute = "Login"
        } else if (state.response.data.roles.map((r: AssignedRole) => r.organization.id).includes(state.storeId)) {
            store = state.response.data.roles.find((r: AssignedRole) => r.organization.id === state.storeId)?.organization as Store;
            initialRoute = "Home"
            if (lastNotificationData && lastNotificationData.action === 'NAVIGATE') {
                const notificationData = lastNotificationData;
                setLastNotificationData(undefined) // Unset to prevent doubled handling

                navigationRef.current?.dispatch(StackActions.popToTop());
                switch (notificationData.targetType) {
                    case 'REPORTS':
                        navigationRef.current?.navigate('Reports')
                        if (notificationData.targetSubType) {
                            navigationRef.current?.navigate('ReportsList', {
                                type: notificationData.targetSubType
                            })
                            if (notificationData.targetId) {
                                navigationRef.current?.navigate('ReportDetail', {
                                    reportIdentifier: {
                                        type: notificationData.targetSubType,
                                        id: notificationData.targetId,
                                    }
                                })
                            }
                        }
                        break
                }
            }
        } else {
            initialRoute = "StoreSelect"
        }
        return (
            <AppContext.Provider value={{
                forceReloadApp: forceReload,
                openDialog: openDialog,
                deviceDimensions: DeviceDimensions,
                minDimension: MinDimension,
                storeId: state.storeId,
                user: state.response?.data,
                environment: state.environment,
            }}>
                <DialogWrapper dialog={dialog} closeDialog={closeDialog}/>
                <NavigationContainer ref={navigationRef}>
                    <Stack.Navigator initialRouteName={initialRoute} screenOptions={{
                        headerStyle: styles.backgroundBar,
                        headerTintColor: colors.text.toString(),
                        headerTitleStyle: [styles.text, {
                            fontWeight: 'bold',
                        }],
                    }}>
                        <Stack.Screen name="Login" component={LoginScreen} options={{title: "Login"}}/>
                        <Stack.Screen name="Register" component={RegisterScreen} options={{title: "Register"}}/>

                        <Stack.Screen name="BusinessCreate" component={BusinessCreateScreen}
                                      options={{title: "Unternehmen registrieren"}}/>
                        <Stack.Screen name="StoreCreate" component={StoreCreateScreen}
                                      options={{title: "Filiale hinzufügen"}}/>

                        <Stack.Screen name="Home" component={HomeScreen} initialParams={{store: store}}
                                      options={((props: {
                                          route: Route;
                                          navigation: any;
                                      }) => ({title: props.route.params?.store?.organizationName || "Kassensturz App"} as StackNavigationOptions))}/>

                        <Stack.Screen name="Floorplan" component={FloorplanScreen} options={{title: "Tischplan"}}/>
                        <Stack.Screen name="OpenTable" component={OpenTableScreen} options={((props: {
                            route: Route;
                            navigation: any;
                        }) => ({title: (props.route.params?.tableData as OpenTableInfo).tableName} as StackNavigationOptions))}/>
                        <Stack.Screen name="OrderCategories" component={OrderCategoriesScreen}
                                      options={{title: "Bestellung"}}/>
                        <Stack.Screen name="CustomOrderItem" component={CustomOrderItemScreen}
                                      options={{title: "Freie Buchung"}}/>

                        <Stack.Screen name="WaiterSelect" component={WaiterSelectScreen}
                                      options={{title: "Kellner wählen"}}/>

                        <Stack.Screen name="Payment" component={PaymentScreen} options={{title: "Bezahlen"}}/>
                        <Stack.Screen name="PaymentSelectPart" component={PaymentSelectPartScreen}
                                      options={{title: "Zahlungsumfang"}}/>

                        <Stack.Screen name="SelectCustomer" component={SelectCustomerScreen}
                                      options={{title: "Kunde wählen"}}/>

                        <Stack.Screen name="Delivery" component={DeliveryScreen} options={{title: "Lieferung"}}/>

                        <Stack.Screen name="WalkIn" component={WalkInScreen} options={{title: "Walk-In"}}/>

                        <Stack.Screen name="Reservations" component={ReservationsScreen}
                                      options={{title: "Reservierungen"}}/>
                        <Stack.Screen name="AddReservation" component={AddReservationScreen}
                                      options={{title: "Neue Reservierung"}}/>
                        <Stack.Screen name="ReservationDetails" component={ReservationDetailsScreen}
                                      options={{title: "Reservierung"}}/>

                        <Stack.Screen name="History" component={HistoryScreen} options={{title: "Historie"}}/>
                        <Stack.Screen name="HistoryTable" component={HistoryTableScreen} options={{title: "Historie"}}/>

                        <Stack.Screen name="Reports" component={ReportsScreen} options={{title: "Berichte"}}/>
                        <Stack.Screen name="ReportsList" component={ReportsListScreen} options={((props: {
                            route: Route;
                            navigation: any;
                        }) => ({title: props.route.params?.type !== undefined ? t('reportType_' + props.route.params?.type + '_list') : "Berichte"}))}/>
                        <Stack.Screen name="ReportDetail" component={ReportDetailScreen} options={((props: {
                            route: Route;
                            navigation: any;
                        }) => ({title: props.route.params?.reportInfo?.type !== undefined ? t('reportType_' + props.route.params?.reportInfo?.type) : "Bericht"}))}/>

                        <Stack.Screen name="Business" component={BusinessScreen} options={{title: "Verwaltung"}}/>
                        <Stack.Screen name="Employee" component={EmployeeScreen} options={{title: "Mitarbeiter"}}/>
                        <Stack.Screen name="EditEmployee" component={EditEmployeeScreen} options={((props: {
                            route: Route;
                            navigation: any;
                        }) => ({title: props.route.params?.target?.displayName || "Mitarbeiter bearbeiten"} as StackNavigationOptions))}/>
                        <Stack.Screen name="EditFloorplan" component={EditFloorplanScreen}
                                      options={{title: "Tischplan bearbeiten"}}/>
                        <Stack.Screen name="EditArticles" component={EditArticlesScreen}
                                      options={{title: "Artikel bearbeiten"}}/>
                        <Stack.Screen name="EditArticleCategory" component={EditArticleCategoryScreen}
                                      options={((props: {
                                          route: Route;
                                          navigation: any;
                                      }) => ({title: props.route.params?.category !== undefined ? "Kategorie bearbeiten" : "Kategorie hinzufügen"}))}/>
                        <Stack.Screen name="CreateArticle" component={ArticleCreateScreen} options={((props: {
                            route: Route;
                            navigation: any;
                        }) => ({title: props.route.params?.article !== undefined ? "Artikel bearbeiten" : "Artikel hinzufügen"}))}/>
                        <Stack.Screen name="CreateVoucher" component={CreateVoucherScreen} options={((props: {
                            route: Route;
                            navigation: any;
                        }) => ({title: props.route.params?.voucher !== undefined ? "Gutschein bearbeiten" : "Gutschein hinzufügen"}))}/>
                        <Stack.Screen name="EditLogo" component={EditLogoScreen} options={{title: "Logo"}}/>
                        <Stack.Screen name="Printers" component={PrintersScreen} options={{title: "Drucker"}}/>
                        <Stack.Screen name="EditPrinter" component={EditPrinterScreen}
                                      options={{title: "Drucker bearbeiten"}}/>
                        <Stack.Screen name="PairStoreHub" component={PairStoreHubScreen} options={{title: "StoreHub"}}/>
                        <Stack.Screen name="StoreHubDetails" component={StoreHubDetailsScreen} options={((props: {
                            route: Route;
                            navigation: any;
                        }) => ({title: props.route.params?.storeHubInfo?.displayName || "StoreHub bearbeiten"} as StackNavigationOptions))}/>
                        <Stack.Screen name="WagePayment" component={WagePaymentScreen}
                                      options={{title: "Lohnzahlung"}}/>
                        <Stack.Screen name="PaymentTypeExchange" component={PaymentTypeExchangeScreen}
                                      options={{title: "Zahlungsmitteltausch"}}/>
                        <Stack.Screen name="MoneyTransfer" component={MoneyTransferScreen}
                                      options={{title: "Geldtransit"}}/>
                        <Stack.Screen name="Capital" component={CapitalScreen}
                                      options={{title: "Privateinlage/-entnahme"}}/>
                        <Stack.Screen name="Diff" component={DiffScreen} options={{title: "Soll/Ist-Differenz"}}/>
                        <Stack.Screen name="OtherPayment" component={OtherPaymentScreen}
                                      options={{title: "Sonstiger Transfer"}}/>

                        <Stack.Screen name="Insights" component={InsightsScreen} options={{title: "Statistiken"}}/>

                        <Stack.Screen name="Export" component={ExportScreen} options={{title: "Export"}}/>

                        <Stack.Screen name="Settings" component={SettingsScreen} options={{title: 'Einstellungen'}}/>
                        <Stack.Screen name="StoreSelect" component={StoreSelectScreen}
                                      options={{title: 'Filiale wählen'}}/>
                        <Stack.Screen name="Account" component={AccountScreen} options={{title: 'Account'}}/>
                    </Stack.Navigator>
                </NavigationContainer>
            </AppContext.Provider>
        );
    }
}