import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {getAppContext} from "../../AppProvider";
import {Divider, ListItem} from "react-native-elements";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'
import * as Backend from "../../backend/Backend";
import {ScreenState} from "../../types/ScreenState";
import {ErrorView} from "../elements/ErrorView";
import {LoadingView} from "../elements/LoadingView";
import * as Alert from "../../alerts/alerts";
import {ArticleCategoryRequest} from "../../types/Article";
import {ViewStyle} from "react-native";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
// @ts-ignore
import ColorPalette from 'react-native-color-palette'
// @ts-ignore
import IconPicker from 'react-native-icon-picker';
import {IconName} from "@fortawesome/fontawesome-common-types";

export default function EditArticleCategoryScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'EditArticleCategory'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()

	const icons: IconName[] = [
			"folder",
			"cookie",
			"fish",
			"beer",
			"ice-cream",
			"pizza-slice",
			"cheese",
			"hamburger",
			"glass-cheers",
			"glass-whiskey",
			"wine-glass-alt",
			"glass-martini-alt",
			"drumstick-bite",
			"utensils",
			"utensil-spoon",
			"hotdog",
			"carrot",
			"bacon",
			"fire",
			"candy-cane",
			"coffee",
			"leaf",
			"mug-hot",
			"tag",
			"gift",
			"door-open",
			"shoe-prints",
			"map-signs",
			"comment-dots",
	]

	const [colorPalette] = React.useState([
		'#d73964',
		'#d23440',
		'#db643a',
		'#e88334',
		'#e2a71e',
		'#e25241',
		'#d0da59',
		'#4053ae',
		'#70b949',
		'#73564a',
		'#67ab5a',
		'#8f36aa',
		'#f6c244',
		'#52b9d0',
		'#4595ec',
		'#009688',
		'#5abeA7',
		'#59bccd',
		'#4a97e4',
		'#2d68cd',
		'#9946c7',
		'#d9639e',
		'#939287',
		'#868ea3',
		'#ffffff',
	]);

	const [state, setState] = React.useState<ScreenState & ArticleCategoryRequest & {showIconPicker: boolean}>({
		isLoading: false,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,

		name: route.params.category?.name || '',
		sort: route.params.category?.sort || 0,
		faIcon: route.params.category?.faIcon || 'folder',
		hexCode: route.params.category?.hexCode || '#ffffff',

		showIconPicker: false,
	})

	function replaceSpace(str: string | undefined) {
		return str?.replace(/\u0020/g, '\u00a0')
	}

	function escapeNumber(str: string): number {
		return parseInt(str.replace(/[^0-9]/g, ''))
	}

	function deleteArticleCategory() {
		if ('category' in route.params && route.params.category !== undefined) {
			Alert.confirm("Kategorie entfernen", "Möchtest du diese Kategorie löschen?", "Entfernen", "Abbrechen", () => {
				Backend.sendStoreData("category/" + route.params.category?.id, setState, appContext, "DELETE").then(() => {
					navigation.goBack()
				})
			}, undefined, 'destructive', 'cancel')
		}
	}

	function submit() {
		if (state.name.length < 3 || state.faIcon.length < 1 || state.hexCode.length < 3) {
			Alert.alert("Bitte fülle alle Felder vollständig aus!")
			return;
		}

		const category: ArticleCategoryRequest = {
			name: state.name,
			sort: state.sort,
			faIcon: state.faIcon,
			hexCode: state.hexCode,
		};

		if ('category' in route.params && route.params.category !== undefined) { // Editing existing article category
			Backend.sendStoreData("category/" + route.params.category.id, setState, appContext, "PATCH", category).then(() => {
				navigation.goBack()
			})
		} else { // Creating a new article category
			Backend.sendStoreData("category", setState, appContext, "PUT", category).then(() => {
				navigation.goBack()
			})
		}
	}

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else {
		return (
				<KeyboardAwareScrollView style={[styles.container]} enableResetScrollToCoords={false}>
					<ListItem key={"categoryName"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Name der Kategorie</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"Vorspeisen"}
															value={replaceSpace(state.name)}
															onChangeText={text => setState((s) => ({...s, name: text}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"sort"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Sortierung</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>Niedrigere Zahlen stehen weiter oben, es wird also aufsteigend sortiert</ListItem.Subtitle>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"1"} keyboardType={"number-pad"}
															value={isNaN(state.sort) ? '' : state.sort + ''}
															onChangeText={text => setState((s) => ({...s, sort: escapeNumber(text)}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"color"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Icon-Farbe</ListItem.Title>
							<ColorPalette
									onChange={(color: string) => setState((s) => ({...s, hexCode: color}))}
									value={state.hexCode}
									colors={colorPalette}
									title={""}
									icon={<FontAwesomeIcon icon={"check-circle"} size={24} color={'black'} />}
							/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"icon"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => setState((s) => ({...s, showIconPicker: true}))}>
						<ListItem.Content style={[styles.center]}>
							<IconPicker
									headerTitle={"Icon wählen"}
									style={[styles.background]}
									showIconPicker={state.showIconPicker}
									toggleIconPicker={() => setState((s) => ({...s, showIconPicker: !s.showIconPicker}))}
									iconDetails={[{ family: "FontAwesome5", icons: icons }]}
									onSelect={(icon: any) => setState((s) => ({...s, showIconPicker: false, faIcon: icon.icon}))}
									content={<FontAwesomeIcon icon={state.faIcon} style={[styles.text, {color: state.hexCode} as ViewStyle]} size={48} />}
							/>
						</ListItem.Content>
						<ListItem.Chevron/>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"submit"} topDivider bottomDivider containerStyle={[styles.background]} onPress={submit}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title, styles.center]}>Absenden</ListItem.Title>
						</ListItem.Content>
						<ListItem.Chevron/>
					</ListItem>
					<Divider style={[styles.divider]} />
					{
						route.params.category !== undefined && <>
							<ListItem key={"delete"} topDivider bottomDivider containerStyle={[styles.background]} onPress={deleteArticleCategory}>
								<ListItem.Content style={[styles.center]}>
									<ListItem.Title style={[styles.title, styles.center, styles.destructive]}>Löschen</ListItem.Title>
								</ListItem.Content>
								<ListItem.Chevron/>
							</ListItem>
							<Divider style={[styles.divider]} />
						</>
					}
				</KeyboardAwareScrollView>
		)
	}
}
