import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../../theme/style';
import {InfoView} from '../../elements/InfoView';
import * as Backend from '../../../backend/Backend';
import {ErrorView} from '../../elements/ErrorView';
import {LoadingView} from '../../elements/LoadingView';
import {RefreshControl, SafeAreaView, ScrollView, Text} from 'react-native';
import {Col, Grid, Row} from 'react-native-easy-grid';
import {getAppContext} from '../../../AppProvider';
import {Divider, ListItem} from 'react-native-elements';
import {ScreenState} from "../../../types/ScreenState";
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../../types/RootStackParamList";
import {LocalizationContext} from "../../../LocalizationContext";
import {SelectedItems} from "../../../types/SelectedItems";
import {OpenTable} from "../../../types/OpenTable";
import {OrderItem} from "../../../types/OrderItem";
import {Modifier} from "../../../types/PaymentRequest";

export default function PaymentSelectPartScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'PaymentSelectPart'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const { locale } = React.useContext(LocalizationContext);

	const [state, setState] = React.useState<ScreenState>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})

	const [paymentItems, setPaymentItems] = React.useState([] as boolean[])

	function togglePaymentItem(key: number) {
		setPaymentItems((items) => ({
			...items,
			[key]: !items[key]
		}))
	}

	function loadData() {
		Backend.loadStoreData("table/" + route?.params?.tableData.tableId + "/" + route?.params?.tableData.partyNumber, setState, appContext).then(() => {})
	}
	React.useEffect(loadData, [])

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen",
				"Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else if (state.response?.data == null) {
		return InfoView("Tisch noch nicht geöffnet",
				"Für diesen Tisch wurde noch keine Bestellung aufgenommen. Nutze den Button \"Bestellung\" um eine neue Bestellung aufzugeben und den Tisch damit zu öffnen.",
				"question-circle")
	} else {
		const tableData: OpenTable = state.response?.data
		let selectedPrice = 0.0
		let selectedCount = 0
		let selectedItems: SelectedItems = {};
		let indexOffset = 0;
		const flattenedItems: ((OrderItem & {signFactor: number}) | Modifier)[] = tableData.orderItems.flatMap((item: OrderItem) => {
			const filteredModifiers = item.modifiers.filter((m: Modifier) => !m.isRedeemed)
			const rtn = Array<(OrderItem & {signFactor: number}) | Modifier>(Math.abs(Math.round(item.quantity + filteredModifiers.length)))
			let isAnyItemSelected = false
			let isAllItemsSelected = true
			let itemSubtotal = 0
			for (let i = 0; i < Math.abs(item.quantity); i++) {
				const signFactor = item.quantity < 0 ? -1 : 1;
				rtn[i] = {...item, signFactor: signFactor};
				if (paymentItems[i + indexOffset]) {
					isAnyItemSelected = true
					selectedCount++
					selectedPrice += (item.unitPrice * signFactor)
					itemSubtotal += (item.unitPrice * signFactor)
					selectedItems[item.id] = (selectedItems[item.id] || 0) + signFactor
				} else {
					isAllItemsSelected = false
				}
			}
			for (let i = 0; i < filteredModifiers.length; i++) {
				let modifier = filteredModifiers[i]
				rtn[i + item.quantity] = modifier
				if (paymentItems[i + item.quantity + indexOffset] || isAllItemsSelected) {
					paymentItems[i + item.quantity + indexOffset] = true
					if (isAnyItemSelected) {
						selectedCount++
						if (modifier.id) {
							let mod = modifier.isAbsolute ? modifier.amount : ((modifier.amount / 100.0) * itemSubtotal)
							selectedPrice += mod
							itemSubtotal += mod
							selectedItems[modifier.id] = (selectedItems[modifier.id] || 0) + 1
						}
					} else {
						paymentItems[i + item.quantity + indexOffset] = false
					}
				}
			}
			indexOffset += rtn.length
			return rtn
		})

		function submit() {
			if (selectedCount > 0) {
				navigation.navigate('Payment', {tableData: tableData, selectedItems: selectedItems})
			}
		}

		return (
				<SafeAreaView style={[styles.container]}>
					<Grid>
						<Row size={10}>
							<ScrollView style={[styles.container]} refreshControl={<RefreshControl refreshing={state.refreshing} onRefresh={loadData} />}>
								{
									flattenedItems.map((item: (OrderItem & {signFactor: number}) | Modifier, i: number) => {
										if (paymentItems[i] == null)
											paymentItems[i] = false
										let modifier = item as Modifier
										let orderItem = item as (OrderItem & {signFactor: number})
										if (modifier.isAbsolute !== undefined) {
											return (
													<ListItem key={"modifier" + i} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => togglePaymentItem(i)}>
														<ListItem.Content>
															<ListItem.Title style={[styles.text, paymentItems[i] && styles.title]}>{modifier.description}</ListItem.Title>
														</ListItem.Content>
														<ListItem.Content right={true}>
															{
																modifier.isAbsolute ?
																		<ListItem.Subtitle style={[styles.subTitle]}>{modifier.amount.toLocaleString(locale, {style: 'currency', currency: 'EUR'})}</ListItem.Subtitle>
																		:
																		<ListItem.Subtitle style={[styles.subTitle]}>{modifier.amount.toLocaleString(locale, {style: 'decimal'})} %</ListItem.Subtitle>
															}
														</ListItem.Content>
														<ListItem.CheckBox checked={paymentItems[i]} onPress={() => togglePaymentItem(i)}/>
													</ListItem>
											)
										} else if (orderItem.unitPrice !== undefined) {
											return (
													<ListItem key={"item" + i} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => togglePaymentItem(i)}>
														<ListItem.Content>
															<ListItem.Title style={[styles.text, paymentItems[i] && styles.title]}>{orderItem.articleName}</ListItem.Title>
															{orderItem.specialWishes !== null && orderItem.specialWishes !== undefined && <ListItem.Subtitle style={[styles.text, styles.subTitle]}>Notiz: {orderItem.specialWishes}</ListItem.Subtitle>}
														</ListItem.Content>
														<ListItem.Content right={true}>
															<ListItem.Subtitle style={[styles.text, styles.subTitle]}>{(orderItem.unitPrice*orderItem.signFactor).toLocaleString(locale, { style: 'currency', currency: 'EUR' })}</ListItem.Subtitle>
														</ListItem.Content>
														<ListItem.CheckBox checked={paymentItems[i]} onPress={() => togglePaymentItem(i)}/>
													</ListItem>
											)
										} else {
											console.log(item)
										}
									})
								}
								<Divider style={[styles.divider]} />
							</ScrollView>
						</Row>
						<Row size={1}>
							<Col size={1} style={[styles.center, selectedPrice === 0 ? styles.backgroundHighlight : styles.backgroundPrimary]} onPress={submit}>
								<Text style={[styles.title, { fontSize: 20}]}>{selectedPrice.toLocaleString(locale, { style: 'currency', currency: 'EUR' })} zahlen ({selectedCount} Artikel)</Text>
							</Col>
						</Row>
					</Grid>
				</SafeAreaView>
		)
	}
}
