export function alert(title = "", desc = "", onPress = () => {}) {
	window.alert(`${title}\n${desc}`)
	if (onPress) onPress()
}

export function confirm(title = "", desc = "", positiveLabel: string, negativeLabel: string, onPositivePress = () => {}, onNegativePress = () => {}, positiveStyle?: 'default' | 'cancel' | 'destructive', negativeStyle?: 'default' | 'cancel' | 'destructive') {
	const res = window.confirm(`${title}\n${desc}`)
	if (res) onPositivePress()
	else onNegativePress()
}
