import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {getAppContext} from "../../AppProvider";
import {Divider, ListItem} from "react-native-elements";
import {BusinessRequest} from "../../types/OrganizationRequest";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'
import * as Backend from "../../backend/Backend";
import {ScreenState} from "../../types/ScreenState";
import {ErrorView} from "../elements/ErrorView";
import {LoadingView} from "../elements/LoadingView";
import * as Alert from "../../alerts/alerts";
import * as Storage from "../../storage/storage";
import {CommonActions} from "@react-navigation/native";
import {setSentryStoreContext} from '../../util/SentryWrapper';

export default function BusinessCreateScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'BusinessCreate'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()

	const [state, setState] = React.useState<ScreenState>({
		isLoading: false,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})

	const [createStore, setCreateStore] = React.useState<boolean>(true)
	const [business, setBusiness] = React.useState<BusinessRequest>( {
		address: {
			city: "",
			country: "Deutschland",
			postalCode: "",
			state: "",
			street: "",
			streetNr: ""
		},
		config:  {
			headerLine: "",
			footerLine: "",
		},
		organizationName: "",
		taxId: "",
		telephone: "",
		createIdenticalStore: true,
		registrationCode: route.params.registrationCode,
	})

	function submit() {
		if (business.organizationName.length < 3 || business.taxId.length < 3 || business.telephone.length < 3 ||
				business.address.street.length < 3 || business.address.streetNr.length < 1 || business.address.postalCode.length !== 5 ||
				business.address.city.length < 2) {
			Alert.alert("Bitte fülle alle Felder vollständig aus!")
			return;
		}

		business.createIdenticalStore = createStore
		Backend.request("business", setState, appContext, "PUT", business).then((response) => {
			if (response.data.store) {
				Storage.setItem("storeId", response.data.store.id).then(() => {
					setSentryStoreContext(response.data.store.id)
					appContext.storeId = response.data.store.id
					navigation.dispatch(
							CommonActions.reset({
								index: 0,
								routes: [
									{name: 'Home', params: {user: state.response?.data, store: response.data.store}},
								],
							})
					);
				});
			} else {
				navigation.dispatch(
						CommonActions.reset({
							index: 1,
							routes: [
								{ name: 'StoreSelect' },
								{ name: 'StoreCreate', params: {businessId: response.data.business.id, registrationCode: response.data.storeRegistrationCode}},
							],
						})
				);
			}
		})
	}

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else {
		function replaceSpace(str: string | undefined) {
			return str?.replace(/\u0020/g, '\u00a0')
		}

		function escapeTaxId(str: string) {
			return str.toUpperCase().replace(/\s/g, '').replace(/[^A-Z0-9/]/g, '')
		}

		function onlyNumbers(str: string) {
			return str.replace(/[^0-9]/g, '')
		}

		return (
				<KeyboardAwareScrollView style={[styles.container]} enableResetScrollToCoords={false}>
					<ListItem key={"code"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title, styles.center]}>Registierungs-Code</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle, styles.center]}>{route.params.registrationCode}</ListItem.Subtitle>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"organizationNameTitle"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Firmenname</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"Name des Unternehmens"}
															value={replaceSpace(business.organizationName)}
															onChangeText={text => setBusiness((s) => ({...s, organizationName: text}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"taxId"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Umsatzsteuer-ID</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>alternativ Steuernummer</ListItem.Subtitle>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"DE123456789"}
															autoCapitalize={"characters"}
															value={business.taxId}
															onChangeText={text => setBusiness((s) => ({...s, taxId: escapeTaxId(text)}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"telephone"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Telefonnnummer</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"089123456789"} keyboardType={"phone-pad"}
															onChangeText={text => setBusiness((s) => ({...s, telephone: text}))}/>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"addressHeader"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title, styles.center]}>Adresse</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle, styles.center]}>Geschäftsanschrift des Unternehmens</ListItem.Subtitle>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"addressStreet"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Straße</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"Musterstraße"} keyboardType={"default"}
															value={replaceSpace(business.address.street)}
															onChangeText={text => setBusiness((s) => ({...s, address: {...s.address, street: text}}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"addressStreetNr"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Hausnummer</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"1b"} keyboardType={"default"}
															value={replaceSpace(business.address.streetNr)}
															onChangeText={text => setBusiness((s) => ({...s, address: {...s.address, streetNr: text}}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"addressPostalCode"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Postleitzahl</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"12345"} keyboardType={"number-pad"}
															onChangeText={text => setBusiness((s) => ({...s, address: {...s.address, postalCode: onlyNumbers(text)}}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"addressCity"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Stadt</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"Musterstadt"} keyboardType={"default"}
															value={replaceSpace(business.address.city)}
															onChangeText={text => setBusiness((s) => ({...s, address: {...s.address, city: text}}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"addressState"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Bundesland</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"Musterstaat"} keyboardType={"default"}
															value={replaceSpace(business.address.state)}
															onChangeText={text => setBusiness((s) => ({...s, address: {...s.address, state: text}}))}/>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"createIdenticalStore"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => setCreateStore(!createStore)}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Filiale</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>Filiale mit gleichen Eigenschaften anlegen?</ListItem.Subtitle>
						</ListItem.Content>
						<ListItem.CheckBox checked={createStore}/>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"submit"} topDivider bottomDivider containerStyle={[styles.background]} onPress={submit}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title, styles.center]}>Absenden</ListItem.Title>
						</ListItem.Content>
						<ListItem.Chevron/>
					</ListItem>
					<Divider style={[styles.divider]} />
				</KeyboardAwareScrollView>
		)
	}
}
