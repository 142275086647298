import 'react-native-gesture-handler';
import * as React from 'react';
import {ActivityIndicator, RefreshControl, ScrollView} from 'react-native';
import {Divider, ListItem} from 'react-native-elements';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import * as Alert from '../alerts/alerts';
import * as Storage from '../storage/storage';
import * as Style from '../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../types/RootStackParamList";
import {CommonActions} from "@react-navigation/native";
import * as Updates from 'expo-updates';
import {Manifest} from 'expo-updates';
import Constants from "expo-constants";
import {getAppContext} from '../AppProvider';
import {setSentryStoreContext, setSentryUser} from '../util/SentryWrapper';

export default function SettingsScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'Settings'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()

	function logout() {
		Alert.confirm("Abmelden?", "Möchtest du dich wirklich abmelden?", "Abmelden", "Abbrechen", () => {
			Storage.deleteItem("authorization").then(() => {
				setSentryUser(null)
				Storage.deleteItem("storeId").then(() => {
					setSentryStoreContext(null)
					navigation.dispatch(
							CommonActions.reset({
								index: 0,
								routes: [
									{ name: 'Login' },
								],
							})
					);
				})
			})
		}, undefined, 'destructive', 'cancel')
	}

	function showVersionInfo() {
		let deviceInfo =
				"Device Name: " + Constants.deviceName + "\n" +
				"Device Year Class: " + Constants.deviceYearClass + "\n"
		;

		const iOS = Constants.platform?.ios;
		const android = Constants.platform?.android;
		if (iOS !== undefined) {
			deviceInfo += "iOS Version: " + iOS.systemVersion + "\n"
			deviceInfo += "Apple Model: " + iOS.model + "\n"
			deviceInfo += "Apple Model Id: " + iOS.platform + "\n"
			deviceInfo += "Apple Model Type: " + iOS.userInterfaceIdiom + "\n"
		}
		if (android !== undefined) {
			deviceInfo += "Android Version-Code: " + android.versionCode + "\n"
		}

		Alert.alert("System-Informationen", "" +
				"App Version: " + Constants.expoConfig?.version + "\n" +
				"Native App Version: " + Constants.nativeAppVersion + "\n" +
				"\n" +
				"Stage: " + (Constants.expoConfig?.extra?.stage ?? 'Unknown') + "\n" +
				"\n" +
				"Host-Type: " + Constants.appOwnership + "\n" +
				"Expo Version: " + Constants.expoVersion + "\n" +
				"System Version: " + Constants.systemVersion + "\n" +
				"\n" +
				deviceInfo +
				"");
	}

	const [showWarnings, setShowWarnings] = React.useState<boolean>(true);
	const [overrideColorScheme, setOverrideColorScheme] = React.useState<string | null>(null);
	const [refreshIcon, setRefreshIcon] = React.useState<boolean>(false);
	const [availableUpdate, setAvailableUpdate] = React.useState<Manifest|undefined|null>(undefined);
	const [downloadingUpdate, setDownloadingUpdate] = React.useState<boolean>(false);
	const [updateDownloaded, setUpdateDownloaded] = React.useState<boolean>(false);

	Storage.getItem("overrideColorScheme").then(storedValue => {
		setOverrideColorScheme(storedValue)
	})

	Storage.getItem("showWarnings").then(storedValue => {
		setShowWarnings(storedValue !== "false");
	})

	function toggleShowWarnings() {
		const newValue = !showWarnings
		Storage.setItem("showWarnings", newValue + '').then(() => {
			setShowWarnings(newValue)
		})
	}

	function checkForUpdate() {
		console.log("Checking for update...");
		setRefreshIcon(true);
		setAvailableUpdate(undefined);
		Updates.checkForUpdateAsync().then((r: Updates.UpdateCheckResult) => {
			if (r.isAvailable) {
				setAvailableUpdate(r.manifest);
			} else {
				setAvailableUpdate(null);
			}
			setRefreshIcon(false);
		}).catch((r) => {
			setRefreshIcon(false);
			console.log("Update-Prüfung fehlgeschlagen!");
		});
	}

	function downloadUpdate() {
		setDownloadingUpdate(true);
		Updates.fetchUpdateAsync().then((r: Updates.UpdateFetchResult) => {
			if (r.isNew) {
				reloadApp("Update heruntergeladen");
				setUpdateDownloaded(true);
				setDownloadingUpdate(false);
			} else {
				Alert.alert("App up-to-date", "Deine App ist bereits auf dem neusten Stand");
			}
		}).catch((r) => {
			Alert.alert("Update fehlgeschlagen", "Es konnte kein neues Update heruntergeladen werden!");
		});
	}

	function reloadApp(title: string = "App neustarten") {
		Alert.confirm(title, "Möchtest du die App jetzt neu starten?", "Neustart", "Abbrechen", () => {
			Updates.reloadAsync().then(r => {})
		}, undefined, "destructive", "cancel");
	}

	// initial load
	React.useEffect(() => {
		// 1ms Timeout as workaround for it to work on Web
		const timeoutId = setTimeout(() => checkForUpdate(), 1);
		return () => clearTimeout(timeoutId);
	}, []);

	return (
			<ScrollView style={[styles.container]} refreshControl={<RefreshControl onRefresh={checkForUpdate} refreshing={refreshIcon} />}>
				<ListItem key={"storeselect"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('StoreSelect')}>
					<FontAwesomeIcon icon="store" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>Filiale wechseln</ListItem.Title>
						<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Aktuelle Filiale ändern</ListItem.Subtitle>
					</ListItem.Content>
					<ListItem.Chevron />
				</ListItem>
				<Divider style={[styles.divider]} />
				<ListItem key={"account"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('Account')}>
					<FontAwesomeIcon icon="user" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>Account</ListItem.Title>
						<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Accountdaten anzeigen und bearbeiten</ListItem.Subtitle>
					</ListItem.Content>
					<ListItem.Chevron />
				</ListItem>
				<ListItem key={"logout"} topDivider bottomDivider containerStyle={[styles.background]} onPress={logout}>
					<FontAwesomeIcon icon="sign-out-alt" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>Abmelden</ListItem.Title>
						<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Von diesem Gerät abmelden</ListItem.Subtitle>
					</ListItem.Content>
					<ListItem.Chevron />
				</ListItem>
				<Divider style={[styles.divider]} />
				<ListItem key={"showWarnings"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => toggleShowWarnings()}>
					<ListItem.Content style={[]}>
						<ListItem.Title style={[styles.title]}>Server-Warnungen anzeigen</ListItem.Title>
						<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Kritische Fehler werden weiterhin angezeigt</ListItem.Subtitle>
					</ListItem.Content>
					<ListItem.CheckBox checked={showWarnings} onPress={() => toggleShowWarnings()}/>
				</ListItem>
				<Divider style={[styles.divider]} />
				<ListItem key={"forceColorScheme"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => appContext.openDialog({
					title: 'Farbschema',
					message: 'Setze ein Farbschema fest oder nutze das vom System definierte Farbschema',
					middleButton_visible: true,
					middleButton_label: 'Hell',
					middleButton_bold: true,
					positiveButton_label: 'Dunkel',
					negativeButton_visible: true,
					negativeButton_label: 'System',
					positiveButton_onPress() {
						Storage.setItem("overrideColorScheme", "dark").then(() => {
							setOverrideColorScheme("dark")
							Updates.reloadAsync().then(r => {})
						});
					},
					middleButton_onPress() {
						Storage.setItem("overrideColorScheme", "light").then(() => {
							setOverrideColorScheme("light")
							Updates.reloadAsync().then(r => {})
						});
					},
					negativeButton_onPress() {
						Storage.deleteItem("overrideColorScheme").then(() => {
							setOverrideColorScheme(null)
							Updates.reloadAsync().then(r => {})
						});
					}
				})}>
					<ListItem.Content style={[]}>
						<ListItem.Title style={[styles.title]}>Farbschema</ListItem.Title>
					</ListItem.Content>
					<ListItem.Content right={true} style={[]}>
						<ListItem.Title style={[styles.title]}>
							{overrideColorScheme === null && 'System'}
							{overrideColorScheme === "light" && 'Hell'}
							{overrideColorScheme === "dark" && 'Dunkel'}
						</ListItem.Title>
					</ListItem.Content>
					<ListItem.Chevron/>
				</ListItem>
				<Divider style={[styles.divider]} />
				<ListItem key={"version"} topDivider bottomDivider containerStyle={[styles.background]} onPress={showVersionInfo}>
					<FontAwesomeIcon icon="info-circle" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>App-Version</ListItem.Title>
						{
							availableUpdate === null &&
							<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Up-to-date</ListItem.Subtitle>
						}
					</ListItem.Content>
					<ListItem.Content right={true}>
						<ListItem.Subtitle style={[styles.text, styles.subTitle]}>{Constants.expoConfig?.version || 'n/a'}</ListItem.Subtitle>
					</ListItem.Content>
				</ListItem>
				{
					availableUpdate !== undefined && availableUpdate !== null && !downloadingUpdate && !updateDownloaded &&
					<ListItem key={"update"} topDivider bottomDivider containerStyle={[styles.background]} onPress={downloadUpdate}>
						<FontAwesomeIcon icon="download" size={32} style={[styles.text]}/>
						<ListItem.Content>
							<ListItem.Title style={[styles.text, styles.title]}>Update verfügbar!</ListItem.Title>
							<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Channel: {Updates.releaseChannel}</ListItem.Subtitle>
						</ListItem.Content>
						<ListItem.Content right={true}>
							<ListItem.Title style={[styles.text, styles.title, styles.textRight]}>{availableUpdate.version}</ListItem.Title>
						</ListItem.Content>
						<ListItem.Chevron />
					</ListItem>
				}
				{
					availableUpdate !== undefined && availableUpdate !== null && downloadingUpdate && !updateDownloaded &&
					<ListItem key={"updateLoading"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ActivityIndicator size={"large"}/>
						<ListItem.Content>
							<ListItem.Title style={[styles.text, styles.title]}>Downloading Update...</ListItem.Title>
						</ListItem.Content>
						<ListItem.Content right={true}>
							<ListItem.Title style={[styles.text, styles.title, styles.textRight]}>{availableUpdate.version}</ListItem.Title>
							<ListItem.Subtitle style={[styles.text, styles.subTitle, styles.textRight]}>{Updates.releaseChannel}</ListItem.Subtitle>
						</ListItem.Content>
					</ListItem>
				}
				{
					availableUpdate !== undefined && availableUpdate !== null && updateDownloaded &&
					<ListItem key={"updateDownloaded"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => reloadApp()}>
						<FontAwesomeIcon icon="check" size={32} style={[styles.text]}/>
						<ListItem.Content>
							<ListItem.Title style={[styles.text, styles.title]}>Downloaded Update!</ListItem.Title>
						</ListItem.Content>
						<ListItem.Content right={true}>
							<ListItem.Title style={[styles.text, styles.title, styles.textRight]}>{availableUpdate.version}</ListItem.Title>
							<ListItem.Subtitle style={[styles.text, styles.subTitle, styles.textRight]}>{Updates.releaseChannel}</ListItem.Subtitle>
						</ListItem.Content>
					</ListItem>
				}
				<Divider style={[styles.divider]} />
				<ListItem key={"reload"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => reloadApp()}>
					<FontAwesomeIcon icon="power-off" size={32} style={[styles.text]}/>
					<ListItem.Content>
						<ListItem.Title style={[styles.text, styles.title]}>Neustarten</ListItem.Title>
						<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Die App neu laden</ListItem.Subtitle>
					</ListItem.Content>
					<ListItem.Chevron />
				</ListItem>
				<Divider style={[styles.divider]} />
			</ScrollView>
	);
}
