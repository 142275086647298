import * as React from 'react';
import {useColorScheme} from 'react-native';
import {darkColors, lightColors, ThemeColors} from './colorThemes';
import * as Storage from '../storage/storage';

export type ThemeContextType = {
	isDark: boolean,
	colors: ThemeColors,
	setScheme: (scheme: Scheme) => void,
}

export type Scheme = 'dark' | 'light';


export const ThemeContext = React.createContext<ThemeContextType>({
	isDark: false,
	colors: lightColors,
	setScheme: () => {},
});

export const ThemeProvider = (props: React.PropsWithChildren<any>) => {
	// Getting the device color theme, this will also work with react-native-web
	const colorScheme = useColorScheme(); // Can be dark | light | no-preference
	console.log('useColorScheme -> ' + colorScheme);
	/*
	* To enable changing the app theme dynamicly in the app (run-time)
	* we're gonna use useState so we can override the default device theme
	*/
	const [isDark, setIsDark] = React.useState(colorScheme === "dark");

	// Listening to changes of device appearance while in run-time
	React.useEffect(() => {
		Storage.getItem("overrideColorScheme").then(overrideColorScheme => {
			if (!overrideColorScheme) {
				setIsDark(colorScheme === "dark");
			}
		})
	}, [colorScheme]);

	Storage.getItem("overrideColorScheme").then(overrideColorScheme => {
		if (overrideColorScheme) {
			setIsDark(overrideColorScheme === "dark")
		}
	})

	const defaultTheme = {
		isDark: isDark,
		// Chaning color schemes according to theme
		colors: isDark ? darkColors : lightColors,
		// Overrides the isDark value will cause re-render inside the context.
		setScheme: (scheme: Scheme) => setIsDark(scheme === "dark"),
	};

	return (
			<ThemeContext.Provider value={defaultTheme}>
				{props.children}
			</ThemeContext.Provider>
	);
};

// Custom hook to get the theme object returns {isDark, colors, setScheme}
export const useTheme = () => React.useContext(ThemeContext);
