import * as React from 'react';
import {ScaledSize} from "react-native";
import {DialogState} from "./types/DialogState";
import {UserInfo} from "./types/UserInfo";
import {KassensturzEnvironment, ProdEnvironment} from "./KassensturzEnvironment";

export type AppContextType = {
	forceReloadApp: () => void,
	openDialog: (dialog: Partial<DialogState>) => void,
	deviceDimensions: ScaledSize,
	minDimension: number,
	storeId: string|null,
	user?: UserInfo,
	environment: KassensturzEnvironment,
}

export const AppContext = React.createContext<AppContextType>({
	forceReloadApp: () => {},
	openDialog: () => {},
	deviceDimensions: {fontScale: 0, height: 0, scale: 1, width: 0},
	minDimension: 0,
	storeId: null,
	environment: ProdEnvironment,
});

// Custom hook to get the theme object returns {isDark, colors, setScheme}
export const getAppContext = () => React.useContext(AppContext);
