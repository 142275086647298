import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {getAppContext} from "../../AppProvider";
import {ScreenState} from "../../types/ScreenState";
import * as Backend from "../../backend/Backend";
import {ErrorView} from "../elements/ErrorView";
import {LoadingView} from "../elements/LoadingView";
import {RefreshControl, ScrollView} from "react-native";
import {Divider, ListItem} from "react-native-elements";
import * as Alert from "../../alerts/alerts";
import * as DocumentPicker from 'expo-document-picker';
import * as FileSystem from 'expo-file-system';
import {StorePrinter} from "../../types/StorePrinter";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {PrinterTypeEnum} from "../../types/PrinterTypeEnum";

export default function EditPrinterScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'EditPrinter'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const [state, setState] = React.useState<ScreenState>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})
	const printerId = route.params.printerId
	function loadData() {
		Backend.loadStoreData("printers/" + printerId, setState, appContext).then(() => {})
	}
	React.useEffect(() => {
		return navigation.addListener('focus', loadData);
	}, [navigation]);

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen", "Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else {
		const printer: StorePrinter = state.response.data
		return (
			<ScrollView style={[styles.container]} refreshControl={<RefreshControl onRefresh={loadData} refreshing={state.refreshing} />}>
				<ListItem key={"infoHeader"} topDivider bottomDivider containerStyle={[styles.background]}>
					<ListItem.Content style={[styles.center]}>
						<ListItem.Title style={[styles.title, styles.center]}>Allgemeine Informationen</ListItem.Title>
					</ListItem.Content>
				</ListItem>
				<ListItem key={"name"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => appContext.openDialog({
					title: "Anzeigename ändern",
					message: "Bitte gib den neuen Namen an",
					input_visible: true,
					input_keyboardType: 'default',
					input_placeholder: 'Name (nur für Mitarbeiter sichtbar)',
					positiveButton_label: "Speichern",
					positiveButton_onPress(inputValue?: string, pickerValue?: string) {
						if (inputValue) {
							Backend.sendStoreData("printers", setState, appContext, "PUT", {
								...printer,
								displayName: inputValue
							}, false).then(() => loadData())
						}
					},
					negativeButton_visible: true,
					negativeButton_label: "Abbrechen",
				})}>
					<FontAwesomeIcon icon="pencil" size={32} style={[styles.text]}/>
					<ListItem.Content style={[]}>
						<ListItem.Title style={[styles.text, styles.title]}>Name</ListItem.Title>
					</ListItem.Content>
					<ListItem.Content style={[{flex: 1}]} right={true}>
						<ListItem.Subtitle style={[styles.text, styles.textRight]}>{printer.displayName}</ListItem.Subtitle>
					</ListItem.Content>
				</ListItem>
				<ListItem key={"ip"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => appContext.openDialog({
					title: "Hostname ändern",
					message: "Bitte gib den neuen Hostnamen an. Bitte nutze dafür das Format 'Hostname:Port' (Standard-Port: 9100).",
					input_visible: true,
					input_keyboardType: 'default',
					input_placeholder: "192.168.0.99:9100",
					input_defaultValue: printer.hostAddress + ":" + printer.port,
					positiveButton_label: "Speichern",
					positiveButton_onPress(inputValue?: string, pickerValue?: string) {
						if (inputValue) {
							let hostAddress: string = inputValue;
							let port: number = 9100;

							if (inputValue.includes(':')) {
								const parts = inputValue.split(':')
								hostAddress = parts[0]
								port = parseInt(parts[1])
							}

							Backend.sendStoreData("printers", setState, appContext, "PUT", {
								...printer,
								hostAddress: hostAddress,
								port: port
							}, false).then(() => loadData())
						}
					},
					negativeButton_visible: true,
					negativeButton_label: "Abbrechen",
				})}>
					<FontAwesomeIcon icon="network-wired" size={32} style={[styles.text]}/>
					<ListItem.Content style={[]}>
						<ListItem.Title style={[styles.text, styles.title]}>Hostname / IP</ListItem.Title>
					</ListItem.Content>
					<ListItem.Content style={[{flex: 1}]} right={true}>
						<ListItem.Subtitle style={[styles.text, styles.textRight]}>{printer.hostAddress}:{printer.port}</ListItem.Subtitle>
					</ListItem.Content>
				</ListItem>
				<ListItem key={"type"} topDivider bottomDivider containerStyle={[styles.background]}>
					<FontAwesomeIcon icon="receipt" size={32} style={[styles.text]}/>
					<ListItem.Content style={[]}>
						<ListItem.Title style={[styles.text, styles.title]}>Typ</ListItem.Title>
					</ListItem.Content>
					<ListItem.Content style={[{flex: 1}]} right={true}>
						<ListItem.Subtitle style={[styles.text, styles.textRight]}>{printer.printerType}</ListItem.Subtitle>
					</ListItem.Content>
				</ListItem>
				<Divider style={[styles.divider]} />
				<ListItem key={"test"} topDivider bottomDivider
						  containerStyle={[styles.background]} onPress={() => {
						Backend.sendStoreData("printers/" + printer.id + "/test", setState, appContext, "PUT", {}, false).then(() => {})
				}}>
					<ListItem.Content style={[]}>
						<ListItem.Title style={[styles.text, styles.title]}>Drucker testen</ListItem.Title>
					</ListItem.Content>
					<ListItem.Chevron/>
				</ListItem>
				<Divider style={[styles.divider]} />
				<ListItem key={"codePageHeader"} topDivider bottomDivider containerStyle={[styles.background]}>
					<ListItem.Content style={[styles.center]}>
						<ListItem.Title style={[styles.title, styles.center]}>Code-Page</ListItem.Title>
					</ListItem.Content>
				</ListItem>
				<ListItem key={"codepage"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => appContext.openDialog({
					title: "Code-Page setzen",
					message: "Bitte gib die neue Code-Page an (Zahl).",
					input_visible: true,
					input_keyboardType: 'number-pad',
					input_defaultValue: printer.codePage + '',
					positiveButton_label: "Speichern",
					positiveButton_onPress(inputValue?: string, pickerValue?: string) {
						if (inputValue) {
							let newCodePage: number = parseInt(inputValue)

							Backend.sendStoreData("printers", setState, appContext, "PUT", {
								...printer,
								codePage: newCodePage
							}, false).then(() => loadData())
						}
					},
					negativeButton_visible: true,
					negativeButton_label: "Abbrechen",
				})}>
					<FontAwesomeIcon icon="language" size={32} style={[styles.text]}/>
					<ListItem.Content style={[]}>
						<ListItem.Title style={[styles.text, styles.title]}>Aktive Code-Page</ListItem.Title>
						{
							printer.codePage != 19 &&
							<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Empfehlung: 19</ListItem.Subtitle>
						}
						{
							printer.codePage == 19 &&
							<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Empfehlung aktiv</ListItem.Subtitle>
						}
					</ListItem.Content>
					<ListItem.Content style={[]} right={true}>
						<ListItem.Subtitle style={[styles.text, styles.textRight]}>{printer.codePage}</ListItem.Subtitle>
					</ListItem.Content>
					<ListItem.Chevron/>
				</ListItem>
				<ListItem key={"testCodePages"} topDivider bottomDivider
						  containerStyle={[styles.background]} onPress={() => {
					Alert.confirm("Code-Page Test-Druck", "Möchtest du den Test wirklich drucken?", "Ja, drucken", "Abbrechen", () => {
						Backend.sendStoreData("printers/" + printer.id + "/test-codepages", setState, appContext, "PUT", {}, false).then(() => {})
					}, undefined, 'default', 'cancel')
				}}>
					<ListItem.Content style={[]}>
						<ListItem.Title style={[styles.text, styles.title]}>Code-Page Test-Druck starten</ListItem.Title>
						<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Ein Bon mit verschiedenen Code-Pages wird gedruckt. Dadurch kann die für den Drucker am besten geeignete Code-Page ausgewählt werden.</ListItem.Subtitle>
					</ListItem.Content>
					<ListItem.Chevron/>
				</ListItem>
				<Divider style={[styles.divider]} />
				<ListItem key={"delete"} topDivider bottomDivider
						  containerStyle={[styles.background]} onPress={() => {
					Alert.confirm("Drucker entfernen", "Möchtest du diesen Drucker löschen?", "Entfernen", "Abbrechen", () => {
						Backend.sendStoreData("printers/" + printer.printerType, setState, appContext, "DELETE").then(() => {
							navigation.goBack()
						})
					}, undefined, 'destructive', 'cancel')
				}}>
					<ListItem.Content style={[]}>
						<ListItem.Title style={[styles.text, styles.title]}>Drucker löschen</ListItem.Title>
					</ListItem.Content>
					<ListItem.Chevron/>
				</ListItem>
				<Divider style={[styles.divider]} />
			</ScrollView>
		);
	}
}
