import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../types/RootStackParamList";
import {getAppContext} from "../AppProvider";
import {ScreenState} from "../types/ScreenState";
import * as Backend from "../backend/Backend";
import {ErrorView} from "./elements/ErrorView";
import {LoadingView} from "./elements/LoadingView";
import {OpenTable} from "../types/OpenTable";
import {RefreshControl, ScrollView} from "react-native";
import {Divider, ListItem} from "react-native-elements";
import moment from "moment";
import {LocalizationContext} from "../LocalizationContext";
import {zdtFormat} from "../backend/Backend";

export default function HistoryScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'History'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const { locale } = React.useContext(LocalizationContext);

	const [state, setState] = React.useState<ScreenState>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})
	function loadData() {
		Backend.loadStoreData("tables/history", setState, appContext).then(() => {})
	}
	React.useEffect(loadData, [])
	React.useEffect(() => {
		return navigation.addListener('focus', loadData);
	}, [navigation]);

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen", "Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else {
		const historyTables: OpenTable[] = state.response.data
		return (
				<ScrollView style={[styles.container]} refreshControl={<RefreshControl onRefresh={loadData} refreshing={state.refreshing} />}>
					{
						historyTables.map((openTable: OpenTable, i) => {
									return (
											<ListItem key={"table" + i} topDivider bottomDivider
																containerStyle={[styles.background]}
																onPress={() => navigation.navigate('HistoryTable', {id: openTable.id})}>
												<ListItem.Content style={[]}>
													<ListItem.Title style={[styles.text, styles.title]}>{openTable.tableName}</ListItem.Title>
													<ListItem.Subtitle key={"subtitle" + i} style={[styles.text, styles.subTitle]}>
														{moment(openTable.startTime, zdtFormat).format("LT")} - {moment(openTable.endTime, zdtFormat).format("LT")}
													</ListItem.Subtitle>
												</ListItem.Content>
												<ListItem.Content style={[]} right={true}>
													<ListItem.Title style={[styles.text, styles.title, styles.textRight]}>{openTable.totalPaid.toLocaleString(locale, {style: 'currency', currency: 'EUR'})}</ListItem.Title>
												</ListItem.Content>
												<ListItem.Chevron/>
											</ListItem>
									)
								}
						)
					}
					<Divider style={[styles.divider]} />
				</ScrollView>
		);
	}
}
