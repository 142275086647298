import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {getAppContext} from "../../AppProvider";
import {ScreenState} from "../../types/ScreenState";
import * as Alert from "../../alerts/alerts";
import * as Backend from "../../backend/Backend";
import {ErrorView} from "../elements/ErrorView";
import {LoadingView} from "../elements/LoadingView";
import {KeyboardAwareScrollView} from "react-native-keyboard-aware-scroll-view";
import {ButtonGroup, Divider, ListItem} from "react-native-elements";
import {PaymentTypeExchangeRequest} from "../../types/PaymentTypeExchangeRequest";
import {PaymentTypeEnum} from "../../types/ESR";
import {LocalizationContext} from "../../LocalizationContext";

export default function PaymentTypeExchangeScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'PaymentTypeExchange'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const { t } = React.useContext(LocalizationContext);

	const [paymentTypeIn, setPaymentTypeIn] = React.useState<number>(1)
	const [paymentTypeOut, setPaymentTypeOut] = React.useState<number>(0)
	const [state, setState] = React.useState<ScreenState & {amount: number}>({
		isLoading: false,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,

		amount: -1,
	})

	function submit() {
		if (state.amount == -1) {
			Alert.alert("Bitte fülle alle Felder vollständig aus!")
			return;
		}

		const request: PaymentTypeExchangeRequest = {
			amount: state.amount,
			paymentTypeIn: PaymentTypeEnum.CASH,
			paymentTypeOut: PaymentTypeEnum.CASH,
		}

		if (paymentTypeIn == 0) {
			request.paymentTypeIn = PaymentTypeEnum.CASH
		} else if (paymentTypeIn == 1) {
			request.paymentTypeIn = PaymentTypeEnum.DEBIT
		} else if (paymentTypeIn == 2) {
			request.paymentTypeIn = PaymentTypeEnum.CREDIT
		}

		if (paymentTypeOut == 0) {
			request.paymentTypeOut = PaymentTypeEnum.CASH
		} else if (paymentTypeOut == 1) {
			request.paymentTypeOut = PaymentTypeEnum.DEBIT
		} else if (paymentTypeOut == 2) {
			request.paymentTypeOut = PaymentTypeEnum.CREDIT
		}

		if (request.paymentTypeIn == request.paymentTypeOut) {
			Alert.alert("Ungültige Anfrage", "Für einen Zahlungsmitteltausch müssen zwei unterschiedliche Zahlungsmittel ausgewählt werden!")
			return
		}

		Backend.sendStoreData("special/transaction/exchange", setState, appContext, "PUT", request).then(() => {
			navigation.goBack()
		})
	}

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else {
		return (
				<KeyboardAwareScrollView style={[styles.container]} enableResetScrollToCoords={false}>
					<ListItem key={"amount"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Wert in Euro</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"630,00"} keyboardType={"decimal-pad"}
															onChangeText={text => setState((s) => ({...s, amount: Number(text.replace(",", "."))}))}/>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"paymentTypeIn"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title]}>Zahlungsmittel Einnahme</ListItem.Title>
							<ButtonGroup
									onPress={setPaymentTypeIn}
									selectedIndex={paymentTypeIn}
									buttons={[t('paymentType_CASH'), t('paymentType_DEBIT'), t('paymentType_CREDIT')]}
									containerStyle={[styles.mt10, styles.fullWidth]}
									buttonStyle={[styles.background]}
									selectedButtonStyle={[styles.backgroundPrimary]}
									textStyle={[styles.text]}
									selectedTextStyle={[styles.text]}
							/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"paymentTypeOut"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title]}>Zahlungsmittel Ausgabe</ListItem.Title>
							<ButtonGroup
									onPress={setPaymentTypeOut}
									selectedIndex={paymentTypeOut}
									buttons={[t('paymentType_CASH'), t('paymentType_DEBIT'), t('paymentType_CREDIT')]}
									containerStyle={[styles.mt10, styles.fullWidth]}
									buttonStyle={[styles.background]}
									selectedButtonStyle={[styles.backgroundPrimary]}
									textStyle={[styles.text]}
									selectedTextStyle={[styles.text]}
							/>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"submit"} topDivider bottomDivider containerStyle={[styles.background]} onPress={submit}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title, styles.center]}>Absenden</ListItem.Title>
						</ListItem.Content>
						<ListItem.Chevron/>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"exampleA"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Beispiel A</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>
							  Ein Kunde zahlt 50€ per {t('paymentType_DEBIT')} und Sie geben 50€ {t('paymentType_CASH')} zurück.
								Wählen Sie dafür als Einnahme {t('paymentType_DEBIT')} und als Ausgabe {t('paymentType_CASH')}.
							</ListItem.Subtitle>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"exampleB"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Beispiel B</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>
								Ein Kunde will eine Rechnung via {t('paymentType_CREDIT')} zahlen.
								Dies geben Sie im System ein, die Karte wird jedoch abgelehnt und er zahlt dann doch {t('paymentType_CASH')}.
								Nun können Sie im Nachhinein eine Umbuchung eintragen.
								Wählen Sie dafür als Einnahme {t('paymentType_CASH')} und als Ausgabe {t('paymentType_CREDIT')}.
							</ListItem.Subtitle>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
				</KeyboardAwareScrollView>
		)
	}
}
