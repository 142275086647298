import {Platform} from 'react-native';
import * as Browser from '@sentry/react';
import * as Native from '@sentry/react-native';
import {UserInfo} from '../types/UserInfo';

export function setSentryUser(user: UserInfo | null): void {
	switch (Platform.OS) {
		case 'web':
			Browser.setUser(user);
			break;
		default:
			Native.setUser(user);
			break;
	}
}

export function setSentryStoreContext(storeId: string | null): void {
	switch (Platform.OS) {
		case 'web':
			Browser.setContext("store", {id: storeId})
			break;
		default:
			Native.setContext("store", {id: storeId})
			break;
	}
}

export function setSentryImpersonateContext(waiter: UserInfo | null): void {
	switch (Platform.OS) {
		case 'web':
			Browser.setContext("impersonate", {id: waiter?.id, displayName: waiter?.displayName})
			break;
		default:
			Native.setContext("impersonate", {id: waiter?.id, displayName: waiter?.displayName})
			break;
	}
}
