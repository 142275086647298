import 'react-native-gesture-handler';
import * as React from 'react';
import {RefreshControl, ScrollView} from 'react-native';
import {Divider, ListItem} from 'react-native-elements';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import * as Style from '../../theme/style';
import {getAppContext} from '../../AppProvider';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {LocalizationContext} from "../../LocalizationContext";
import {ScreenState} from "../../types/ScreenState";
import * as Backend from "../../backend/Backend";
import {ErrorView} from "../elements/ErrorView";
import {LoadingView} from "../elements/LoadingView";
import {ReportInfo} from "../../types/Report";
import moment from "moment";
import * as Alert from '../../alerts/alerts';
import {zdtFormat} from "../../backend/Backend";

export default function ReportsListScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'ReportsList'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const {locale, t} = React.useContext(LocalizationContext);

	const [state, setState] = React.useState<ScreenState>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})

	const type = route.params.type
	const [target, setTarget] = React.useState(route.params.target)

	function loadData() {
		Backend.loadStoreData("report/" + type, setState, appContext).then(() => {})
	}
	React.useEffect(() => {
		return navigation.addListener('focus', loadData);
	}, [navigation]);

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen",
				"Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else {
		const reportInfos = state.response.data as ReportInfo[];
		return (
				<ScrollView style={[styles.container]} refreshControl={<RefreshControl onRefresh={loadData} refreshing={state.refreshing} />}>
					{
							type === "Z" && <>
								<ListItem key={"newZReport"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('ReportDetail', {reportIdentifier: undefined})}>
									<FontAwesomeIcon icon="plus" size={32} style={[styles.text]}/>
									<ListItem.Content>
										<ListItem.Title style={[styles.text, styles.title]}>Neuer Z-Bericht</ListItem.Title>
										<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Tagesabschlussbericht erstellen</ListItem.Subtitle>
									</ListItem.Content>
									<ListItem.Chevron/>
								</ListItem>
								<Divider style={[styles.divider]} />
							</>
					}
					{
							(type === "MONTHLY" || type === "YEARLY") && <>
								<ListItem key={"newTimedReport"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {
									Backend.sendStoreData("report/" + type, setState, appContext, "POST", undefined, false).then(() => {
										Alert.alert("Berechnung gestartet", "Die fehlenden Berichte werden nun im Hintergrund berechnet. Dies kann insbesondere bei Jahresberichten einige Zeit in Anspruch nehmen. Du wirst per Push-Benachrichtigung über die Fertigstellung informiert.")
									})}}>
									<FontAwesomeIcon icon="sync" size={32} style={[styles.text]}/>
									<ListItem.Content>
										<ListItem.Title style={[styles.text, styles.title]}>{ t('reportType_' + type) } erstellen</ListItem.Title>
										<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Alle fehlenden abgeschlossenen { t('reportType_' + type + '_list') } werden berechnet</ListItem.Subtitle>
									</ListItem.Content>
								</ListItem>
								<Divider style={[styles.divider]} />
							</>
					}
					{
						reportInfos.map((rInfo, i) =>
								<ListItem key={"report" + i} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('ReportDetail', {reportIdentifier: rInfo})}>
									<FontAwesomeIcon icon="info-circle" size={32} style={[styles.text]}/>
									<ListItem.Content>
										{type === "Z" && <ListItem.Title style={[styles.text, styles.title]}>Z-Bericht {rInfo.zId}</ListItem.Title>}
										{type === "MONTHLY" && <ListItem.Title style={[styles.text, styles.title]}>{moment(rInfo.startTime, zdtFormat).format("MMMM YYYY")}</ListItem.Title>}
										{type === "YEARLY" && <ListItem.Title style={[styles.text, styles.title]}>{moment(rInfo.startTime, zdtFormat).format("YYYY")}</ListItem.Title>}
										<ListItem.Subtitle style={[styles.text, styles.subTitle]}>
											{moment(rInfo.startTime, zdtFormat).format("dddd DD. MMM")}
										</ListItem.Subtitle>
										<ListItem.Subtitle style={[styles.text, styles.subTitle]}>
											{moment(rInfo.startTime, zdtFormat).format("LT")} - {moment(rInfo.endTime, zdtFormat).format("DD.MM LT")}
										</ListItem.Subtitle>
									</ListItem.Content>
									<ListItem.Content right={true}>
										<ListItem.Subtitle style={[styles.text, styles.subTitle, styles.textRight]}>Brutto</ListItem.Subtitle>
										<ListItem.Title style={[styles.text, styles.textRight]}>{rInfo.revenueGross.toLocaleString(locale, { style: 'currency', currency: 'EUR' })}</ListItem.Title>
									</ListItem.Content>
									<ListItem.Chevron/>
								</ListItem>
						)
					}
				</ScrollView>
		);
	}
}
