import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {getAppContext} from "../../AppProvider";
import {ScreenState} from "../../types/ScreenState";
import * as Backend from "../../backend/Backend";
import {Button} from "react-native-paper";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {ErrorView} from "../elements/ErrorView";
import {LoadingView} from "../elements/LoadingView";
import {Article, ArticleCategory, ArticleGroup, ArticleType} from "../../types/Article";
import {RefreshControl, ScrollView, View, ViewStyle} from "react-native";
import {Divider, ListItem} from "react-native-elements";
import {LocalizationContext} from "../../LocalizationContext";
import {taxGroupPercentage} from "../../types/TaxGroup";

export default function EditArticlesScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'EditArticles'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const { locale } = React.useContext(LocalizationContext);
	const [state, setState] = React.useState<ScreenState>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})
	function loadData() {
		Backend.loadArticles(setState, appContext).then(() => {})
	}
	React.useEffect(() => {
		return navigation.addListener('focus', loadData);
	}, [navigation]);

	React.useLayoutEffect(() => {
		navigation.setOptions({
			headerRight: () => (
					<Button onPress={() => navigation.navigate('EditArticleCategory', {})}>
						<FontAwesomeIcon icon="plus" size={24} style={[styles.text]}/>
					</Button>
			),
		});
	}, [navigation]);

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen", "Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else {
		const categories: ArticleCategory[] = state.response.data.categories
		const groups: ArticleGroup[] = state.response.data.groups
		const articles: Article[] = state.response.data.articles
		return (
				<ScrollView style={[styles.container]} refreshControl={<RefreshControl onRefresh={loadData} refreshing={state.refreshing} />}>
					{
						categories.map((category: ArticleCategory, i) =>
								<View key={"group" + i}>
									<ListItem key={"category" + i} topDivider bottomDivider
														containerStyle={[styles.background]} onPress={() => {
										navigation.navigate('EditArticleCategory', {category: category})
									}}>
										<FontAwesomeIcon icon={category.faIcon} size={16} style={[styles.text, {color: category.hexCode} as ViewStyle]}/>
										<ListItem.Content style={[]}>
											<ListItem.Title style={[styles.text, styles.title]}>{category.name}</ListItem.Title>
										</ListItem.Content>
									</ListItem>
									{
										articles.filter((a) => a.category == category.id).map((article, j) =>
												<ListItem key={"article" + j} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {
													if (article.type === ArticleType.ARTICLE || article.type === ArticleType.DEPOSIT) {
														navigation.navigate('CreateArticle', {article: article, category: category, groups: groups.filter(group => group.category === category.id)})
													} else if (article.type === ArticleType.VOUCHER_SINGLEPURPOSE || article.type === ArticleType.VOUCHER_MULTIPURPOSE) {
														navigation.navigate('CreateVoucher', {voucher: article, category: category, groups: groups.filter(group => group.category === category.id)})
													}
												}}>
													<FontAwesomeIcon icon={article.icon} size={24} style={[styles.text]}/>
													<ListItem.Content style={[]}>
														<ListItem.Title style={[styles.text, styles.title]}>{article.name}</ListItem.Title>
														<ListItem.Subtitle style={[styles.text, styles.subTitle]}>SKU: {article.stockKeepingUnit}</ListItem.Subtitle>
														{
															article.itemNumber !== undefined &&
															<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Nr: {article.itemNumber}</ListItem.Subtitle>
														}
														{
															article.group !== undefined &&
															<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Gruppe: {groups.find(g => g.id === article.group)?.name}</ListItem.Subtitle>
														}
													</ListItem.Content>
													<ListItem.Content style={[{flex: 1}]} right={true}>
														<ListItem.Subtitle style={[styles.text]}>{article.unitPrice.toLocaleString(locale, { style: 'currency', currency: 'EUR' })} / {article.quantityUnit}</ListItem.Subtitle>
														<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Vor-Ort: {taxGroupPercentage(article.taxGroupEatHere).toLocaleString(locale, { style: 'decimal' })}%</ListItem.Subtitle>
														<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Mitnahme: {taxGroupPercentage(article.taxGroupTakeAway).toLocaleString(locale, { style: 'decimal' })}%</ListItem.Subtitle>
													</ListItem.Content>
												</ListItem>
										)
									}
									<ListItem key={"addArticle" + i} topDivider bottomDivider containerStyle={[styles.background]}
														onPress={() => navigation.navigate('CreateArticle', {category: category, groups: groups.filter(group => group.category === category.id)})}>
										<FontAwesomeIcon icon="plus" size={32} style={[styles.text]}/>
										<ListItem.Content>
											<ListItem.Title style={[styles.text, styles.title]}>Artikel hinzufügen</ListItem.Title>
										</ListItem.Content>
									</ListItem>
									<ListItem key={"addVoucher" + i} topDivider bottomDivider containerStyle={[styles.background]}
														onPress={() => navigation.navigate('CreateVoucher', {category: category, groups: groups.filter(group => group.category === category.id)})}>
										<FontAwesomeIcon icon="plus" size={32} style={[styles.text]}/>
										<ListItem.Content>
											<ListItem.Title style={[styles.text, styles.title]}>Gutschein hinzufügen</ListItem.Title>
										</ListItem.Content>
									</ListItem>
									<Divider style={[styles.divider]} />
								</View>
						)
					}
					<Divider style={[styles.divider]} />
				</ScrollView>
		);
	}
}
