import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../theme/style';
import {ActivityIndicator, ScrollView} from 'react-native';
import {Divider, ListItem} from 'react-native-elements';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import * as Backend from '../backend/Backend';
import {ErrorView} from './elements/ErrorView';
import {LoadingView} from './elements/LoadingView';
import {getAppContext} from '../AppProvider';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../types/RootStackParamList";
import {ScreenState} from "../types/ScreenState";

export default function ExportScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'Export'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()

	const [state, setState] = React.useState<ScreenState>({
		isLoading: false,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})

	const [requestedDSFinVK, setRequestedDSFinVK] = React.useState<boolean>(false);

	async function exportDSFinVK() {
		setRequestedDSFinVK(true);

		const response = await Backend.loadStoreData('export/dsfinvk', setState, appContext, false);
		let fileUri = FileSystem.documentDirectory + "DSFinVK.zip";
		await FileSystem.writeAsStringAsync(fileUri, response.data, {encoding: FileSystem.EncodingType.Base64});
		await Sharing.shareAsync(fileUri);

		setRequestedDSFinVK(false);
	}
	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else {
		return (
				<ScrollView style={[styles.container]}>
					{
						!requestedDSFinVK &&
						<ListItem key={"DSFinVK"} topDivider bottomDivider containerStyle={[styles.background]} onPress={exportDSFinVK}>
							<FontAwesomeIcon icon="file-archive" size={32} style={[styles.text]}/>
							<ListItem.Content>
								<ListItem.Title style={[styles.text, styles.title]}>DSFinVK-Export</ListItem.Title>
								<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Export runterladen</ListItem.Subtitle>
							</ListItem.Content>
							<ListItem.Chevron/>
						</ListItem>
					}
					{
						requestedDSFinVK &&
						<ListItem key={"requestedDSFinVK"} topDivider bottomDivider containerStyle={[styles.background]}>
							<ActivityIndicator size={"large"}/>
							<ListItem.Content>
								<ListItem.Title style={[styles.text, styles.title]}>DSFinVK-Export</ListItem.Title>
								<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Export lädt...</ListItem.Subtitle>
							</ListItem.Content>
						</ListItem>
					}
					<Divider style={[styles.divider]} />
				</ScrollView>
		);
	}
}
