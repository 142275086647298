import {Address} from "./Address";

export enum OrganizationType {
	BUSINESS = "BUSINESS",
	STORE = "STORE",
}

export type Organization = {
	id: string,
	created: any,
	type: OrganizationType,
	organizationName: string,
	ownerName: string,
	address: Address,
	telephone: string,
	taxId: string,
	subsidiaries?: Store[],
}

export type Business = Organization & {
	config: BusinessConfiguration
}

export type Store = Organization & {
	id: string,
	business: string, // Id
}

export type BusinessConfiguration = {
	id: string,
	headerLine: string,
	footerLine: string
}
