import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {getAppContext} from "../../AppProvider";
import {ScreenState} from "../../types/ScreenState";
import * as Backend from "../../backend/Backend";
import {ErrorView} from "../elements/ErrorView";
import {LoadingView} from "../elements/LoadingView";
import {RefreshControl, ScrollView} from "react-native";
import {Divider, ListItem} from "react-native-elements";
import {UserInfo} from "../../types/UserInfo";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {Button} from "react-native-paper";
import {InviteEmployeeRequest} from "../../types/InviteEmployeeRequest";
import {Role} from "../../types/Role";
import * as Alert from "../../alerts/alerts";

export default function EmployeeScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'Employee'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const [state, setState] = React.useState<ScreenState>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})
	function loadData() {
		Backend.loadStoreData("employees", setState, appContext).then(() => {})
	}
	React.useEffect(loadData, [])
	React.useEffect(() => {
		return navigation.addListener('focus', loadData);
	}, [navigation]);

	function invite(username: string, role: Role = "EMPLOYEE") {
		const request: InviteEmployeeRequest = {
			username: username,
			role: role,
		}
		Backend.sendStoreData("employees", setState, appContext, "PUT", request).then(() => {})
	}

	function removeEmployee(userId: string) {
		Backend.sendStoreData("employees/" + userId, setState, appContext, "DELETE").then(() => {})
	}

	React.useLayoutEffect(() => {
		navigation.setOptions({
			headerRight: () => (
					<Button onPress={() => appContext.openDialog({
						title: "Mitarbeiter hinzufügen",
						message: "Bitte gib den Username des neuen Mitarbeiters ein und beachte hierbei die exakte Schreibweise (Groß-/Kleinbuchstaben). Der Nutzer muss sich bereits im vorhinein registriert haben!",
						input_visible: true,
						input_keyboardType: 'default',
						positiveButton_label: "Einladen",
						positiveButton_onPress(inputValue?: string) {
							if (inputValue)
								invite(inputValue)
						},
						negativeButton_visible: true,
						negativeButton_label: "Abbrechen",
					})}>
						<FontAwesomeIcon icon="plus" size={24} style={[styles.text]}/>
					</Button>
			),
		});
	}, [navigation]);

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen", "Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else {
		const employees: UserInfo[] = state.response.data.employees
		const invitations: UserInfo[] = state.response.data.invitations
		console.log(invitations)
		return (
				<ScrollView style={[styles.container]} refreshControl={<RefreshControl onRefresh={loadData} refreshing={state.refreshing} />}>
					{
						employees.map((employee, i) =>
								<ListItem key={"employee" + i} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {
									navigation.navigate('EditEmployee', {target: employee})
								}}>
									<FontAwesomeIcon icon="user-tie" size={32} style={[styles.text, {color: employee.roles.find((ar) => ar.organization.id == appContext.storeId)?.hexCode}]}/>
									<ListItem.Content style={[]}>
										<ListItem.Title style={[styles.text, styles.title]}>{employee.displayName}</ListItem.Title>
										<ListItem.Subtitle style={[styles.text, styles.subTitle]}>{employee.roles.find((ar) => ar.organization.id == appContext.storeId)?.roleDisplay}</ListItem.Subtitle>
									</ListItem.Content>
									{
										employee.id === appContext.user?.id &&
										<ListItem.Content style={[]} right={true}>
											<ListItem.Title style={[styles.text, styles.title]}>Du</ListItem.Title>
										</ListItem.Content>
									}
								</ListItem>
						)
					}
					{
						invitations.length !== 0 &&
						<>
							<Divider style={[styles.divider]} />
							<ListItem key={"invitationsHeader"} topDivider bottomDivider containerStyle={[styles.background]}>
								<ListItem.Content style={[styles.center]}>
									<ListItem.Title style={[styles.text, styles.title]}>Offene Einladungen</ListItem.Title>
								</ListItem.Content>
							</ListItem>
						</>
					}
					{
						invitations.map((invitedUser, i) =>
								<ListItem key={"invitation" + i} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {
									Alert.confirm("Einladung löschen", "Möchtest du diese Einladung zurückziehen?", "Löschen", "Abbrechen", () => removeEmployee(invitedUser.id), undefined, 'destructive', 'cancel')
								}}>
									<FontAwesomeIcon icon="user-clock" size={32} style={[styles.text]}/>
									<ListItem.Content style={[]}>
										<ListItem.Title style={[styles.text, styles.title]}>{invitedUser.displayName}</ListItem.Title>
										<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Einladung zum {invitedUser.invitations.find((ei) => ei.store.id == appContext.storeId)?.roleDisplay} ausstehend...</ListItem.Subtitle>
									</ListItem.Content>
								</ListItem>
						)
					}
					<Divider style={[styles.divider]} />
				</ScrollView>
		);
	}
}
