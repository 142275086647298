export const de = {
	paymentType_0: 'Bar',
	paymentType_CASH: 'Bar',
	paymentType_3: 'EC-Karte',
	paymentType_DEBIT: 'EC-Karte',
	paymentType_4: 'Kreditkarte',
	paymentType_CREDIT: 'Kreditkarte',
	taxGroup_A: 'Normal',
	taxGroup_B: 'Ermäßigt',
	taxGroup_C: 'Durchschnittsatz (§24(1)Nr.3 UStG)',
	taxGroup_D: 'Durchschnittsatz (§24(1)Nr.1 UStG)',
	taxGroup_E: 'Nicht Steuerbar',
	taxGroup_F: 'Umsatzsteuerfrei',
	taxGroup_G: 'Umsatzsteuer nicht ermittelbar',
	taxGroup_H: 'Corona Normal',
	taxGroup_I: 'Corona Ermäßigt',
	voidReason_OUT_OF_STOCK: 'Ausverkauft',
	voidReason_PARTY_VOID: 'Partei Komplett-Stornierung',
	voidReason_COMPLAINT: 'Beschwerde',
	voidReason_OTHER: 'Sonstiges',
	reportType_Z: 'Z-Bericht',
	reportType_X: 'X-Bericht',
	reportType_MONTHLY: 'Monatsbericht',
	reportType_YEARLY: 'Jahresbericht',
	reportType_Z_list: 'Z-Berichte',
	reportType_X_list: 'X-Berichte',
	reportType_MONTHLY_list: 'Monatsberichte',
	reportType_YEARLY_list: 'Jahresberichte',
	bar: 'Bar {{someValue}}',
};
