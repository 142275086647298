import {ColorValue} from "react-native";

export type ThemeColors = {
	background: ColorValue,
	backgroundBar: ColorValue,
	backgroundHighlight: ColorValue,
	backgroundLight: ColorValue,
	primary: ColorValue,
	secondary: ColorValue,
	text: ColorValue,
	textSecondary: ColorValue,
	textDisabled: ColorValue,
	errorText: ColorValue,
	errorIcon: ColorValue,
	infoColor: ColorValue,
	warningColor: ColorValue,
	destructiveColor: ColorValue,
	linkColor: ColorValue,
}

// Light theme colors
export const lightColors = {
	background: '#f5f5f5',
	backgroundBar: '#bebebe',
	backgroundHighlight: '#dcdcdc',
	backgroundLight: '#ffffff',
	primary: '#96e949',
	secondary: '#9a7fca',
	text: '#121212',
	textSecondary: '#5f5f5f',
	textDisabled: '#4c4c4c',
	errorText: '#D32F2F',
	errorIcon: '#b80c00',
	infoColor: '#00c5cb',
	warningColor: '#ff8200',
	destructiveColor: '#D32F2F',
	linkColor: '#0989ea',
};

// Dark theme colors
export const darkColors = {
	background: '#292929',
	backgroundBar: '#1a1a1a',
	backgroundHighlight: '#1e1e1e',
	backgroundLight: '#323232',
	primary: '#4b8a12',
	secondary: '#512DA8',
	text: '#FFFFFF',
	textSecondary: '#bebebe',
	textDisabled: '#858585',
	errorText: '#ea6d6d',
	errorIcon: '#960700',
	infoColor: '#009da2',
	warningColor: '#ff8200',
	destructiveColor: '#ff0d0d',
	linkColor: '#0989ea',
};
