import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import * as Backend from "../../backend/Backend";
import {ScreenState} from "../../types/ScreenState";
import {getAppContext} from "../../AppProvider";
import {ErrorView} from "../elements/ErrorView";
import {LoadingView} from "../elements/LoadingView";
import {UserInfo} from "../../types/UserInfo";
import {RefreshControl, ScrollView, View} from "react-native";
import {Divider, ListItem} from "react-native-elements";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {AssignedRole} from "../../types/Role";
import {Organization, OrganizationType, Store} from "../../types/Organization";
import * as Storage from "../../storage/storage";
import {CommonActions} from "@react-navigation/native";
import * as Alert from "../../alerts/alerts";
import {setSentryStoreContext, setSentryUser} from '../../util/SentryWrapper';

export default function StoreSelectScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'StoreSelect'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()

	const [state, setState] = React.useState<ScreenState & {storeId?: string|null}>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
		storeId: undefined,
	})

	function loadData() {
		Backend.request("user", setState, appContext).then(() => {})
	}
	React.useEffect(loadData, [])
	React.useEffect(() => {
		return navigation.addListener('focus', loadData);
	}, [navigation]);

	function selectStore(store: Organization) {
		Storage.setItem("storeId", store.id).then(() => {
			setSentryStoreContext(store.id)
			appContext.storeId = store.id
			navigation.dispatch(
					CommonActions.reset({
						index: 0,
						routes: [
							{name: 'Home', params: {user: state.response?.data, store: store}},
						],
					})
			);
		});
	}

	function logout() {
		Alert.confirm("Abmelden?", "Möchtest du dich wirklich abmelden?", "Abmelden", "Abbrechen", () => {
			Storage.deleteItem("authorization").then(() => {
				setSentryUser(null)
				Storage.deleteItem("storeId").then(() => {
					setSentryStoreContext(null)
					navigation.dispatch(
							CommonActions.reset({
								index: 0,
								routes: [
									{ name: 'Login' },
								],
							})
					);
				})
			})
		}, undefined, 'destructive', 'cancel')
	}

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen", "Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else {
		const userData = state.response.data as UserInfo
		const stores = userData.roles.filter((role: AssignedRole) => role.organization.type === OrganizationType.STORE)
		const businesses = userData.roles.filter((role: AssignedRole) => role.organization.type === OrganizationType.BUSINESS)
		return (
				<ScrollView style={[styles.container]} refreshControl={<RefreshControl refreshing={state.refreshing} onRefresh={loadData}/>}>
					{
						userData.roles.length === 0 &&
						<>
							<ListItem key={"empty"} topDivider bottomDivider containerStyle={[styles.background]}>
								<ListItem.Content style={[styles.center]}>
									<ListItem.Title style={[styles.title, styles.center]}>Keine Unternehmen/Filialen gefunden!</ListItem.Title>
								</ListItem.Content>
							</ListItem>
							<Divider style={[styles.divider]} />
						</>
					}
					{
						businesses.map((businessRole: AssignedRole, i: number) =>
								<View key={"businessGroup" + i}>
									<ListItem key={"business" + i} topDivider bottomDivider containerStyle={[styles.background]} onPress={() =>
											appContext.openDialog({
												title: "Filiale hinzufügen",
												message: "Bitte gib hier den Registrierungscode ein, den du von deinem Kundenbetreuer erhalten hast:",
												input_visible: true,
												input_keyboardType: 'number-pad',
												positiveButton_label: "Start",
												positiveButton_onPress(inputValue?: string) {
													if (inputValue) {
														navigation.navigate('StoreCreate', {businessId: businessRole.organization.id, registrationCode: parseInt(inputValue)})
													}
												},
												negativeButton_visible: true,
												negativeButton_label: "Abbrechen",
											})
									}>
										{
											businessRole.organization.type === OrganizationType.BUSINESS ?
													<FontAwesomeIcon icon="building" size={24} style={[styles.text]}/>
													:
													<FontAwesomeIcon icon="store" size={24} style={[styles.text]}/>
										}
										<ListItem.Content style={[]}>
											<ListItem.Title style={[styles.title]}>{businessRole.organization.organizationName}</ListItem.Title>
											<ListItem.Subtitle style={[styles.subTitle]}>{businessRole.roleDisplay}</ListItem.Subtitle>
										</ListItem.Content>
										<ListItem.Content style={[]} right={true}>
											<ListItem.Subtitle style={[styles.text, styles.textRight]}>Neue Filiale</ListItem.Subtitle>
										</ListItem.Content>
										<ListItem.Chevron/>
									</ListItem>
									{
										stores.filter((storeRole: AssignedRole) => businessRole.organization.id === (storeRole.organization as Store).business).map((storeRole: AssignedRole, j: number) =>
												<ListItem key={"business" + i + "store" + j} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {
													selectStore(storeRole.organization)
												}}>
													{
														storeRole.organization.type === OrganizationType.BUSINESS ?
																<FontAwesomeIcon icon="building" size={24} style={[styles.text]}/>
																:
																<FontAwesomeIcon icon="store" size={24} style={[styles.text]}/>
													}
													<ListItem.Content style={[]}>
														<ListItem.Title style={[styles.title]}>{storeRole.organization.organizationName}</ListItem.Title>
														<ListItem.Subtitle style={[styles.subTitle]}>{storeRole.roleDisplay}</ListItem.Subtitle>
													</ListItem.Content>
													<ListItem.Chevron/>
												</ListItem>
										)
									}
									<Divider style={[styles.divider]} />
								</View>
						)
					}
					{
						stores.filter((storeRole: AssignedRole) => businesses.find((businessRole) => businessRole.organization.id == (storeRole.organization as Store).business) === undefined).map((storeRole: AssignedRole, j: number) =>
								<View key={"storeGroup" + j}>
									<ListItem key={"store" + j} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {
										selectStore(storeRole.organization)
									}}>
										{
											storeRole.organization.type === OrganizationType.BUSINESS ?
													<FontAwesomeIcon icon="building" size={24} style={[styles.text]}/>
													:
													<FontAwesomeIcon icon="store" size={24} style={[styles.text]}/>
										}
										<ListItem.Content style={[]}>
											<ListItem.Title style={[styles.title]}>{storeRole.organization.organizationName}</ListItem.Title>
											<ListItem.Subtitle style={[styles.subTitle]}>{storeRole.roleDisplay}</ListItem.Subtitle>
										</ListItem.Content>
										<ListItem.Chevron/>
									</ListItem>
									<Divider style={[styles.divider]} />
								</View>
						)
					}
					{
						userData.invitations.length !== 0 &&
						<>
							<ListItem key={"invitationsHeader"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {
								// TODO: Share
							}}>
								<ListItem.Content style={[styles.center]}>
									<ListItem.Title style={[styles.title, styles.center]}>Eingegangene Einladungen</ListItem.Title>
									<ListItem.Subtitle style={[styles.subTitle, styles.center]}>Du wurdest als Mitarbeiter eingetragen. Klicke auf die unten stehende Filiale, um die Einladung anzunehmen</ListItem.Subtitle>
								</ListItem.Content>
							</ListItem>
							{
								userData.invitations.map((invitation, i) =>
										<ListItem key={"invitation" + i} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {
											Alert.confirm("Einladung annehmen", "Möchtest du diese Einladung annehmen?", "Annehmen", "Abbrechen", () => {
												Backend.request(`store/${invitation.store.id}/employees/accept`, setState, appContext, "PUT").then(() => {})
											}, undefined, 'default', 'cancel')
										}}>
											<FontAwesomeIcon icon="user-clock" size={32} style={[styles.text]}/>
											<ListItem.Content style={[]}>
												<ListItem.Title style={[styles.text, styles.title]}>{invitation.store.organizationName}</ListItem.Title>
												<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Einladung zum {invitation.roleDisplay} ausstehend...</ListItem.Subtitle>
											</ListItem.Content>
										</ListItem>
								)
							}
							<Divider style={[styles.divider]} />
						</>
					}
					<ListItem key={"employer"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {
						appContext.openDialog({
							title: "Unternehmen registrieren",
							message: "Bitte gib hier den Registrierungscode ein, den du von deinem Kundenbetreuer erhalten hast:",
							input_visible: true,
							input_keyboardType: 'number-pad',
							positiveButton_label: "Start",
							positiveButton_onPress(inputValue?: string) {
								if (inputValue) {
									navigation.navigate('BusinessCreate', {registrationCode: parseInt(inputValue)})
								}
							},
							negativeButton_visible: true,
							negativeButton_label: "Abbrechen",
						})
					}}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title, styles.center]}>Du bist Unternehmer?</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle, styles.center]}>Klicke hier um dein Unternehmen zu registrieren</ListItem.Subtitle>
						</ListItem.Content>
						<ListItem.Chevron/>
					</ListItem>
					<ListItem key={"employee"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {
						// TODO: Share
					}}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title, styles.center]}>Du bist Mitarbeiter?</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle, styles.center]}>Bitte deinen Arbeitgeber, dich als Mitarbeiter einzutragen.</ListItem.Subtitle>
							<ListItem.Subtitle style={[styles.subTitle, styles.center]}>Er braucht dafür lediglich deinen Nutzernamen "{userData.username}"</ListItem.Subtitle>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"logout"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => logout()}>
						<FontAwesomeIcon icon="sign-out-alt" size={32} style={[styles.text]}/>
						<ListItem.Content>
							<ListItem.Title style={[styles.text, styles.title]}>Abmelden</ListItem.Title>
							<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Von diesem Gerät abmelden</ListItem.Subtitle>
						</ListItem.Content>
						<ListItem.Chevron />
					</ListItem>
					<Divider style={[styles.divider]} />
				</ScrollView>
		)
	}
}
