import {Text, View, ViewStyle} from 'react-native';
import * as React from 'react';
import * as Style from '../../../theme/style';
import {DialogState} from '../../../types/DialogState';
import {useTheme} from '../../../theme/ThemeProvider';
import {Button, Dialog, Portal, TextInput} from 'react-native-paper';

export default function DialogWrapper(data : {
	dialog: DialogState,
	closeDialog: () => void
}) {
	const styles = Style.getStyles()
	const {colors} = useTheme();

	const [inputValue, setInputValue] = React.useState<string | undefined>(undefined);
	const [pickerValue, setPickerValue] = React.useState<string | undefined>(undefined);

	function closeDialog() {
		data.closeDialog()
		setInputValue(undefined)
		setPickerValue(undefined)
	}

	const dialog = data.dialog

	return (
			<View style={[styles.positionAbsoluteFill, {
				zIndex: dialog.isVisible ? 1 : -1
			} as ViewStyle]}>
				<Portal>
					<Dialog visible={dialog.isVisible} onDismiss={closeDialog} style={[{position: 'relative'} as ViewStyle]}>
						<Dialog.Title>{dialog.title}</Dialog.Title>
						<Dialog.Content>
							{
									dialog.message &&
									<Text style={[styles.text, styles.mb10]}>{dialog.message}</Text>
							}
							{
									dialog.input_visible &&
									<TextInput
											label={dialog.input_placeholder}
											value={inputValue ?? dialog.input_defaultValue}
											onChangeText={text => setInputValue(text)}
											style={[styles.my10]}
									/>
							}
							<Button onPress={() => {
								dialog.positiveButton_onPress(inputValue, pickerValue)
								closeDialog()
							}}
											disabled={dialog.positiveButton_disabled}
											mode={'outlined'} style={[styles.my10]}>
								{dialog.positiveButton_label}
							</Button>
							{
									dialog.middleButton_visible &&
									<Button onPress={() => {
										dialog.middleButton_onPress(inputValue, pickerValue)
										closeDialog()
									}}
													disabled={dialog.middleButton_disabled}
													mode={'outlined'} style={[styles.my10]}>
										{dialog.middleButton_label}
									</Button>
							}
							{
									dialog.negativeButton_visible &&
									<Button onPress={() => {
										dialog.negativeButton_onPress(inputValue, pickerValue)
										closeDialog()
									}}
													disabled={dialog.negativeButton_disabled}
													mode={'outlined'} style={[styles.my10]}>
										{dialog.negativeButton_label}
									</Button>
							}
						</Dialog.Content>
						<Dialog.Actions>
							<Button onPress={closeDialog} mode={"contained"} style={[styles.mx10]}>Abbrechen</Button>
						</Dialog.Actions>
					</Dialog>
				</Portal>
			</View>
	)
}
