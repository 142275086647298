import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {RefreshControl, SafeAreaView, ScrollView, View} from 'react-native';
import * as Backend from '../../backend/Backend';
import {ErrorView} from '../elements/ErrorView';
import {LoadingView} from '../elements/LoadingView';
import {getAppContext} from '../../AppProvider';
import {ScreenState} from "../../types/ScreenState";
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {OpenTable} from "../../types/OpenTable";
import {Divider, ListItem} from "react-native-elements";
import {LocalizationContext} from "../../LocalizationContext";
import {PaymentRequest} from "../../types/PaymentRequest";

export default function HistoryTableScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'HistoryTable'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const { locale } = React.useContext(LocalizationContext);

	const [state, setState] = React.useState<ScreenState>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})

	function reprintPaymentReceipt(payment: PaymentRequest) {
		appContext.openDialog({
			title: 'Rechnung drucken',
			message: 'Möchtest du die Rechnung (mit Bewirtungsbeleg) erneut drucken?',
			positiveButton_label: 'Drucken',
			positiveButton_bold: true,
			negativeButton_label: 'Abbrechen',
			positiveButton_onPress(inputValue) {
				Backend.loadStoreData("tables/history/" + route?.params?.id + "/payment/" + payment.id + "/reprint", setState, appContext, false).then(() => {})
			},
			negativeButton_onPress(inputValue?: string) {}
		})
	}

	function loadData() {
		Backend.loadStoreData("tables/history/" + route?.params?.id, setState, appContext).then(() => {})
	}
	React.useEffect(loadData, [])
	React.useEffect(() => {
		return navigation.addListener('focus', loadData);
	}, [navigation]);

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen",
				"Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else {
		const tableData = state.response?.data as OpenTable
		return (
				<SafeAreaView style={[styles.container]}>
					<ScrollView style={[styles.container]} refreshControl={<RefreshControl refreshing={state.refreshing} onRefresh={loadData}/>}>
						{
							tableData.payments.map((payment, i) => {
										return (<View key={"payment" + i}>
											<ListItem key={"paymentsHeader"} topDivider bottomDivider containerStyle={[styles.background]}>
												<ListItem.Content style={[styles.center]}>
													<ListItem.Title style={[styles.text, styles.title, styles.center]}>{payment.Dsc} {payment.Amt.toLocaleString(locale, {style: 'currency', currency: 'EUR'})}</ListItem.Title>
													<ListItem.Subtitle style={[styles.text, styles.subTitle, styles.center]}>{payment.inHouseConsumption ? 'Verzehr vor Ort' : 'Außer Haus'}</ListItem.Subtitle>
												</ListItem.Content>
											</ListItem>
											<ListItem topDivider bottomDivider containerStyle={[styles.background]} onPress={() => reprintPaymentReceipt(payment)}>
												<ListItem.Content style={[]}>
													<ListItem.Title style={[styles.title]}>Rechnung neu drucken</ListItem.Title>
												</ListItem.Content>
												<ListItem.Chevron/>
											</ListItem>
											<ListItem topDivider bottomDivider containerStyle={[styles.background]}>
												<ListItem.Content style={[]}>
													<ListItem.Title style={[styles.title]}>Zahlungsmittel korrigieren</ListItem.Title>
												</ListItem.Content>
												<ListItem.Content style={[]} right={true}>
													<ListItem.Subtitle style={[styles.subTitle]}>Coming soon...</ListItem.Subtitle>
												</ListItem.Content>
											</ListItem>
											<Divider style={[styles.divider]}/>
										</View>)
									}
							)
						}
						<Divider style={[styles.divider]}/>
					</ScrollView>
				</SafeAreaView>
		)
	}
}
