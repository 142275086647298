import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {getAppContext} from "../../AppProvider";
import {ScreenState} from "../../types/ScreenState";
import * as Alert from "../../alerts/alerts";
import * as Backend from "../../backend/Backend";
import {ErrorView} from "../elements/ErrorView";
import {LoadingView} from "../elements/LoadingView";
import {KeyboardAwareScrollView} from "react-native-keyboard-aware-scroll-view";
import {Divider, ListItem} from "react-native-elements";
import {MoneyTransferRequest} from "../../types/MoneyTransferRequest";

export default function MoneyTransferScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'MoneyTransfer'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()

	const [state, setState] = React.useState<ScreenState & MoneyTransferRequest>({
		isLoading: false,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,

		amount: -1,
		isIncome: true,
	})

	function submit() {
		if (state.amount < 0) {
			Alert.alert("Ungültige Eingabe", "Der Wert muss positiv sein")
			return
		}

		const request: MoneyTransferRequest = {
			amount: state.amount,
			isIncome: state.isIncome,
		}

		Backend.sendStoreData("special/transaction/transfer", setState, appContext, "PUT", request).then(() => {
			navigation.goBack()
		})
	}

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else {
		return (
				<KeyboardAwareScrollView style={[styles.container]} enableResetScrollToCoords={false}>
					<ListItem key={"amount"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Wert in Euro</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"630,00"} keyboardType={"decimal-pad"}
															onChangeText={text => setState((s) => ({...s, amount: Number(text.replace(",", "."))}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"isIncome"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => setState((s) => ({...s, isIncome: !s.isIncome}))}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Einzahlung</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>
								Checkbox für Wechselgeldeinzahlung aktivieren und für Abschöpfungen deaktivieren
							</ListItem.Subtitle>
						</ListItem.Content>
						<ListItem.CheckBox checked={state.isIncome} onPress={() => setState((s) => ({...s, isIncome: !s.isIncome}))}/>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"submit"} topDivider bottomDivider containerStyle={[styles.background]} onPress={submit}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title, styles.center]}>Absenden</ListItem.Title>
						</ListItem.Content>
						<ListItem.Chevron/>
					</ListItem>
					<Divider style={[styles.divider]} />
				</KeyboardAwareScrollView>
		)
	}
}
