import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {getAppContext} from "../../AppProvider";
import {ScreenState} from "../../types/ScreenState";
import * as Backend from "../../backend/Backend";
import {Button} from "react-native-paper";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {ErrorView} from "../elements/ErrorView";
import {LoadingView} from "../elements/LoadingView";
import {RefreshControl, ScrollView, View} from "react-native";
import {Divider, ListItem} from "react-native-elements";
import * as Alert from "../../alerts/alerts";
import {Floorplan, Table} from "../../types/Floorplan";

export default function EditFloorplanScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'EditFloorplan'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const [c, setC] = React.useState(0);
	const [state, setState] = React.useState<ScreenState>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})
	function loadData() {
		Backend.loadStoreData("tables", setState, appContext).then(() => { setC(0) })
	}
	React.useEffect(loadData, [])

	React.useLayoutEffect(() => {
		navigation.setOptions({
			headerRight: () => (
					<Button onPress={() => appContext.openDialog({
						title: "Bereich hinzufügen",
						message: "Bitte gib den Namen des neuen Bereichs ein:",
						input_visible: true,
						input_keyboardType: 'default',
						input_placeholder: "Außenbereich",
						positiveButton_label: "Erstellen",
						positiveButton_onPress(inputValue?: string) {
							setState((s) => {
								if (inputValue) {
									(s.response?.data.floorplan as Floorplan).floors.push({name: inputValue, tables: []})
								}
								return s
							});
							setC(c + 1)
						},
						negativeButton_visible: true,
						negativeButton_label: "Abbrechen",
					})}>
						<FontAwesomeIcon icon="plus" size={24} style={[styles.text]}/>
					</Button>
			),
		});
	}, [navigation]);

	function submit() {
		if (state.response?.data?.floorplan) {
			Backend.sendStoreData("floors", setState, appContext, "PUT", state.response?.data?.floorplan.floors, false).then((r) => {
				setState((s) => ({...s, response: {...s.response, data: {...s.response?.data, floorplan: r.data}}}));
				setC(0)
			})
		}
	}

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen", "Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else if (state.response.data.tables.length !== 0) {
		return ErrorView("Offene Tische", "Aktuell sind noch Tische aktiv. Du kannst den Tischplan nur verändern, wenn keine Tische aktiv sind.")
	} else {
		const floorplan: Floorplan = state.response.data.floorplan
		return (
				<ScrollView style={[styles.container]} refreshControl={<RefreshControl onRefresh={() => {
					Alert.confirm("Neu laden?", "Wenn du neu lädst, werden deine Änderungen verworfen", "Zurücksetzen", "Abbrechen", loadData, undefined, 'destructive', 'cancel')
				}} refreshing={state.refreshing} />}>
					{
						c !== 0 &&
						<>
							<ListItem key={"save"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {
								Alert.confirm("Speichern", "Möchtest du wirklich den Tischplan mit deinen Änderungen überschreiben?", "Speichern", "Abbrechen", submit, undefined, 'default', 'cancel')
							}}>
								<FontAwesomeIcon icon="save" size={32} style={[styles.text]}/>
								<ListItem.Content style={[styles.center]}>
									<ListItem.Title style={[styles.text, styles.title, styles.center]}>Änderungen speichern</ListItem.Title>
									<ListItem.Subtitle style={[styles.text, styles.subTitle, styles.center]}>Überschreibt den aktuellen Tischplan</ListItem.Subtitle>
								</ListItem.Content>
							</ListItem>
							<Divider style={[styles.divider]} />
						</>
					}
					{
						floorplan.floors.length === 0 &&
						<>
							<ListItem key={"noFloors"} topDivider bottomDivider containerStyle={[styles.background]}>
								<ListItem.Content style={[styles.center]}>
									<ListItem.Title style={[styles.text, styles.title, styles.center]}>Keine Bereiche</ListItem.Title>
									<ListItem.Subtitle style={[styles.text, styles.subTitle, styles.center]}>Lege zunächst über das Plus oben rechts einen neuen Bereich an</ListItem.Subtitle>
								</ListItem.Content>
							</ListItem>
							<Divider style={[styles.divider]} />
						</>
					}
					{
						floorplan.floors.map((floor, i) =>
								<View key={"view" + i}>
									<ListItem key={"floor" + i} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {
										Alert.confirm("Bereich entfernen", "Möchtest du diesen Bereich löschen? Dies löscht auch alle Tische in diesem Bereich", "Entfernen", "Abbrechen", () => {
											setState((s) => {
												(s.response?.data.floorplan as Floorplan).floors = (s.response?.data.floorplan as Floorplan).floors.filter(f => f !== floor)
												return s
											});
											setC(c + 1)
										}, undefined, 'destructive', 'cancel')
									}}>
										<FontAwesomeIcon icon="folder" size={16} style={[styles.text]}/>
										<ListItem.Content>
											<ListItem.Title style={[styles.text, styles.title]}>{floor.name}</ListItem.Title>
										</ListItem.Content>
									</ListItem>
									{
										floor.tables.map((table: Table, j) =>
												<ListItem key={"table" + j} topDivider bottomDivider
																	containerStyle={[styles.background]} onPress={() => {
													Alert.confirm("Tisch entfernen", "Möchtest du diesen Tisch löschen?", "Entfernen", "Abbrechen", () => {
														setState((s) => {
															(s.response?.data.floorplan as Floorplan).floors[i].tables = (s.response?.data.floorplan as Floorplan).floors[i].tables.filter(t => t.id !== table.id)
															return s
														});
														setC(c + 1)
													}, undefined, 'destructive', 'cancel')
												}}>
													<FontAwesomeIcon icon="chair" size={16} style={[styles.text]}/>
													<ListItem.Content style={[]}>
														<ListItem.Title style={[styles.text, styles.title]}>{table.name}</ListItem.Title>
													</ListItem.Content>
												</ListItem>
										)
									}
									<ListItem key={"addTable" + i} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {
										appContext.openDialog({
											title: "Tisch hinzufügen",
											message: "Bitte gib den Tischnamen ein:",
											input_visible: true,
											input_keyboardType: 'default',
											input_placeholder: "Tisch 1",
											positiveButton_label: "Erstellen",
											positiveButton_onPress(inputValue?: string) {
												if (inputValue) {
													if (floorplan.floors.find(f => f.tables.find(t => t.name === inputValue) !== undefined) === undefined) {
														setState((s) => {
															(s.response?.data.floorplan as Floorplan).floors[i].tables.push({name: inputValue, posX: 0, posY: 0, sizeX: 0, sizeY: 0})
															return s
														});
														setC(c + 1)
													} else {
														Alert.alert("Tischnummer vergeben!", "Es gibt bereits einen Tisch mit dieser Nummer!")
													}
												}
											},
											negativeButton_visible: true,
											negativeButton_label: "Abbrechen",
										})
									}}>
										<FontAwesomeIcon icon="plus" size={32} style={[styles.text]}/>
										<ListItem.Content>
											<ListItem.Title style={[styles.text, styles.title]}>Tisch hinzufügen</ListItem.Title>
										</ListItem.Content>
									</ListItem>
									<Divider style={[styles.divider]} />
								</View>
						)
					}
				</ScrollView>
		);
	}
}
