import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {getAppContext} from "../../AppProvider";
import {ScreenState} from "../../types/ScreenState";
import * as Backend from "../../backend/Backend";
import {ErrorView} from "../elements/ErrorView";
import {LoadingView} from "../elements/LoadingView";
import {Image, Platform, RefreshControl, ScrollView} from "react-native";
import {Divider, ListItem} from "react-native-elements";
import * as Alert from "../../alerts/alerts";
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import {ImageInfo} from 'expo-image-picker/build/ImagePicker.types';
import * as FileSystem from 'expo-file-system';

export default function EditLogoScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'EditLogo'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const [state, setState] = React.useState<ScreenState>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})
	function loadData() {
		Backend.loadStoreData("logo", setState, appContext).then(() => {})
	}
	React.useEffect(() => {
		return navigation.addListener('focus', loadData);
	}, [navigation]);

	async function ensureImageLibraryPermission(): Promise<boolean> {
		if (Platform.OS !== 'web') {
			const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
			if (status !== 'granted') {
				alert('Sorry, we need camera roll permissions to make this work!');
				return false;
			}
		}
		return true;
	}
	async function pickImage(): Promise<ImageInfo | undefined> {
		const libraryPermission = await ensureImageLibraryPermission();
		if (!libraryPermission) {
			return undefined;
		}
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: true,
			aspect: [4, 3],
			quality: 1,
			base64: true,
		});
		if (result.cancelled) {
			return undefined;
		}
		return result
	}

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen", "Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else {
		const logo: {image: string, height: number, width: number} | undefined = state.response.data
		const logoWidth = Math.min(appContext.minDimension*0.8, 500)
		const logoHeight = (logo?.height || 1) * (logoWidth / (logo?.width || 1))
		return (
				<ScrollView style={[styles.container]} refreshControl={<RefreshControl onRefresh={loadData} refreshing={state.refreshing} />}>
					<ListItem key={"logo"} topDivider bottomDivider
										containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.text, styles.title]}>Bon-Logo</ListItem.Title>
							<ListItem.Subtitle style={[styles.text, styles.subTitle, styles.mb10]}>Dieses Logo wird auf Rechnungs-Bons gedruckt</ListItem.Subtitle>
							{
								logo &&
								<Image source={{uri: `data:image/png;base64,${logo?.image}`}} style={[{width: logoWidth, height: logoHeight}]} width={logoWidth} height={logoHeight} />
							}
							{
								!logo &&
								<ListItem.Subtitle style={[styles.text, styles.subTitle, styles.warningColor, styles.mb10]}>Kein Logo vorhanden</ListItem.Subtitle>
							}
						</ListItem.Content>
					</ListItem>
					{
						logo &&
						<ListItem key={"delete"} topDivider bottomDivider
											containerStyle={[styles.background]} onPress={() => {
							Alert.confirm("Logo entfernen", "Möchtest du dieses Logo löschen?", "Entfernen", "Abbrechen", () => {
								Backend.sendStoreData("logo", setState, appContext, "DELETE", undefined, false).then(() => {
									setState((s) => ({...s, response: {...s.response, data: undefined}}));
								})
							}, undefined, 'destructive', 'cancel')
						}}>
							<ListItem.Content style={[]}>
								<ListItem.Title style={[styles.text, styles.title]}>Logo löschen</ListItem.Title>
								<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Es wird kein Logo mehr auf die Bons gedruckt</ListItem.Subtitle>
							</ListItem.Content>
							<ListItem.Chevron/>
						</ListItem>
					}
					<Divider style={[styles.divider]} />
					<ListItem key={"uploadFile"} topDivider bottomDivider
										containerStyle={[styles.background]} onPress={() => {
						DocumentPicker.getDocumentAsync({type: 'image/*'}).then(result => {
							if (result.type === "success") {
								console.log("picked file " + result.name);
								FileSystem.readAsStringAsync(result.uri, {encoding: "base64"}).then(base64string => {
									Backend.sendStoreData("logo", setState, appContext, "PUT", {
										image: base64string,
										height: undefined,
										width: undefined
									}).then(() => {
										console.log("uploaded file " + result.name);
									}).catch(() => {
										console.log("error uploading file");
									})
								}).catch(() => {
									console.log("error reading file");
								})
							}
						})
					}}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.text, styles.title]}>Neue Logo-Datei hochladen</ListItem.Title>
							<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Datei-Browser öffnen</ListItem.Subtitle>
						</ListItem.Content>
						<ListItem.Chevron/>
					</ListItem>
					<ListItem key={"uploadImage"} topDivider bottomDivider
										containerStyle={[styles.background]} onPress={() => {
						pickImage().then(image => {
							if (image) {
								Backend.sendStoreData("logo", setState, appContext, "PUT", {
									image: image.base64,
									height: image.height,
									width: image.width
								}).then(() => {
									console.log("uploaded image!");
								})
							}
						})
					}}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.text, styles.title]}>Neues Logo-Bild hochladen</ListItem.Title>
							<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Fotoalbum durchsuchen</ListItem.Subtitle>
						</ListItem.Content>
						<ListItem.Chevron/>
					</ListItem>
					<Divider style={[styles.divider]} />
				</ScrollView>
		);
	}
}
