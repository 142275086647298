import 'react-native-gesture-handler';
import * as React from 'react';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import * as Style from '../../theme/style';
import {getAppContext} from '../../AppProvider';
import {ScreenState} from '../../types/ScreenState';
import * as Alert from '../../alerts/alerts';
import * as Backend from '../../backend/Backend';
import {ErrorView} from '../elements/ErrorView';
import {LoadingView} from '../elements/LoadingView';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {Divider, ListItem} from 'react-native-elements';
import {ReservationCreateRequest} from '../../types/Reservation';
import {Customer} from '../../types/Customer';
import DatePickerListItem from '../components/datepicker/DatePickerListItem';

export default function AddReservationScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'AddReservation'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()

	const [state, setState] = React.useState<ScreenState>({
		isLoading: false,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})

	const minimumDate = new Date(new Date().setMinutes(0, 0, 0))
	const [startDate, setStartDateInternal] = React.useState(new Date(new Date().setHours(new Date().getHours() + 1, 0, 0, 0)))
	const [openStartDate, setOpenStartDate] = React.useState(false)
	const [endDate, setEndDateInternal] = React.useState(new Date(new Date(startDate).setHours(startDate.getHours() + 2)))
	const [openEndDate, setOpenEndDate] = React.useState(false)

	function setStartDate(newValue: Date): void {
		const oldStartTime = startDate.getTime()
		const oldEndTime = endDate.getTime()
		const oldDistance = Math.max(0, oldEndTime - oldStartTime)

		const newStartTime = newValue.setSeconds(0, 0)
		const newEndTime = newStartTime + oldDistance
		setStartDateInternal(new Date(newStartTime))
		setEndDateInternal(new Date(newEndTime))
	}

	function setEndDate(newValue: Date): void {
		const newEndTime = newValue.setSeconds(0, 0)
		setEndDateInternal(new Date(newEndTime))
	}

	const [customer, setCustomer] = React.useState<Customer|undefined>()
	const [reservation, setReservation] = React.useState<ReservationCreateRequest>( {
		customer: '',
		guests: 0,
		additionalNotes: '',
		startTime: '',
		endTime: '',
		tableId: ''
	})

	function submit() {
		if (reservation.customer.length === 0 && reservation.additionalNotes?.length === 0) {
			Alert.alert("Bitte wähle einen Kunden aus oder trage Notizen ein!")
			return;
		}
		if (isNaN(reservation.guests) || reservation.guests === 0) {
			Alert.alert("Bitte gib die Gästeanzahl an!")
			return;
		}
		const now = new Date()
		if (startDate < now || endDate < now) {
			Alert.alert("Bitte wähle ein Datum in der Zukunft!")
			//return;
		}
		const body = {
			...reservation,
			startTime: startDate.toISOString(),
			endTime: endDate.toISOString(),
		}
		Backend.sendStoreData("reservations", setState, appContext, "PUT", body, false).then(response => {
			console.log(response)
			navigation.goBack()
		})
	}

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else {
		function replaceSpace(str: string | undefined) {
			return str?.replace(/\u0020/g, '\u00a0')
		}

		function toInt(str: string): number {
			let int = parseInt(str.replace(/[^0-9]/g, ''));
			if (isNaN(int))
				int = 0
			return int
		}

		return (
				<KeyboardAwareScrollView style={[styles.container]} enableResetScrollToCoords={false}>
					<ListItem key={"customer"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('SelectCustomer')}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Kundendaten</ListItem.Title>
							{
								customer == null ?
										<ListItem.Subtitle style={[styles.subTitle]}>Kein/e Kunde/in gewählt</ListItem.Subtitle>
										:
										<ListItem.Subtitle style={[styles.subTitle]}>Kunde: {customer.fullName}</ListItem.Subtitle>
							}
						</ListItem.Content>
						<ListItem.Chevron/>
					</ListItem>
					<ListItem key={"guests"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Anzahl Gäste</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} keyboardType={"number-pad"}
															placeholder={"0"}
															value={reservation.guests === 0 || isNaN(reservation.guests) ? "" : reservation.guests.toString()}
															onChangeText={text => setReservation((s) => ({...s, guests: toInt(text)}))}/>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<DatePickerListItem nativeId={'startTime'} minimumValue={minimumDate} value={startDate} callback={setStartDate} />
					<DatePickerListItem nativeId={'endTime'} minimumValue={startDate} value={endDate} callback={setEndDate} />
					<Divider style={[styles.divider]} />
					<ListItem key={"additionalNotes"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Weitere Notizen</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"Notizen"} keyboardType={"default"}
															value={replaceSpace(reservation.additionalNotes)}
															onChangeText={text => setReservation((s) => ({...s, additionalNotes: text}))}/>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"submit"} topDivider bottomDivider containerStyle={[styles.background]} onPress={submit}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title, styles.center]}>Absenden</ListItem.Title>
						</ListItem.Content>
						<ListItem.Chevron/>
					</ListItem>
					<Divider style={[styles.divider]} />
				</KeyboardAwareScrollView>
		)
	}
}
