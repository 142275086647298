import * as React from 'react';
import 'react-native-gesture-handler';
import {Provider as PaperProvider} from 'react-native-paper';

import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import * as Localization from "expo-localization";
import * as Notifications from 'expo-notifications';
import * as Updates from 'expo-updates';
import * as WebBrowser from 'expo-web-browser';
import {I18n, Scope, TranslateOptions} from 'i18n-js';
import * as Sentry from '@sentry/react-native';
import {LocalizationContext} from './LocalizationContext';
import {de} from "./localization/de";
import {en} from "./localization/en";
import {ThemeProvider} from './theme/ThemeProvider';
import {MainNavigation} from "./views/MainNavigation";
import {Platform} from "react-native";

library.add(fab, fas)

const i18n = new I18n({
	de: de,
	en: en,
});
i18n.enableFallback = true;
i18n.defaultLocale = 'de';
i18n.locale = Localization.locale;

Sentry.init({
	dsn: "https://601b74516d0a41bebe4113add110bb81@o1356091.ingest.sentry.io/6641168",
	enabled: __DEV__,
	debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

Notifications.setNotificationHandler({
	handleNotification: async () => ({
		shouldShowAlert: true,
		shouldPlaySound: false,
		shouldSetBadge: false,
	}),
});

Notifications.setBadgeCountAsync(0).then(() => {})

WebBrowser.maybeCompleteAuthSession();

export default function App() {
	const [locale, setLocale] = React.useState(Localization.locale);
	const localizationContext = React.useMemo(
		() => ({
			t: (scope: Scope, options: TranslateOptions | undefined) => i18n.t(scope, { locale, ...options }),
			locale,
			setLocale,
		}),
		[locale]
	);

	const {
		currentlyRunning,
		availableUpdate,
		downloadedUpdate,
		initializationError,
	} = Updates.useUpdates();

	if (availableUpdate) {
		console.log('New OTA-Update available! New id: ' + availableUpdate.manifest?.id);
	}
	if (downloadedUpdate) {
		console.log('New OTA-Update downloaded! New id: ' + downloadedUpdate.manifest?.id);
	}
	if (initializationError) {
		console.log('Error with OTA-Update! Message: ' + initializationError.message);
	}

	return (
		<>
			{
				// style-Tag breaks mobile app, but is required on web to show icons
				(Platform.OS === 'web') &&
				<style type="text/css">{`
				  @font-face {
					font-family: 'MaterialIcons';
					src: url(${require('react-native-vector-icons/Fonts/MaterialIcons.ttf')}) format('truetype');
				  }
				
				  @font-face {
					font-family: 'FontAwesome';
					src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
				  }
				`}
				</style>
			}
			<ThemeProvider>
				<PaperProvider>
					<LocalizationContext.Provider value={localizationContext}>
						<MainNavigation/>
					</LocalizationContext.Provider>
				</PaperProvider>
			</ThemeProvider>
		</>
	)
		;
}

