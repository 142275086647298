import * as React from 'react';
import {ActivityIndicator, SafeAreaView, Text} from 'react-native';
import * as Style from '../../theme/style';

export function LoadingView(text = "Laden...") {
	const styles = Style.getStyles()

	return (
			<SafeAreaView style={[styles.container, styles.center]}>
				<ActivityIndicator size="large" />
				<Text style={[styles.text, {
					marginTop: 20
				}]}>{text}</Text>
			</SafeAreaView>
	)
}
