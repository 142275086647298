import 'react-native-gesture-handler';
import * as React from 'react';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import * as Style from '../../theme/style';
import {getAppContext} from '../../AppProvider';
import {ScreenState} from '../../types/ScreenState';
import {ErrorView} from '../elements/ErrorView';
import {LoadingView} from '../elements/LoadingView';
import {ScrollView} from 'react-native';
import {Divider, ListItem} from 'react-native-elements';
import * as Backend from '../../backend/Backend';
import * as Alert from '../../alerts/alerts';
import moment from 'moment';
import {Reservation} from '../../types/Reservation';
import {zdtFormat} from "../../backend/Backend";

export default function ReservationDetailsScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'ReservationDetails'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const [state, setState] = React.useState<ScreenState>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})

	function loadData() {
		Backend.loadStoreData("reservations/" + route?.params?.id, setState, appContext).then(() => {})
	}
	React.useEffect(loadData, [])
	React.useEffect(() => {
		return navigation.addListener('focus', loadData);
	}, [navigation]);

	function assignTable() {
		Alert.alert("Diese Funktion steht in einem zukünftigen Update bereit!") // TODO
	}

	function deleteReservation() {
		Alert.confirm("Reservierung stornieren", "Möchtest du diese Reservierung löschen?", "Stornieren", "Abbrechen", () => {
			Backend.sendStoreData("reservations/" + route.params?.id, setState, appContext, "DELETE").then(() => {
				navigation.goBack()
			})
		}, undefined, 'destructive', 'cancel')
	}

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen", "Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else {
		const reservation: Reservation = state.response.data
		return (
				<ScrollView style={[styles.container]}>
					{
							reservation.customer &&
							<ListItem key={"customer"} topDivider bottomDivider containerStyle={[styles.background]}>
								<ListItem.Content style={[]}>
									<ListItem.Title style={[styles.title]}>Kundendaten</ListItem.Title>
									<ListItem.Subtitle style={[styles.subTitle]}>Kunde: {reservation.customer.fullName}</ListItem.Subtitle>
								</ListItem.Content>
							</ListItem>
					}
					<ListItem key={"guests"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Anzahl Gäste</ListItem.Title>
						</ListItem.Content>
						<ListItem.Content right={true}>
							<ListItem.Title style={[styles.text, styles.title, styles.textRight]}>{reservation.guests}</ListItem.Title>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"time"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Startzeit</ListItem.Title>
							{
								reservation.startTime == null ?
										<ListItem.Subtitle style={[styles.subTitle]}>Keine Startzeit gewählt</ListItem.Subtitle>
										:
										<ListItem.Subtitle style={[styles.subTitle]}>{moment(reservation.startTime, zdtFormat).format('DD. MMM YYYY - LT')}</ListItem.Subtitle>
							}
						</ListItem.Content>
						<ListItem.Content style={[{flex: 1}]} right={true}>
							<ListItem.Title style={[styles.title, styles.textRight]}>Endzeit</ListItem.Title>
							{
								reservation.endTime == null ?
										<ListItem.Subtitle style={[styles.subTitle, styles.textRight]}>Keine Endzeit gewählt</ListItem.Subtitle>
										:
										<ListItem.Subtitle style={[styles.subTitle, styles.textRight]}>{moment(reservation.endTime, zdtFormat).format('DD. MMM YYYY - LT')}</ListItem.Subtitle>
							}
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"additionalNotes"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Weitere Notizen:</ListItem.Title>
							{
									reservation.additionalNotes &&
									<ListItem.Subtitle style={[styles.text, styles.subTitle]}>{reservation.additionalNotes}</ListItem.Subtitle>
							}
						</ListItem.Content>
						{
								!reservation.additionalNotes &&
								<ListItem.Content style={[]} right={true}>
									<ListItem.Subtitle style={[styles.textSecondary, styles.subTitle, styles.textRight]}> - Keine - </ListItem.Subtitle>
								</ListItem.Content>
						}
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"assign"} topDivider bottomDivider containerStyle={[styles.background]} onPress={assignTable}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title, styles.center]}>Tisch zuordnen</ListItem.Title>
						</ListItem.Content>
						<ListItem.Chevron/>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"delete"} topDivider bottomDivider containerStyle={[styles.background]} onPress={deleteReservation}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title, styles.center, styles.destructive]}>Reservierung stornieren</ListItem.Title>
						</ListItem.Content>
						<ListItem.Chevron/>
					</ListItem>
					<Divider style={[styles.divider]} />
				</ScrollView>
		);
	}
}
