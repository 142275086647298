import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../../theme/style';
import {InfoView} from '../../elements/InfoView';
import * as Backend from '../../../backend/Backend';
import {ErrorView} from '../../elements/ErrorView';
import {LoadingView} from '../../elements/LoadingView';
import {ActivityIndicator, RefreshControl, SafeAreaView, ScrollView, Text} from 'react-native';
import {Col, Grid, Row} from 'react-native-easy-grid';
import {getAppContext} from '../../../AppProvider';
import {ButtonGroup, Divider, Input, ListItem} from 'react-native-elements';
import * as Alert from '../../../alerts/alerts';
import {ScreenState} from "../../../types/ScreenState";
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../../types/RootStackParamList";
import {LocalizationContext} from "../../../LocalizationContext";
import {SelectedItems} from "../../../types/SelectedItems";
import {PaymentTypeEnum} from "../../../types/ESR";
import {Modifier, PaymentRequest} from "../../../types/PaymentRequest";
import {OrderItem} from "../../../types/OrderItem";

export default function PaymentScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'Payment'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const { t, locale } = React.useContext(LocalizationContext);

	const [state, setState] = React.useState<ScreenState>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})

	const [paymentType, setPaymentType] = React.useState<PaymentTypeEnum>(PaymentTypeEnum.CASH)
	const [bewirtungsbeleg, setBewirtungsbeleg] = React.useState<boolean>(true)
	const [inHouseConsumption, setInHouseConsumption] = React.useState<boolean>(true)

	const [emailReceipt, setEmailReceipt] = React.useState<boolean>(false)
	const [billHelpPrinting, setBillHelpPrinting] = React.useState<boolean>(false)
	const [billHelpPrinted, setBillHelpPrinted] = React.useState<boolean>(false)

	const [discountIsAbsolute, setDiscountIsAbsolute] = React.useState<boolean>(true)
	const [discountAmount, setDiscountAmount] = React.useState<number>(0.0)

	const [selectedTipIndex, setSelectedTipIndex] = React.useState<number>(0)
	const [customTipTotal, setCustomTipTotal] = React.useState<number>(0)

	function loadData() {
		Backend.loadStoreData("table/" + route?.params?.tableData.tableId + "/" + route?.params?.tableData.partyNumber, setState, appContext).then(() => {})
	}
	React.useEffect(loadData, [])

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (billHelpPrinting) {
		return LoadingView("Rechnung drucken...")
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen",
				"Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else if (state.response?.data == null) {
		return InfoView("Tisch noch nicht geöffnet",
				"Für diesen Tisch wurde noch keine Bestellung aufgenommen. Nutze den Button \"Bestellung\" um eine neue Bestellung aufzugeben und den Tisch damit zu öffnen.",
				"question-circle")
	} else {
		const tableData = state.response?.data
		let selectedItems: SelectedItems = route.params?.selectedItems || {}
		if (Object.keys(selectedItems).length === 0) {
			tableData.orderItems.forEach((item: OrderItem) => {
				selectedItems[item.id] = item.quantity
				item.modifiers.filter((m: Modifier) => !m.isRedeemed).forEach(modifier => {
					if (modifier.id) {
						selectedItems[modifier.id] = 1
					}
				})
			})
		}
		console.log(selectedItems)
		const selectedCount = Object.values(selectedItems).filter(a => a !== undefined).reduce((a, b) => Math.abs(a!) + Math.abs(b!), 0)
		let totalPrice = 0.0
		tableData.orderItems.forEach((item: OrderItem) => {
			let count = selectedItems[item.id] || 0
			if (count != 0) {
				let price = count * item.unitPrice
				totalPrice += price
				item.modifiers.filter((m: Modifier) => !m.isRedeemed).sort(function(x, y) {
					// absolute modifiers first
					return (x.isAbsolute === y.isAbsolute) ? 0 : (x.isAbsolute ? -1 : 1);
				}).filter(modifier => modifier.id && selectedItems[modifier.id] === 1).forEach(modifier => {
					const mod = modifier.isAbsolute ? modifier.amount : ((modifier.amount / 100.0) * price)
					price += mod // using price as running subtotal for relative modifiers
					totalPrice += mod
				})
			}
		})
		if (discountIsAbsolute) {
			totalPrice -= discountAmount
		} else {
			totalPrice *= 1.0 - (discountAmount / 100.0)
		}

		const tip1 = Math.ceil(totalPrice)
		let tip2 = Math.ceil(totalPrice/5)*5
		if (tip2 <= tip1)
			tip2 += 5
		let tip3 = Math.ceil(totalPrice/10)*10
		while (tip3 <= tip2)
			tip3 += 5
		const totalPriceStr = totalPrice.toLocaleString(locale, { style: 'currency', currency: 'EUR' })
		const tip1Str = tip1.toLocaleString(locale, { style: 'currency', currency: 'EUR' })
		const tip2Str = tip2.toLocaleString(locale, { style: 'currency', currency: 'EUR' })
		const tip3Str = tip3.toLocaleString(locale, { style: 'currency', currency: 'EUR' })
		const tipOptions = ['Ohne', tip1Str, tip2Str, tip3Str, 'Indiv.']
		let paymentAmount: number
		switch (selectedTipIndex) {
			case 0:
				paymentAmount = totalPrice
				break;
			case 1:
				paymentAmount = tip1
				break;
			case 2:
				paymentAmount = tip2
				break;
			case 3:
				paymentAmount = tip3
				break;
			case 4:
				paymentAmount = customTipTotal
				break;
			default:
				paymentAmount = 0
				break;
		}

		const customerId = tableData?.customer?.id

		function toggleEmailReceipt() {
			if (!emailReceipt && customerId == null) {
				navigation.navigate('SelectCustomer')
			} else {
				setEmailReceipt(!emailReceipt)
			}
		}

		function printBillHelp() {
			if (!billHelpPrinted && !billHelpPrinting) {
				setBillHelpPrinting(true)
				Backend.sendTableData("print/billhelp", route?.params?.tableData.tableId, route?.params?.tableData.partyNumber, setState, appContext, "PUT").then(() => {
					setBillHelpPrinting(false)
					setBillHelpPrinted(true)
				})
			}
		}

		function saveFinalAmountPaid() {
			const modifiers: Modifier[] = []
			if (discountAmount > 0) {
				modifiers.push({isAbsolute: discountIsAbsolute, amount: -discountAmount, description: 'Manueller Rabatt', isRedeemed: false});
			}

			const payment: PaymentRequest = {
				PayG: paymentType,
				Amt: paymentAmount,
				tip: paymentAmount - totalPrice,
				customerId: customerId,
				emailReceipt: emailReceipt,
				selectedItems: selectedItems,
				modifiers: modifiers,
				bewirtungsbeleg: bewirtungsbeleg,
				inHouseConsumption: inHouseConsumption,
			}
			Backend.sendTableData("pay", route?.params?.tableData.tableId, route?.params?.tableData.partyNumber, setState, appContext, "PATCH", payment, true).then((response) => {
				if (response?.data?.active) { // Only paid partly
					navigation.goBack()
				} else { // Fully paid, table has been set inactive by the backend
					navigation.navigate('Floorplan')
				}
			})
		}

		return (
				<SafeAreaView style={[styles.container]}>
					<Grid>
						<Row size={10}>
							<ScrollView style={[styles.container]} refreshControl={<RefreshControl refreshing={state.refreshing} onRefresh={loadData} />}>
								<ListItem key={"articleType"} topDivider bottomDivider containerStyle={[styles.background]}>
									<ListItem.Content style={[styles.center]}>
										<ListItem.Title style={[styles.title]}>Steuersatz</ListItem.Title>
										<ButtonGroup
												onPress={i => setInHouseConsumption(i == 1)}
												selectedIndex={inHouseConsumption ? 1 : 0}
												buttons={['Außer Haus', 'Verzehr vor Ort']}
												containerStyle={[styles.mt10, styles.fullWidth]}
												buttonStyle={[styles.background]}
												selectedButtonStyle={[styles.backgroundPrimary]}
												textStyle={[styles.text]}
												selectedTextStyle={[styles.text]}
										/>
									</ListItem.Content>
								</ListItem>
								<Divider style={[styles.divider]} />
								<ListItem key={"print_billhelp"} topDivider bottomDivider containerStyle={[styles.background]} onPress={printBillHelp}>
									<ListItem.Content style={[]}>
										<ListItem.Title style={[styles.title]}>Abrechnungshilfe drucken</ListItem.Title>
										<ListItem.Subtitle style={[styles.subTitle]}>Hilfe für manuelle Abrechnung jetzt drucken (Dies ist keine Rechnung/Quittung)</ListItem.Subtitle>
									</ListItem.Content>
									{
										billHelpPrinting &&
										<ActivityIndicator size="small" />
									}
									{
										billHelpPrinted &&
										<ListItem.CheckBox checked={true}/>
									}
								</ListItem>
								<Divider style={[styles.divider]} />
								<ListItem key={"discount"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => appContext.openDialog({
									title: 'Rabatt',
									message: 'Rabattsumme in EUR oder Prozent',
									input_visible: true,
									input_keyboardType: "decimal-pad",
									middleButton_visible: true,
									middleButton_label: '%',
									middleButton_bold: true,
									positiveButton_label: '€',
									negativeButton_visible: true,
									negativeButton_label: 'Kein Rabatt',
									positiveButton_onPress(inputValue) {
										let v = Number(inputValue?.replace(",", "."))
										if (isNaN(v) || v < 0) {
											v = 0
										}
										setDiscountIsAbsolute(true)
										setDiscountAmount(v)
									},
									middleButton_onPress(inputValue) {
										let v = Number(inputValue?.replace(",", "."))
										if (isNaN(v) || v < 0) {
											v = 0
										}
										setDiscountIsAbsolute(false)
										setDiscountAmount(v)
									},
									negativeButton_onPress(inputValue?: string) {
										setDiscountAmount(0)
									}
								})}>
									<ListItem.Content style={[]}>
										<ListItem.Title style={[styles.title]}>Rabatt</ListItem.Title>
									</ListItem.Content>
									<ListItem.Content right={true} style={[]}>
										<ListItem.Title style={[styles.title]}>{discountAmount.toLocaleString(locale, { style: discountIsAbsolute ? 'currency': 'decimal', currency: 'EUR' })}{!discountIsAbsolute && ' %'}</ListItem.Title>
									</ListItem.Content>
									<ListItem.Chevron/>
								</ListItem>
								<ListItem key={"pay_voucher"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {}}>
									<ListItem.Content style={[]}>
										<ListItem.Title style={[styles.title]}>Gutschein einlösen</ListItem.Title>
									</ListItem.Content>
									<ListItem.Content style={[]} right={true}>
										<ListItem.Subtitle style={[styles.subTitle, styles.errorText, styles.center]}>Nicht verfügbar</ListItem.Subtitle>
									</ListItem.Content>
									<ListItem.Chevron/>
								</ListItem>
								<Divider style={[styles.divider]} />
								<ListItem key={"part"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('PaymentSelectPart', {tableData: tableData, selectedItems: selectedItems})}>
									<ListItem.Content style={[]}>
										<ListItem.Title style={[styles.title]}>Zahlungsumfang</ListItem.Title>
										<ListItem.Subtitle style={[styles.subTitle]}>Teile hier die Rechnung auf</ListItem.Subtitle>
									</ListItem.Content>
									<ListItem.Content right={true} style={[]}>
										<ListItem.Title style={[styles.title, styles.textRight]}>{selectedCount} Positionen</ListItem.Title>
									</ListItem.Content>
									<ListItem.Chevron/>
								</ListItem>
								<ListItem key={"bewirtungsbeleg"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => setBewirtungsbeleg(!bewirtungsbeleg)}>
									<ListItem.Content style={[]}>
										<ListItem.Title style={[styles.title]}>Bewirtungsbeleg</ListItem.Title>
									</ListItem.Content>
									<ListItem.CheckBox checked={bewirtungsbeleg} onPress={() => setBewirtungsbeleg(!bewirtungsbeleg)}/>
								</ListItem>
								<Divider style={[styles.divider]} />
								<ListItem key={"customer_address"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('SelectCustomer')}>
									<ListItem.Content style={[]}>
										<ListItem.Title style={[styles.title]}>Kundendaten</ListItem.Title>
										{
											customerId == null ?
													<ListItem.Subtitle style={[styles.subTitle]}>Kein/e Kunde/in gewählt</ListItem.Subtitle>
													:
													<ListItem.Subtitle style={[styles.subTitle]}>Kunde {customerId}</ListItem.Subtitle>
										}
									</ListItem.Content>
									<ListItem.Chevron/>
								</ListItem>
								<ListItem key={"email_receipt"} topDivider bottomDivider containerStyle={[styles.background]} onPress={toggleEmailReceipt}>
									<ListItem.Content style={[]}>
										<ListItem.Title style={[styles.title]}>Rechnung per E-Mail senden</ListItem.Title>
										<ListItem.Subtitle style={[styles.subTitle]}>Wird nach vollständiger Abrechnung verschickt</ListItem.Subtitle>
										<ListItem.Subtitle style={[styles.subTitle, styles.errorText]}>Keine Adresse hinterlegt!</ListItem.Subtitle>
									</ListItem.Content>
									<ListItem.CheckBox checked={emailReceipt} onPress={toggleEmailReceipt}/>
								</ListItem>
								<Divider style={[styles.divider]} />
								<ListItem key={"tip"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => {}}>
									<ListItem.Content style={[styles.center]}>
										<ListItem.Title style={[styles.title]}>Trinkgeld</ListItem.Title>
										<ListItem.Subtitle style={[styles.subTitle, styles.center]}>Gesamtbetrag inkl. Trinkgeld</ListItem.Subtitle>
										<ButtonGroup
												onPress={setSelectedTipIndex}
												selectedIndex={selectedTipIndex}
												buttons={tipOptions}
												containerStyle={[styles.mt10, styles.fullWidth]}
												buttonStyle={[styles.background]}
												selectedButtonStyle={[styles.backgroundPrimary]}
												textStyle={[styles.text]}
												selectedTextStyle={[styles.text]}
										/>
										{
											selectedTipIndex === 4 &&
											<Input
													onChangeText={(input) => setCustomTipTotal(Number(input.replace(",", ".")))}
													placeholder={totalPriceStr}
													keyboardType={'numeric'}
													inputStyle={[styles.text]}
											/>
										}
									</ListItem.Content>
								</ListItem>
								<Divider style={[styles.divider]} />
								<ListItem key={"pay_cash"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => setPaymentType(PaymentTypeEnum.CASH)}>
									<ListItem.Content style={[]}>
										<ListItem.Title style={[styles.title]}>Bar zahlen</ListItem.Title>
									</ListItem.Content>
									<ListItem.CheckBox checked={paymentType === PaymentTypeEnum.CASH} onPress={() => setPaymentType(PaymentTypeEnum.CASH)}/>
								</ListItem>
								{
									customerId != null &&
									<ListItem key={"pay_invoice"} topDivider bottomDivider containerStyle={[styles.background]}>
										<ListItem.Content style={[]}>
											<ListItem.Title style={[styles.title]}>Auf Rechnung</ListItem.Title>
										</ListItem.Content>
										<ListItem.CheckBox checked={paymentType === PaymentTypeEnum.OUTSTANDING} onPress={() => setPaymentType(PaymentTypeEnum.OUTSTANDING)}/>
									</ListItem>
								}
								<ListItem key={"pay_debit"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => setPaymentType(PaymentTypeEnum.DEBIT)}>
									<ListItem.Content style={[]}>
										<ListItem.Title style={[styles.title]}>EC-Kartenzahlung</ListItem.Title>
									</ListItem.Content>
									<ListItem.CheckBox checked={paymentType === PaymentTypeEnum.DEBIT} onPress={() => setPaymentType(PaymentTypeEnum.DEBIT)}/>
								</ListItem>
								<ListItem key={"pay_credit"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => setPaymentType(PaymentTypeEnum.CREDIT)}>
									<ListItem.Content style={[]}>
										<ListItem.Title style={[styles.title]}>Kredit-Kartenzahlung</ListItem.Title>
									</ListItem.Content>
									<ListItem.CheckBox checked={paymentType === PaymentTypeEnum.CREDIT} onPress={() => setPaymentType(PaymentTypeEnum.CREDIT)}/>
								</ListItem>
								<Divider style={[styles.divider]} />
							</ScrollView>
						</Row>
						{
							(paymentAmount === 0)?
									<Row size={1}>
										<Col size={1} style={[styles.center, styles.backgroundPrimary]} onPress={saveFinalAmountPaid}>
											<Text style={[styles.title, { fontSize: 20}]}>Vollständig bezahlt!</Text>
										</Col>
									</Row>
									:
									(paymentAmount < 0)?
											<Row size={1}>
												<Col size={1} style={[styles.center, styles.backgroundPrimary]} onPress={saveFinalAmountPaid}>
													<Text style={[styles.title, { fontSize: 20}]}>Rückgeld: {tableData.totalChange.toLocaleString(locale, { style: 'currency', currency: 'EUR' })}</Text>
												</Col>
											</Row>
											:
											<>
												<Row size={1}>
													{
														paymentType === PaymentTypeEnum.CASH && false && // TODO: Remove false-Statement when this feature is implemented
														<Col size={1} style={[styles.center, styles.backgroundSecondary]} onPress={() => {
															if (paymentAmount >= totalPrice) {
																Alert.alert("Rückgeldrechner", "Hier kann man in Zukunft den gegebenen Betrag eingeben. Aktuell ist dies leider nicht möglich.")
															} else {
																Alert.alert("Betrag zu niedrig", "Der Betrag inklusive Trinkgeld kann nicht niedriger sein, als der zu zahlende Betrag!")
															}
														}}>
															<Text style={[styles.title, { fontSize: 20}]}>Rückgeld</Text>
														</Col>
													}
													<Col size={2} style={[styles.center, styles.backgroundPrimary]} onPress={saveFinalAmountPaid}>
														<Text style={[styles.title, { fontSize: 20}]}>{t('paymentType_' + paymentType)} {paymentAmount.toLocaleString(locale, { style: 'currency', currency: 'EUR' })}</Text>
													</Col>
												</Row>
											</>
						}
					</Grid>
				</SafeAreaView>
		)
	}
}
