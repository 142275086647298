import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {getAppContext} from "../../AppProvider";
import {ButtonGroup, Divider, ListItem} from "react-native-elements";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'
import * as Backend from "../../backend/Backend";
import {ScreenState} from "../../types/ScreenState";
import {ErrorView} from "../elements/ErrorView";
import {LoadingView} from "../elements/LoadingView";
import * as Alert from "../../alerts/alerts";
import {ArticleCreateRequest} from "../../types/ArticleCreateRequest";
import {TaxGroupTypeEnum} from "../../types/TaxGroup";
import {Article, ArticleGroup, ArticleType} from "../../types/Article";
import RNPickerSelect from 'react-native-picker-select';
import {Platform} from "react-native";

export default function CreateVoucherScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'CreateVoucher'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()

	let voucher: Article | undefined
	if ('voucher' in route.params && route.params.voucher !== undefined) { // Editing existing voucher
		voucher = route.params.voucher
	}

	const [articleGroups, setArticleGroups] = React.useState<ArticleGroup[]>(route.params.groups)
	const [voucherType, setVoucherType] = React.useState<number>((voucher?.type === ArticleType.VOUCHER_MULTIPURPOSE) ? 1 : 0)
	const [orderReceiptTypeIndex, setOrderReceiptTypeIndex] = React.useState<number>((voucher?.orderReceiptType === "RECEIPT") ? 2 : ((voucher?.orderReceiptType === "KITCHEN_ORDER") ? 1 : 0))
	const [selectedTax, setSelectedTax] = React.useState<number>((voucher?.taxGroupEatHere === TaxGroupTypeEnum.F) ? 2 : ((voucher?.taxGroupEatHere === TaxGroupTypeEnum.B) ? 1 : 0))
	const [state, setState] = React.useState<ScreenState & ArticleCreateRequest>({
		isLoading: false,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,

		name: voucher?.name || "",
		netFlag: voucher?.netFlag || false,
		stockKeepingUnit: voucher?.stockKeepingUnit || "",
		itemNumber: voucher?.itemNumber,
		orderReceiptType: voucher?.orderReceiptType,
		quantityUnit: voucher?.quantityUnit,
		sort: voucher?.sort || 0,
		stock: voucher?.stock,
		groupId: voucher?.group,
		type: voucher?.type,
		taxGroupEatHere: voucher?.taxGroupEatHere || TaxGroupTypeEnum.A,
		taxGroupTakeAway: voucher?.taxGroupTakeAway || TaxGroupTypeEnum.A,
		unitPrice: voucher?.unitPrice || -1,
	})

	function replaceSpace(str: string | undefined) {
		return str?.replace(/\u0020/g, '\u00a0')
	}

	function escapeSKU(str: string) {
		return str.toUpperCase().replace(/\s/g, '-').replace(/[^A-Z0-9-_]/g, '')
	}

	function escapeItemNumber(str: string) {
		return str.replace(/[^0-9]/g, '')
	}

	function createArticleGroup() {
		appContext.openDialog({
			title: "Gruppe hinzufügen",
			message: "Bitte gib einen Namen für die neue Artikel-Gruppe ein:",
			input_visible: true,
			input_keyboardType: 'default',
			positiveButton_label: "Erstellen",
			positiveButton_onPress(inputValue?: string) {
				if (inputValue) {
					Backend.sendStoreData("category/" + route.params.category.id + "/group", setState, appContext, "PUT", {name: inputValue}, false).then(response => {
						setArticleGroups(response.data.groups.filter((group: ArticleGroup) => group.category === route.params.category.id))
					})
				}
			},
			negativeButton_visible: true,
			negativeButton_label: "Abbrechen",
		})
	}

	function deleteVoucher() {
		if ('voucher' in route.params && route.params.voucher !== undefined) {
			Alert.confirm("Gutschein entfernen", "Möchtest du diesen Gutschein löschen?", "Entfernen", "Abbrechen", () => {
				Backend.sendStoreData("article/" + route.params.voucher?.id, setState, appContext, "DELETE").then(() => {
					navigation.goBack()
				})
			}, undefined, 'destructive', 'cancel')
		}
	}

	function submit() {
		if (state.name.length < 3 || state.stockKeepingUnit.length < 1 || (voucherType === 0 && state.unitPrice == -1)) {
			Alert.alert("Bitte fülle alle Felder vollständig aus!")
			return;
		}

		const request: ArticleCreateRequest = {
			name: state.name,
			netFlag: false,
			stockKeepingUnit: state.stockKeepingUnit,
			taxGroupEatHere: TaxGroupTypeEnum.A,
			taxGroupTakeAway: TaxGroupTypeEnum.A,
			unitPrice: (voucherType === 1 ? state.unitPrice : 1.0),
			itemNumber: state.itemNumber,
			orderReceiptType: state.orderReceiptType,
			sort: state.sort,
		}

		if (state.groupId !== 'new')
			request.groupId = state.groupId

		if (voucherType === 0) {
			request.type = ArticleType.VOUCHER_SINGLEPURPOSE
		} else if (voucherType === 1) {
			request.type = ArticleType.VOUCHER_MULTIPURPOSE
			request.unitPrice = 1
		}

		if (orderReceiptTypeIndex == 0) {
			request.orderReceiptType = "DRINKS_ORDER"
		} else if (orderReceiptTypeIndex == 1) {
			request.orderReceiptType = "KITCHEN_ORDER"
		} else if (orderReceiptTypeIndex == 2) {
			request.orderReceiptType = "RECEIPT"
		}

		if (voucherType === 0 && selectedTax === 0) {
			request.taxGroupEatHere = TaxGroupTypeEnum.A
		} else if (voucherType === 0 && selectedTax === 1) {
			request.taxGroupEatHere = TaxGroupTypeEnum.B
		} else if (voucherType === 1 || selectedTax === 2) {
			request.taxGroupEatHere = TaxGroupTypeEnum.F
		}
		request.taxGroupTakeAway = request.taxGroupEatHere

		if ('voucher' in route.params && route.params.voucher !== undefined) { // Editing existing voucher
			Backend.sendStoreData("article/" + route.params.voucher.id, setState, appContext, "PATCH", request).then(() => {
				navigation.goBack()
			})
		} else { // Creating a new article
			Backend.sendStoreData("category/" + route.params.category.id + "/article", setState, appContext, "PUT", request).then(() => {
				navigation.goBack()
			})
		}
	}

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else {
		let groups = articleGroups.map(group => {
			return {label: group.name, value: group.id}
		})
		groups.push({label: '- Neue Gruppe anlegen -', value: 'new'})
		return (
				<KeyboardAwareScrollView style={[styles.container]} enableResetScrollToCoords={false}>
					<ListItem key={"articleType"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title]}>Typ des Gutscheins</ListItem.Title>
							<ButtonGroup
									onPress={setVoucherType}
									selectedIndex={voucherType}
									buttons={['Einzweck', 'Mehrzweck']}
									containerStyle={[styles.mt10, styles.fullWidth]}
									buttonStyle={[styles.background]}
									selectedButtonStyle={[styles.backgroundPrimary]}
									textStyle={[styles.text]}
									selectedTextStyle={[styles.text]}
							/>
							<ListItem.Subtitle style={[styles.subTitle, styles.center]}>Bei Einzweckgutscheinen steht der Steuersatz bereits beim Gutscheinkauf fest. Mehrzweckgutscheinen werden steuerfrei gebucht.</ListItem.Subtitle>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"orderReceiptType"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title]}>Auftragsziel</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>Wähle aus, wo der Bon gedruckt werden soll</ListItem.Subtitle>
							<ButtonGroup
									onPress={setOrderReceiptTypeIndex}
									selectedIndex={orderReceiptTypeIndex}
									buttons={['Theke', 'Küche', 'Rechnungen']}
									containerStyle={[styles.mt10, styles.fullWidth]}
									buttonStyle={[styles.background]}
									selectedButtonStyle={[styles.backgroundPrimary]}
									textStyle={[styles.text]}
									selectedTextStyle={[styles.text]}
							/>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"categoryName"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Artikel-Kategorie</ListItem.Title>
						</ListItem.Content>
						<ListItem.Content style={[]} right={true}>
							<ListItem.Title style={[styles.text]}>{route.params.category.name}</ListItem.Title>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"articleGroup"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Artikel-Gruppe</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>kann zur Gruppierung ähnlicher Artikel genutzt werden (z.B. verschiedene Größen des selben Artikels)</ListItem.Subtitle>
						</ListItem.Content>
						<ListItem.Content style={[]} right={true}>
							<RNPickerSelect
									style={{
										placeholder: styles.text,
										inputIOS: styles.text,
										inputAndroid: styles.text,
									}}
									onClose={() => {
										if (state.groupId === 'new' && Platform.OS === "ios") {
											setState((s) => ({...s, groupId: undefined}))
											createArticleGroup()
										}
									}}
									onValueChange={(groupId) => {
										if (groupId === 'new' && Platform.OS !== "ios") {
											createArticleGroup()
										} else {
											setState((s) => ({...s, groupId: groupId}))
										}
									}}
									value={state.groupId}
									items={groups}>
								<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]}
																value={groups.find(g => g.value === state.groupId)?.label}/>
							</RNPickerSelect>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"articleName"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Gutschein-Name</ListItem.Title>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"Gutschein Menü 1"}
															value={replaceSpace(state.name)}
															onChangeText={text => setState((s) => ({...s, name: text}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"stockKeepingUnit"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Artikelnummer / SKU</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>einzigartiger interner Identifikator</ListItem.Subtitle>
							<ListItem.Subtitle style={[styles.subTitle]}>Erlaubte Zeichen: [A-Z], [0-9], [-_]</ListItem.Subtitle>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"GUTSCHEIN-12"}
															autoCapitalize={"characters"}
															value={state.stockKeepingUnit}
															onChangeText={text => setState((s) => ({...s, stockKeepingUnit: escapeSKU(text)}))}/>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"itemNumber"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Artikelnummer (optional)</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle]}>Globale Id-Nummer (z.B. GTIN, EAN, UPC,...)</ListItem.Subtitle>
							<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"4000001012345"}
															keyboardType={"number-pad"}
															value={state.itemNumber}
															onChangeText={text => setState((s) => ({...s, itemNumber: escapeItemNumber(text)}))}/>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"unitPrice"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.title]}>Preis pro Einheit</ListItem.Title>
							{
								voucherType === 0 &&
								<ListItem.Input style={[styles.text, styles.backgroundHighlight, styles.p10, styles.mt10]} placeholder={"5,99"} keyboardType={"decimal-pad"}
																onChangeText={text => setState((s) => ({...s, unitPrice: Number(text.replace(",", "."))}))}/>
							}
							{
								voucherType === 1 &&
								<ListItem.Subtitle style={[styles.subTitle]}>Bei Mehrzweckgutscheinen wird der genaue Betrag erst bei Bestellung über die Menge bestimmt</ListItem.Subtitle>
							}
							</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"taxGroup"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title]}>Steuersatz</ListItem.Title>
							<ButtonGroup
									onPress={setSelectedTax}
									selectedIndex={voucherType === 1 ? 2 : selectedTax}
									buttons={['19%', '7%', '0%']}
									containerStyle={[styles.mt10, styles.fullWidth]}
									buttonStyle={[styles.background]}
									selectedButtonStyle={[styles.backgroundPrimary]}
									textStyle={[styles.text]}
									selectedTextStyle={[styles.text]}
							/>
							<ListItem.Title style={[styles.text, styles.center, styles.mt10]}>
								{voucherType === 0 && selectedTax === 0 && "Normal"}
								{voucherType === 0 && selectedTax === 1 && "Ermäßigt"}
								{(voucherType === 1 || selectedTax === 2) && "Umsatzsteuerfrei"}
							</ListItem.Title>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"submit"} topDivider bottomDivider containerStyle={[styles.background]} onPress={submit}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title, styles.center]}>Absenden</ListItem.Title>
						</ListItem.Content>
						<ListItem.Chevron/>
					</ListItem>
					<Divider style={[styles.divider]} />
					{
						voucher !== undefined && <>
							<ListItem key={"delete"} topDivider bottomDivider containerStyle={[styles.background]} onPress={deleteVoucher}>
								<ListItem.Content style={[styles.center]}>
									<ListItem.Title style={[styles.title, styles.center, styles.destructive]}>Löschen</ListItem.Title>
								</ListItem.Content>
								<ListItem.Chevron/>
							</ListItem>
							<Divider style={[styles.divider]} />
						</>
					}
				</KeyboardAwareScrollView>
		)
	}
}
